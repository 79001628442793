import { useEffect, useRef, useLayoutEffect } from 'react';
import { log } from '../../services';

export function useWhyDidYouUpdate(name, props) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef<Object>();
  const count = useRef(0);
  // First Time
  useLayoutEffect(() => {
    const allKeys = Object.keys({ ...props });
    const changesObj = {};
    allKeys.forEach(key => (changesObj[key] = props[key]));
    // If changesObj not empty then output to console
    if (Object.keys(changesObj).length) {
      log.log(`>${count.current}`, name, changesObj);
    }
    previousProps.current = props;
    count.current = count.current + 1;
    return () => {
      // Unmount
      log.log(`<${count.current}`, name, '-Unmounted-');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj = {};
      // Iterate through keys
      allKeys.forEach(key => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        log.log(`#${count.current}`, name, changesObj);
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
    count.current = count.current + 1;
  });
}
