import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { rootStore } from '../store';

// Persist Error to DB
const logErrorGql = gql`
  mutation($grouping: String!, $message: String!) {
    logError(originatedFrom: "browserApp", grouping: $grouping, message: $message)
  }
`;

export function persistLogError(client: any, componentName: string, location: string, message: string): void {
  return;
  //console.log('PersistErrorLog', componentName, location, message);
  if (!rootStore.appStore.isLogIn) {
    console.log('PersistErrorLog (not logged in)', componentName, location, message);
    return;
  }
  const grouping = `${componentName}.${location}`;
  client
    .mutate({ mutation: logErrorGql, variables: { grouping, message } })
    .catch(error => console.log('failed to persist the error', error));
}

export function usePersistentLog(componentName: string) {
  const client = useApolloClient();
  function logError(location: string, message: string): void {
    persistLogError(client, componentName, location, message);
  }
  return { logError };
}
