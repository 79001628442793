import React, { FC } from 'react';
import { Card } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { FlexRow, ButtonSubmit, ButtonCancel, ButtonDelete } from './../index';
import ButtonApprove from './../buttons/button-approve';
import ButtonReject from './../buttons/button-reject';

type Props = {
  isNew?: boolean;
  permitDelete?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onDelete?: () => void;
  handleApproval?: () => void;
  handleRejection?: () => void;
  noButtons?: boolean;
  otherButtons?: boolean;
  alwaysOn?: boolean;
};

const FormFooter: FC<Props> = ({
  isNew,
  permitDelete,
  loading = false,
  onDelete = null,
  children = null,
  disabled = false,
  noButtons = false,
  otherButtons = false,
  handleApproval = null,
  handleRejection = null,
  alwaysOn = false,
}) => {
  const { dirty, resetForm } = useFormikContext();

  return (
    <Card.Footer>
      <FlexRow>
        {!noButtons ? (
          <div>
            {(dirty || isNew || alwaysOn) && (
              <>
                <ButtonSubmit disabled={!alwaysOn && (!dirty || loading || disabled)} loading={loading} />
                <span className="mx-1"></span>
              </>
            )}
            {(dirty || isNew || alwaysOn) && <ButtonCancel disabled={!dirty} onClick={resetForm} />}
          </div>
        ) : otherButtons ? (
          <div>
            {(dirty || isNew) && (
              <>
                <ButtonApprove disabled={!dirty || loading || disabled} loading={loading} onClick={handleApproval} />
                <span className="mx-1"></span>
              </>
            )}
            {(dirty || isNew) && (
              <ButtonReject disabled={!dirty || loading || disabled} loading={loading} onClick={handleRejection} />
            )}
          </div>
        ) : null}
        <div>
          {!dirty && !isNew && (
            <>
              {children}
              {permitDelete && <ButtonDelete onClick={onDelete} />}
            </>
          )}
        </div>
      </FlexRow>
    </Card.Footer>
  );
};

export default FormFooter;
