import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from "react";
import { Card, Row } from "react-bootstrap";
import { FormCol, CellFloatCustom, FloatCustom } from "components/common";
import LoadingOverlay from "react-loading-overlay";
import { useRootStore } from "store";
import { useLazyQuery } from "@apollo/react-hooks";
import { useLocation, useHistory } from "react-router-dom";
import { FormFilterCard } from "components/common/formik";
import TableSubSimple from "components/common/table-sub-simple";
import { FieldDateRanger } from "components/common/formik/field-dateranger";
import { gqlGetCollectorStations } from "./graphql";
import moment from "moment";
import _ from "lodash";
import { PdfRep } from "./pdf-collectorStations";

interface FilterParams {
  fromDate: Date;
  tillDate: Date;
}
const initFilters: FilterParams = {
  fromDate: moment()
    .startOf("month")
    .toDate(),
  tillDate: moment()
    .endOf("month")
    .toDate(),
};

export const CollectorStationsPage = () => {
  const location = useLocation<any>();
  const history = useHistory();
  const [routeFilterValues] = useState(
    location?.state?.filterValues ?? initFilters
  );
  const [
    get,
    { data: rawData, loading },
  ] = useLazyQuery(gqlGetCollectorStations, { fetchPolicy: "network-only" });
  const data = rawData ? rawData.getCollectorStations : undefined;
  const { appStore } = useRootStore();

  const fetchData = useCallback(
    async (params: FilterParams) => {
      let fromDate = params?.fromDate ?? initFilters.fromDate;
      let tillDate = params?.tillDate ?? initFilters.tillDate;

      get({
        variables: {
          CollectorId: appStore.userLogin.collectorId,
          fromDate: fromDate,
          tillDate: tillDate,
        },
      });
    },
    [appStore.userLogin.collectorId, get]
  );

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      const filterParams = location?.state?.filterValues ?? {};
      fetchData(filterParams as FilterParams);
    }
  });

  useEffect(() => {
    if (!!data) {
      setTimeout(() => {
        // eslint-disable-next-line array-callback-return
        data.map((record) => {
          if (record.Alert && !appStore.alert) {
            window.alert(
              "ΠΡΟΣΟΧΗ! Πλησιάζετε το όριο παραδόσεων που έχει θέσει η ECOELASTIKA AE σε 1 ή παραπάνω Σταθμούς Ανακύκλωσης"
            );
            appStore.setAlert();
          }
        });
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const filtersChanged = useCallback(
    (values) => {
      //fetchData(values.collectorId);
      // Ενημέρωσε το State του Route => triggers page refresh
      history.replace(location.pathname, { filterValues: values });
    },
    [history, location.pathname]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Σταθμός Ανακύκλωσης",
        accessor: "Name",
      },
      {
        Header: "Μηνιαίο όριο Παραδόσεων(Kg)",
        accessor: "MonthlyKgLimit",
        Cell: ({ row }) => (
          <div className="text-center">
            {FloatCustom(row.original.MonthlyKgLimit, 10, 1)}
          </div>
        ),
        Footer: ({ rows }) => {
          const value = _.sum(_.map(rows, (d) => d.original.MonthlyKgLimit));
          return FloatCustom(value, 10, 1);
        },
      },
      {
        Header: "Άθροισμα Παραδόσεων(Kg)",
        accessor: "NetWeight",
        Cell: CellFloatCustom(10, 1),
        Footer: ({ rows }) => {
          const value = _.sum(_.map(rows, (d) => d.original.NetWeight));
          return FloatCustom(value, 10, 1);
        },
      },
      {
        Header: "Υπόλοιπο για Παραλαβή(Kg)",
        accessor: "RemainingWeight",
        Cell: CellFloatCustom(10, 1),
        Footer: ({ rows }) => {
          const value = _.sum(_.map(rows, (d) => d.original.RemainingWeight));
          return FloatCustom(value, 10, 1);
        },
      },
    ],
    []
  );

  /* console.log(dataToRender); */
  return (
    <>
      <Card className="shadow">
        <Card.Header>
          <Card.Title className="m-0">
            Πλαφόν παραδόσεων ανά Σταθμό Ανακύκλωσης
          </Card.Title>
        </Card.Header>

        {/* Φίλτρα */}
        <Filters
          onChange={filtersChanged}
          fetchData={fetchData}
          initialValues={routeFilterValues}
        />
        {/* TableGrid */}
        <LoadingOverlay active={loading} spinner text="Παρακαλώ περιμένετε...">
          {!loading && data ? (
            <>
              <TableSubSimple
                title=""
                //onRefresh={() => refetch()}
                //onExport={handleExport}
                columns={columns}
                data={data}
                hasFooter={true}
              />
              <PdfRep
                fromDate={routeFilterValues.fromDate}
                tillDate={routeFilterValues.tillDate}
                data={data}
              ></PdfRep>
            </>
          ) : (
            <div style={{ height: "200px" }}></div>
          )}
        </LoadingOverlay>
      </Card>
    </>
  );
};

/* ----------- Filters Component ------------------ */
const Filters = ({ fetchData, onChange, initialValues }) => {
  //
  const handleFiltersSubmit = useCallback(
    (values, { setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
        console.log("values", values);
        onChange(values);
      }, 400);
    },
    [onChange]
  );

  return (
    <FormFilterCard
      disableClear={true}
      title="Κριτήρια Αναζήτησης"
      disableSubmit={true}
      onSubmit={handleFiltersSubmit}
      initialValues={initialValues}
    >
      <Row>
        <FieldDateRanger
          onSubmit={fetchData}
          disabled={true}
          as={FormCol}
          label="Από"
          name="fromDate"
          name2="tillDate"
        />
      </Row>
    </FormFilterCard>
  );
};
