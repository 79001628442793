import React from 'react';
import { Tooltip } from 'react-bootstrap';

type Props = {
  text: string;
  value: any;
};

const FieldTooltip = ({ text, value }: Props) => (
  <Tooltip id={`overlay-${text}`}>
    {text}:{value}
  </Tooltip>
);

export default FieldTooltip;
