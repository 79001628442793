import React, { FC, ComponentProps } from 'react';
import { Button } from 'react-bootstrap';
import { FaSync } from 'react-icons/fa';

type Props = ComponentProps<typeof Button> & {};

export const ButtonRefresh: FC<Props> = props => (
  <Button {...props} style={{ width: '160px' }} className="ml-2" onClick={props.onClick}>
    <FaSync className="mb-1" /> Ανανέωση
  </Button>
);
