import React from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import ApolloClient from "apollo-client";
import { Button } from "react-bootstrap";
import { useApolloClient, useQuery } from "@apollo/react-hooks";
import { FaFilePdf } from "react-icons/fa";
import { format } from "date-fns";
import { pdfDocOpen, styles, BoldText, PdfTable } from "utils/pdfhelpfunc";
import { useRootStore } from "store";
import { gqlGetTyreCollectionsView } from "./graphql";

// 14 columns => cell = 100/14=7.14%
const columns = [
  {
    name: "CodeId",
    header: "Αριθμός\nΑίτησης",
    type: "string",
    wordWrap: true,
    width: "6%",
  },
  {
    name: "CstPointName",
    header: "Σημείο Συλλογής",
    type: "string",
    wordWrap: true,
    width: "10%",
  },
  {
    name: "CstPointAddress",
    header: "Διεύθυνση",
    type: "string",
    wordWrap: true,
    width: "10.5%",
    maxCharWidth: 17,
  },
  {
    name: "CstPointMunicipality",
    header: "Δήμος",
    type: "string" /* wordWrap: true, */,
  },
  {
    name: "CstPointPhone1",
    header: "Τηλέφωνο",
    type: "string" /* wordWrap: true, */,
  },
  {
    name: "RequestDate",
    header: "Ημ/νια Αίτησης",
    type: "date",
  },
  {
    name: "plannedDate",
    header: "Ημ/νια Δρομολογίου",
    type: "date",
  },
  {
    name: "Notes",
    header: "Σημειώσεις",
    type: "string",
    wordWrap: true,
    width: "10%",
    maxCharWidth: 16,
  },
  {
    name: "CollectorNotes",
    header: "Παρατηρήσεις\nΣυλλέκτη",
    type: "string",
    wordWrap: true,
    width: "10%",
    maxCharWidth: 16,
  },
  {
    name: "TyreType1_ReqQty",
    header: "Α",
    type: "int",
    width: "5%",
  },
  {
    name: "TyreType2_ReqQty",
    header: "Β",
    type: "int",
    width: "5%",
  },
  {
    name: "TyreType3_ReqQty",
    header: `Γ`,
    type: "int",
    width: "5%",
  },
  {
    name: "TyreType4_ReqQty",
    header: "Δ1",
    type: "int",
    width: "5%",
  },
  {
    name: "TyreType5_ReqQty",
    header: "Δ2",
    type: "int",
    width: "5%",
  },
];

const FirstPage = ({ collector, fromDate, tillDate, data, title }) => (
  <Page size="A4" style={styles.page}>
    <View
      style={{
        width: 580,
        height: 820,
        display: "flex",
        padding: "20,5,20,5",
        marginLeft: "10px",
      }}
    >
      {/* Page Header */}
      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 1 }}>
          <Image
            style={{ width: 200, height: 50 }}
            src={process.env.PUBLIC_URL + "/ecoelastika_logo.jpg"}
          />
        </View>
        <View style={{ flexDirection: "column" }}>
          <View
            style={{
              flex: 3,
              fontSize: "1.3vh",
              alignSelf: "flex-end",
              marginTop: "12px",
              marginRight: "45px",
              alignItems: "center",
            }}
          >
            <BoldText>{"ΔΡΟΜΟΛΟΓΗΜΕΝΕΣ ΑΙΤΗΣΕΙΣ"}</BoldText>
            <BoldText>{"ΣΥΛΛΟΓΗΣ ΕΛΑΣΤΙΚΩΝ"}</BoldText>
          </View>
        </View>
      </View>

      {/* Filters Title Header */}
      <View style={{ marginTop: "20px" }}>
        {/* <Text
          style={{ alignSelf: "center", fontSize: "1.3vh" }}
        >{`${collector}`}</Text>
        <Text style={{ alignSelf: "center", fontSize: "1.3vh" }}>
          {"Ημερομηνία από:  "}
          {fromDate ? format(fromDate, "dd-MM-yyyy") : " - "} {" έως  "}
          {tillDate ? format(tillDate, "dd-MM-yyyy") : " - "}
        </Text> */}
        <Text style={{ alignSelf: "flex-start", fontSize: "1.3vh" }}>
          {collector}
          {"\n"}
          {title}
        </Text>
      </View>

      <PdfTable
        fontSize={5}
        headerFontSize={6}
        data={data.length > 15 ? data.splice(0, 15) : data}
        columns={columns}
      />
    </View>
  </Page>
);

const SecondPage = ({ data }) => (
  <Page size="A4" style={styles.page}>
    <View
      style={{ width: 580, height: 820, display: "flex", padding: "20,5,25,5" }}
    >
      <PdfTable
        fontSize={5}
        data={data.length > 20 ? data.splice(0, 20) : data}
        columns={columns}
        headers={false}
      />
    </View>
  </Page>
);

// Create Document Component
const MyDocument = ({ collector, data, fromDate, tillDate, title }) => (
  <Document>
    <FirstPage
      data={data}
      fromDate={fromDate}
      tillDate={tillDate}
      collector={collector}
      title={title}
    ></FirstPage>
    {data.length > 15 && <SecondPage data={data}></SecondPage>}
    {data.length > 35 && <SecondPage data={data}></SecondPage>}
    {data.length > 55 && <SecondPage data={data}></SecondPage>}
    {data.length > 75 && <SecondPage data={data}></SecondPage>}
    {data.length > 95 && <SecondPage data={data}></SecondPage>}
    {data.length > 115 && <SecondPage data={data}></SecondPage>}
    {data.length > 135 && <SecondPage data={data}></SecondPage>}
    {data.length > 155 && <SecondPage data={data}></SecondPage>}
  </Document>
);

export const PdfPlannedOrdersRep = ({
  data,
  fromDate,
  tillDate,
  buildWhere,
  title,
}) => {
  const client = useApolloClient();
  const { appStore } = useRootStore();
  const { data: rawData } = useQuery(gqlGetTyreCollectionsView, {
    fetchPolicy: "network-only",
    variables: {
      where: buildWhere({
        collectedStartDate: fromDate,
        collectedEndDate: tillDate,
      }),
      order: "CollectionIID DESC",
    },
  });
  const allData = rawData?.getTyreCollectionsView?.data;
  async function printPdf(client: ApolloClient<object>): Promise<void> {
    appStore.setBusy();
    const records = [...allData];
    //console.log("data=", allData);
    await pdfDocOpen(
      `OrdersReport`,
      <MyDocument
        fromDate={fromDate}
        tillDate={tillDate}
        collector={appStore.userLogin.collector}
        data={records}
        title={title}
      />
    );
    appStore.clearBusy();
  }

  return (
    <Button
      style={{ width: "160px", marginLeft: "14px" }}
      variant="success"
      onClick={() => printPdf(client)}
    >
      <FaFilePdf className="mb-1" /> Εκτύπωση σε PDF
    </Button>
  );
};
