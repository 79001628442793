import React from 'react';
import { Cell } from 'react-table';

type Props = {
  cell: Cell<any>;
};

const GridCell = ({ cell }: Props) => {
  //useWhyDidYouUpdate(`[GridCell: ${cell.column.Header}-${cell.row.index}-${cell.value}]`, cell);
  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
};

export default React.memo(GridCell);
