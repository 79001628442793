import { FieldFeedback } from 'components/common/formik';
import { Form as FForm, Formik } from 'formik';
import { useUserLoginLazyQuery, useUserSetNewPasswordMutation } from 'graphql/types';
import { useWhyDidYouUpdate } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';
import { createModal } from 'react-modal-promise';
import { useRootStore } from 'store';
import { InferType, object, ref, string } from 'yup';
 // for only what you need
//  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,




type Props = {
  open: boolean;
  close: () => void;
};

const SetPasswordModal = ({ open, close }: Props) => {
  /**
 * Setup Yup Validation
 */

const validationSchema = object().shape({
  password: string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
      "Πρέπει να είναι τουλάχιστον 6 χαρακτήρες και να περιέχει τουλάχιστον ένα Κεφαλαίο γράμμα, ένα Μικρό, και έναν Αριθμό",
    )
    .required("Το πεδίο δεν μπορεί να είναι κενό"),
  confirmPassword: string()
    .oneOf([ref('password'), null], "Η επιβεβαίωση δεν είναι σωστή")
    .required(`Το πεδίο δεν μπορεί να είναι κενό`),
});

type FormDataType = InferType<typeof validationSchema>;

  /*-------------------- Body -----------------------------------------------*/
 
  const { appStore } = useRootStore();

  const [setPassword] = useUserSetNewPasswordMutation();
  const [get, { data }] = useUserLoginLazyQuery();

  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  // * 1. Effect οταν πάρω data
  useEffect(() => {
    if (!!data) {
      const userLogin = data?.userLogin;
      appStore.login(appStore.user, userLogin);
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  /*-------------------- Handlers -------------------------------------------*/
  const handleSubmitForm = (values: FormDataType, action) => {
    console.log(values);
    setPassword({ variables: { email: appStore.user, userNewPassword: values.password } }).then(() => {
      get({ variables: { email: appStore.user, userPassword: values.password } });
    });
  };

  useWhyDidYouUpdate('[SetPasswordModal]', { data, setPassword, get });
  /*-------------------- Render ---------------------------------------------*/
  return (
    <Modal show={open} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>{"Αλλαγή Κωδικού Πρόσβασης"}</Modal.Title>
      </Modal.Header>
      <Formik
        onSubmit={handleSubmitForm}
        initialValues={{ password: '', confirmPassword: '' }}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {formikBag => (
          <Form as={FForm}>
            <Modal.Body>
              <Form.Group>
                <Form.Label className="mb-0">{"Νέος Κωδικός"}</Form.Label>
                <InputGroup>
                  <Form.Control
                    id="password"
                    type={passwordShow ? 'text' : 'password'}
                    {...formikBag.getFieldProps('password')}
                    isInvalid={!!formikBag.touched.password && !!formikBag.errors.password}
                  />
                  <InputGroup.Append>
                    <Button onClick={() => setPasswordShow(value => !value)}>
                      <FaEye />
                    </Button>
                  </InputGroup.Append>
                  <FieldFeedback type="invalid" name={'password'} />
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <Form.Label className="mb-0">{"Επιβεβαίωση Κωδικού"}</Form.Label>
                <InputGroup>
                  <Form.Control
                    id="confirmPassword"
                    type={confirmPasswordShow ? 'text' : 'password'}
                    {...formikBag.getFieldProps('confirmPassword')}
                    isInvalid={!!formikBag.touched.confirmPassword && !!formikBag.errors.confirmPassword}
                  />
                  <InputGroup.Append>
                    <Button onClick={() => setConfirmPasswordShow(value => !value)}>
                      <FaEye />
                    </Button>
                  </InputGroup.Append>
                  <FieldFeedback type="invalid" name={'confirmPassword'} />
                </InputGroup>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit">
                {'ΟΚ'}
              </Button>
              {!appStore.userLogin.userMustChangePassword && (
                <Button variant="danger" onClick={() => close()}>
                  {"ΑΚΥΡΟ"}
                </Button>
              )}
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const setPasswordModal = createModal(SetPasswordModal);

export default setPasswordModal;