import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Card, Col, Popover } from "react-bootstrap";
import { CellFloatCustom, FormCol } from "components/common";
import { useRootStore } from "store";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useLocation, useHistory } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../calendar/calendar.css";
import {
  gqlAddRsCalendar,
  gqlGetCalendarData,
  gqlUpdateRsCalendar,
} from "./graphql";
import LoadingOverlay from "react-loading-overlay";
import _ from "lodash";
import "moment/locale/el";
import { Formik } from "formik";
import { FaPlus } from "react-icons/fa";
import promiseModalSelectCalendarInput from "components/modal-select-calendar-input";
import { toastFailure } from "utils/toasts";
import { format } from "date-fns";
import promiseModalDialog from "components/common/dialogs/modal-dialog-promise";
import FieldSelectRsCollector from "components/fs-rscollectors";
import FieldSelectRecyclingStation from "pages/reports/rs-deliveries/fs-recycling-stations";
import FieldSelectCollector from "components/fs-collectors";
import Pdf from "react-to-pdf";
import { MdPrint } from "react-icons/md";
const localizer = momentLocalizer(moment);

export const CalendarPage = () => {
  const location = useLocation<any>();
  const history = useHistory();
  const { appStore } = useRootStore();
  const [pdfBusy, setPdfBusy] = useState(false);
  const [error, setError] = useState(false);
  const [RsId, setRsId] = useState(
    appStore.isRecyclingStation ? appStore.getRsId : null
  );
  const [CollectorId, setCollectorId] = useState(null);
  const [addCalendarInput] = useMutation(gqlAddRsCalendar);
  const [cancelCalendarInput] = useMutation(gqlUpdateRsCalendar);
  const [get, { data: rawData, loading, refetch }] = useLazyQuery(
    gqlGetCalendarData,
    {
      fetchPolicy: "network-only",
      variables: {
        where: JSON.stringify({
          Canceled: false,
          RsId: RsId === null ? undefined : RsId,
          //CollectorId: CollectorId === null ? undefined : CollectorId,
        }),
        whereCap: JSON.stringify({
          RsId: RsId === null ? undefined : RsId,
        }),
        whereCapExp: JSON.stringify({
          RsId: RsId === null ? undefined : RsId,
          IsActive: true,
        }),
      },
    }
  );

  const data = useMemo(
    () => (rawData ? rawData.getRsCalendars.data : undefined),
    [rawData]
  );

  const exceptions = useMemo(
    () => (rawData ? rawData.getRsCapacityExceptions.data : undefined),
    [rawData]
  );

  const getTitle = (rec) => {
    if (appStore.isCollector)
      return {
        title: (
          <div>
            <span style={{ /* color: getColor(rec), */ fontWeight: "bold" }}>
              {rec.RecyclingStation.name}
            </span>{" "}
            ({rec.PlannedWeight} TN)
          </div>
        ),
        plainTitle: `${rec.RecyclingStation.name} (${rec.PlannedWeight} TN)`,
      };
    else if (appStore.isRecyclingStation)
      return {
        title: (
          <div>
            {rec.Collector.Name} ({rec.PlannedWeight} TN)
          </div>
        ),
        plainTitle: `${rec.Collector.Name} (${rec.PlannedWeight} TN)`,
      };
    else if (appStore.isEA)
      return {
        title: (
          <div>
            <span
              style={{
                /* color: getColor(rec), */
                fontWeight: "bold",
              }}
            >
              {rec.RecyclingStation.name}
            </span>{" "}
            - {rec.Collector.Name} ({rec.PlannedWeight} TN)
          </div>
        ),
        plainTitle: `${rec.RecyclingStation.name} - ${rec.Collector.Name} (${rec.PlannedWeight} TN)`,
      };
    else
      return {
        title: "",
        plainTitle: "",
      };
  };

  const getColor = (rec) => {
    const cap = rawData.getRsCapacities.data.find(
      (record) => record.RsId === rec.RsId
    );
    return cap.RsColor;
  };

  const events = useMemo(
    () =>
      rawData
        ? rawData.getRsCalendars.data
            .filter((rec) =>
              appStore.isCollector
                ? rec.CollectorId === appStore.userLogin.collectorId
                : CollectorId !== null
                ? rec.CollectorId === CollectorId
                : true
            )
            .map((rec) => ({
              id: rec.id,
              title: getTitle(rec).title,
              plainTitle: getTitle(rec).plainTitle,
              start: new Date(new Date(rec.PlannedDate).toDateString()),
              end: new Date(new Date(rec.PlannedDate).toDateString()),
              color: getColor(rec),
            }))
        : [],
    [rawData, CollectorId]
  );

  const capacity = useMemo(
    () =>
      rawData
        ? {
            Day1: _.sumBy(rawData.getRsCapacities.data, "Day1"),
            Day2: _.sumBy(rawData.getRsCapacities.data, "Day2"),
            Day3: _.sumBy(rawData.getRsCapacities.data, "Day3"),
            Day4: _.sumBy(rawData.getRsCapacities.data, "Day4"),
            Day5: _.sumBy(rawData.getRsCapacities.data, "Day5"),
            Day6: _.sumBy(rawData.getRsCapacities.data, "Day6"),
            Day7: _.sumBy(rawData.getRsCapacities.data, "Day7"),
          }
        : undefined,
    [rawData]
  );

  const fetchData = useCallback(async () => {
    get();
  }, [get]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      //const filterParams = location?.state?.filterValues ?? {};
      fetchData();
    }
  }, [RsId]);

  /* useEffect(() => {
    refetch({
      where: JSON.stringify({ RsId: RsId }),
    });
  }, [RsId]); */

  const handleRsId = async (RsId) => {
    setRsId(RsId);
  };

  const handleCollectorId = async (CollectorId) => {
    setCollectorId(CollectorId);
  };

  const calculateNewCap = (exps, date) => {
    const newCapacities = [];
    exps.map((exp) => {
      const cap = rawData.getRsCapacities.data.find(
        (rec) => rec.RsId === exp.RsId
      );
      newCapacities.push({
        ...cap,
        [`Day${date.getDay() + 1}`]: exp.CapacityWeight,
      });
    });
    const newCap = rawData.getRsCapacities.data.map(
      (obj) => newCapacities.find((o) => o.RsId === obj.RsId) || obj
    );
    return _.sumBy(newCap, `Day${date.getDay() + 1}`);
  };

  const getTotalDay = (date, capacity) => {
    let exps = exceptions.filter(
      (exp) =>
        exp.ExceptionDateTo === null &&
        new Date(exp.ExceptionDate).getDate() === date.getDate() &&
        new Date(exp.ExceptionDate).getMonth() === date.getMonth()
    );
    if (exps.length === 0) {
      exps = exceptions.filter(
        (exp) =>
          new Date(exp.ExceptionDate).getDate() <= date.getDate() &&
          new Date(exp.ExceptionDateTo).getDate() >= date.getDate() &&
          new Date(exp.ExceptionDate).getMonth() === date.getMonth() &&
          date.getDay() !== 0
      );
    }

    let cap;
    //console.log("$$$exps= ", exps);
    if (exps.length > 0) {
      cap = calculateNewCap(exps, date);
    } else {
      cap = capacity;
    }
    const total = _.sumBy(
      data.filter(
        (rec) =>
          new Date(rec.PlannedDate).getDate() === date.getDate() &&
          new Date(rec.PlannedDate).getMonth() === date.getMonth()
      ),
      "PlannedWeight"
    );
    return {
      remaining: cap - total,
      cap: cap,
      header: `Διαθεσιμότητα ${(cap - total).toFixed(2)} TN`,
    };
  };

  const CustomDateHeader = ({ date }) => {
    const { remaining, cap, header } = getTotalDay(
      date,
      capacity[`Day${date.getDay() + 1}`]
    );
    const bgColor =
      remaining < 1 ? "#ff3838" : 0.3 * cap > remaining ? "#fa9600" : "#2cff19";
    return (
      <div className="d-flex flex-row justify-content-between my-3">
        <div
          style={{
            padding: "2px 5px",
            backgroundColor: bgColor,
            borderRadius: "5px",
            border: "none",
          }}
        >
          {header}
        </div>
        <div>{date.getDate()}</div>
      </div>
    );
  };

  const FilterStation = () => {
    return appStore.isCollector ? (
      <FieldSelectRsCollector
        onChange={(e) => {
          handleRsId(e.value);
        }}
        where={JSON.stringify({ CollectorId: appStore.userLogin.collectorId })}
        as={FormCol}
        name="RsId"
        label="Σταθμός Ανακύκλωσης"
        //defaultValues={!!data ? data[0] : undefined}
      />
    ) : (
      <>
        <FieldSelectRecyclingStation
          onChange={(e) => {
            handleRsId(e.value);
          }}
          //where={JSON.stringify({ isNotActive: false })}
          as={FormCol}
          name="RsId"
          label="Σταθμός Ανακύκλωσης"
          //defaultValues={!!data ? data[0] : undefined}
        />
        <FieldSelectCollector
          onChange={(e) => {
            handleCollectorId(e.value);
          }}
          //where={JSON.stringify({ isNotActive: false })}
          as={FormCol}
          name="CollectorId"
          label="Συλλέκτης"
          //defaultValues={!!data ? data[0] : undefined}
        />
      </>
    );
  };

  const handleAddCalendarInput = async () => {
    const ret = await promiseModalSelectCalendarInput({
      title: "Καινούργια Εγγραφή",
      collectorId: appStore.isCollector
        ? appStore.userLogin.collectorId
        : undefined,
      rsId: RsId,
    });
    if (!ret) return;
    appStore.setBusy();
    await addCalendarInput({
      variables: {
        data: ret,
      },
    })
      .catch((err) => {
        console.log("err= ", err);
        toastFailure(err ? err.graphQLErrors[0].message : "Σφάλμα");
      })
      .finally(() => {
        appStore.clearBusy();
        refetch();
      });
  };

  const handleSelectedEvent = async (event) => {
    if (appStore.isRecyclingStation) return;
    const res = await promiseModalDialog({
      title: "Διαγραφή Παράδοσης",
      text: `Είστε σίγουρος ότι θέλετε να διαγράψετε την παράδοση ${
        event.plainTitle
      } της ημερομηνίας ${format(event.start, "dd/MM/yyyy")};`,
    });

    if (!res) return;
    appStore.setBusy();
    await cancelCalendarInput({
      variables: {
        data: { id: event.id, Canceled: true, CanceledBy: appStore.user },
      },
    })
      .catch((err) => {
        console.log("err= ", err);
        toastFailure(err ? err.graphQLErrors[0].message : "Σφάλμα");
      })
      .finally(() => {
        appStore.clearBusy();
        refetch();
      });
  };

  return (
    <>
      <Card className="shadow">
        <Card.Header>
          <Card.Title className="m-0 d-flex flex-row justify-content-between align-items-center">
            <div></div>
            <div
              className="d-flex flex-column align-items-center"
              style={{ width: "80%" }}
            >
              <h3>Ημερολόγιο Παραδόσεων</h3>
              {!appStore.isRecyclingStation && (
                <>
                  <br />
                  <Formik initialValues={{ RsId: [] }} onSubmit={() => {}}>
                    <FilterStation />
                  </Formik>
                </>
              )}
            </div>
            {!appStore.isRecyclingStation ? (
              <Button
                size="lg"
                className={"rounded-pill"}
                variant="success"
                onClick={handleAddCalendarInput}
                data-toggle="tooltip"
                data-placement="left"
              >
                <FaPlus className={`mb-1 mr-2`} />
                Προσθήκη Παράδοσης
              </Button>
            ) : (
              <div></div>
            )}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Pdf
            filename={"Ημερολόγιο Παραδόσεων" + ".pdf"}
            x={20}
            y={50}
            options={{ orientation: "p", unit: "pt", format: [2050, 1450] }}
            onComplete={() => setPdfBusy(false)}
          >
            {({ toPdf, targetRef }) => (
              <div ref={targetRef}>
                <LoadingOverlay
                  active={loading}
                  spinner
                  text="Παρακαλώ περιμένετε..."
                  style={{ zIndex: "1" }}
                >
                  {!!events && !!capacity && (
                    <>
                      {!appStore.isRecyclingStation && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <p>
                              * Πατήστε το κουμπί "Προσθήκη Παράδοσης" για να
                              προσθέσετε μια καινούργια παράδοση.
                              <br />
                              ** Πατήστε πάνω σε μια παράδοση για να την
                              διαγράψετε.
                            </p>
                          </div>
                          <div>
                            <Button
                              onClick={toPdf}
                              style={{ width: "200px" }}
                              className="ml-2"
                              variant="primary"
                            >
                              <MdPrint className="mb-1" /> {`Εκτύπωση`}
                            </Button>
                          </div>
                        </div>
                      )}

                      <Calendar
                        localizer={localizer}
                        onSelectEvent={(event) => handleSelectedEvent(event)}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        messages={{
                          next: "Επόμενο",
                          previous: "Προηγούμενο",
                          today: "Σήμερα",
                          month: "Μήνας",
                          week: "Εβδομάδα",
                          day: "Ημέρα",
                          agenda: "Ατζέντα",
                        }}
                        components={{
                          month: {
                            dateHeader: ({ date, localizer }) => (
                              <CustomDateHeader date={date} />
                            ),
                          },
                        }}
                        eventPropGetter={(event) => {
                          const backgroundColor = event.color;
                          return { style: { backgroundColor } };
                        }}
                        style={{ minHeight: "80vh" }}
                      />
                    </>
                  )}
                </LoadingOverlay>
              </div>
            )}
          </Pdf>
        </Card.Body>
      </Card>
    </>
  );
};
