import React, { useCallback, useMemo } from 'react';
import { Form, OverlayTrigger } from 'react-bootstrap';
import { useField, useFormikContext, FieldAttributes } from 'formik';

import { FieldLabel } from './../index';

import FieldTooltip from './field-tooltip';
import { Tooltip } from 'react-bootstrap';
import { FieldFeedback } from '.';

type Props = {
  as?: React.ElementType<any>;
  label: string | React.ReactNode;
  pattern?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  type?: string;
  size?: string;
} & FieldAttributes<any>;

const FieldInput = ({
  as = 'div',
  name,
  label,
  pattern,
  disabled = false,
  placeholder = '',
  required = false,
  type = 'text',
  hook,
  where,
  size,
}: Props) => {
  //
  //-------------------- Body -----------------------------------------------
  //
  const [{ value }, { touched, error, initialValue }] = useField<string>(name);
  const { setFieldValue, setFieldTouched } = useFormikContext<any>();

  const setFieldValueAndTouched = useCallback(
    async (_value, touched: boolean) => {
      await setFieldValue(name, _value, true); //field,value,shouldValidate
      await setFieldTouched(name, touched); //field,touched,shouldValidate
      if (hook) {
        console.log('hook if');

        if (_value.length > 5) {
          console.log('value if');
          hook({ variables: { where: where(_value) } });
        }
      }
    },
    [hook, name, setFieldTouched, setFieldValue, where],
  );
  //
  //-------------------- Handlers -------------------------------------------
  //
  const handleChange = useCallback(
    event => {
      setFieldValueAndTouched(event.target.value, true);
    },
    [setFieldValueAndTouched],
  );

  const handleBlur = useCallback(async () => {
    await setFieldTouched(name, touched); //field,touched,shouldValidate
  }, [setFieldTouched, name, touched]);

  const changed = value !== initialValue;

  // useWhyDidYouUpdate(`[FieldInput:${name}]`, {
  //   changed,
  //   disabled,
  //   error,
  //   handleBlur,
  //   handleChange,
  //   initialValue,
  //   label,
  //   name,
  //   placeholder,
  //   required,
  //   setFieldTouched,
  //   setFieldValue,
  //   touched,
  //   type,
  //   value,
  // });
  //
  //-------------------- Render -------------------------------------------
  //
  return useMemo(() => {
    return (
      <Form.Group as={as} controlId={name} className="mb-2">
        <FieldLabel size={size} text={label} changed={changed} required={required} />
        <OverlayTrigger
          delay={{ show: 1000, hide: 200 }}
          overlay={
            type === 'password' ? (
              <Tooltip id="password-tooltip">Εισαγωγή κωδικού</Tooltip>
            ) : (
              FieldTooltip({ text: name, value: value || '' })
            )
          }
          placement="top"
        >
          <Form.Control
            style={required && !disabled ? { backgroundColor: 'lightYellow' } : {}}
            className="mb-3"
            size={size}
            type={type}
            name={name}
            pattern={pattern}
            value={value || ''}
            placeholder={placeholder}
            isInvalid={touched && !!error}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </OverlayTrigger>
        <FieldFeedback type="invalid" name={name} />
      </Form.Group>
    );
  }, [as, name, label, changed, required, type, value, disabled, size, pattern, placeholder, touched, error, handleChange, handleBlur]);
};

export default FieldInput;
