import React from "react";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import { FsProps } from "components/common/formik/FsProps";
import { FieldSelectDB } from "./common";

const gqlGetCollectors = gql`
  query getCollectors(
    $where: String
    $offset: Int
    $limit: Int
    $order: String
  ) {
    getCollectors(
      where: $where
      offset: $offset
      limit: $limit
      order: $order
    ) {
      data {
        id: ID
        description: Name
      }
      recordCount
    }
  }
`;

function useGetCollectorsLazyQuery() {
  return useLazyQuery(gqlGetCollectors, {
    variables: { where: JSON.stringify({ IsNotActive: false }) },
    fetchPolicy: "no-cache",
  });
  //return [null, { loading: null, data: null }];
}

const FieldSelectCollector = ({
  label = "Συλλέκτης",
  name = "CollectorId",
  defaultValues = {},
  ...rest
}: FsProps) => {
  return (
    <FieldSelectDB
      {...rest}
      label={label}
      name={name}
      // @ts-ignore
      sbLabel={defaultValues?.Collector?.Name ?? undefined}
      hook={useGetCollectorsLazyQuery}
    />
  );
};

export default FieldSelectCollector;
