import React, { useMemo, useCallback, useState } from "react";
import { Card, Row } from "react-bootstrap";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { gqlGetTyreCollectionsResponseDays } from "./graphql";
import {
  TablePaginated,
  FlexRow,
  FormCol,
  CellDate,
  CellFloatCustom,
  CellCenter,
} from "components/common";
import { useRootStore } from "store";
import { useHistory } from "react-router";
import { FormFilterCard } from "components/common/formik";
import LoadingOverlay from "react-loading-overlay";
import { useLocation } from "react-router-dom";
import { useHandleError } from "services";
import {
  buildWhereForDates,
  FieldDateRanger,
} from "components/common/formik/field-dateranger";
import exportXlsModal from "components/common/export-xls-modal";
import { format } from "date-fns";
import { Field } from "formik";
import CustomSelectComponent from "components/custom-select";
import FieldSelectCstPointTypes from "components/fs-cstpointtypes";
import FieldSelectNomoiAll from "components/fs-nomoi-all";
import FieldSelectMunicipalitiesAll from "components/fs-municipalities-all copy";
import moment from "moment";
import { sqlDateTimeUtcStr } from "utils/sqlhelpfunc";

interface FilterParams {
  startDate: Date;
  endDate: Date;
}
const initFilters: FilterParams = {
  startDate: moment()
    .startOf("month")
    .toDate(),
  endDate: moment()
    .endOf("month")
    .toDate(),
};

/* ----------- Page ------------------ */
export const ResponseDaysPage = () => {
  const { appStore } = useRootStore();
  const { handleGraphqlError } = useHandleError("containerorders");
  /* ----------------- Route State -------------------------- */
  const location = useLocation<any>();
  const history = useHistory();
  const [routeFilterValues] = useState(
    location?.state?.filterValues ?? initFilters
  );
  // Στο state του routing αποθηκεύω:
  //    1. την τρέχουσα σελίδα του table pagination (pageIndex)
  //  & 2. τα values των φίλτρων (filterValues)

  /* ------------------- Query ------------------------------- */
  const tablePageSize = appStore.pageSize;
  const [initPageIndex, setInitPageIndex] = useState(
    location?.state?.pageIndex ?? 0
  );

  const [queryParams, setQueryParams] = useState({
    offset: tablePageSize * initPageIndex,
    limit: tablePageSize,
    order: location?.state?.order ?? "Year, CodeId",
    where: buildWhereFromFilterValues(routeFilterValues),
  });
  const { loading, data: rawData, error, refetch } = useQuery(
    gqlGetTyreCollectionsResponseDays,
    {
      variables: { ...queryParams },
      fetchPolicy: "network-only",
    }
  );
  const data = rawData?.getTyreCollectionsResponseDays?.data;
  const dataCount = rawData?.getTyreCollectionsResponseDays?.recordCount;
  const dataSums = rawData?.getTyreCollectionsResponseDays?.sums;

  /* ------------------- Διαχείριση Φίλτρων ------------------------------ */
  function buildWhereFromFilterValues(values): string {
    function sqlDateTimeUtcStr(dt: Date): string {
      if (dt) {
        return moment(dt).format("YYYY-MM-DDTHH:mm:ss.000");
      } else {
        return moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.000");
      }
    }
    // Φτιάξε το where για το graphql
    // console.log("filtersChanged 2", values);
    // console.log("$$$ 1", values.startDate, values.endDate);

    const newValues = { ...values };
    newValues.startDate = sqlDateTimeUtcStr(
      moment(values.startDate)
        .startOf("day")
        .toDate()
    );
    newValues.endDate = sqlDateTimeUtcStr(
      moment(values.endDate)
        .endOf("day")
        .toDate()
    );
    // console.log("$$$ 2", newValues.startDate, newValues.endDate);
    // console.log("$$$ 3", JSON.stringify(newValues));

    return JSON.stringify(newValues);
    // return JSON.stringify(values);
  }

  const filtersChanged = useCallback(
    (values) => {
      console.log("filtersChanged", values);
      // Φτιάξε το where για το graphql
      const where = buildWhereFromFilterValues(values);
      const order = values.orderBy !== "" ? values.orderBy : undefined;
      // Σύνθεσε τα vars του graphql
      const newQueryParams = {
        ...queryParams,
        offset: 0,
        where: where,
        order: order,
      };
      setQueryParams(newQueryParams); // αποθήκευσε τα vars
      refetch(newQueryParams); // φερε τα δεδομένα απο τον σερβερ
      setInitPageIndex(0); // αρχικοποίησε το gridTable στην 1η σελίδα

      // Ενημέρωσε το State του Route
      history.replace(location.pathname, {
        filterValues: values,
        order: order,
        pageIndex: 0,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, location.pathname, queryParams, refetch]
  );

  /* handle Pagination */
  const gridPageChanged = useCallback(
    (pageIndex, pageSize: number) => {
      console.log("gridPageChanged", pageIndex, pageSize);
      setInitPageIndex(pageIndex);
      const newQueryParams = {
        ...queryParams,
        offset: pageSize * pageIndex,
        limit: pageSize,
      };
      setQueryParams(newQueryParams);
      refetch(newQueryParams);

      // Ενημέρωσε το State του Route
      const state = {
        ...location.state,
        pageIndex,
      };
      history.replace(location.pathname, state);
    },
    [history, location.pathname, location.state, queryParams, refetch]
  );

  function useGetExcelQuery() {
    return useLazyQuery(gqlGetTyreCollectionsResponseDays);
    //return [null, { loading: null, data: null }];
  }

  const getExcelTitle = () => {
    const filters = location?.state?.filterValues ?? undefined;
    let titles = ["Ημέρες Εξυπηρέτησης Αιτήσεων & Βάρος Συλλογών"];
    if (filters?.startDate || filters?.endDate) {
      const startDate = filters?.startDate
        ? format(filters.startDate, "dd/MM/yyyy")
        : " - ";
      const endDate = filters?.endDate
        ? format(filters.endDate, "dd/MM/yyyy")
        : " - ";
      titles.push("Ημερομηνία Από: " + startDate + "  Έως: " + endDate);
    }
    if (filters?.nomos) titles.push("Νομός: " + filters.nomos);

    if (filters?.municipality) titles.push("Δήμος: " + filters.municipality);

    if (filters?.responseDays) {
      const rec = responseDaysOptions.find(
        ({ value }) => value === filters.responseDays
      );
      titles.push("Ημέρες Εξυπηρέτησης: " + rec?.label);
    }
    if (filters?.tyreTypes) {
      const rec = tyreTypesOptions.find(
        ({ value }) => value === filters.tyreTypes
      );
      titles.push("Τύπος Ελαστικών: " + rec?.label);
    }
    if (filters?.cstPointType) {
      // titles.push("Κατηγορία Σημείων: " + filters.cstPointType);
      const rec = cstPointTypeOptions.find(
        ({ value }) => value === filters.cstPointType
      );
      titles.push("Κατηγορία Σημείων: " + rec?.label);
    }

    return titles.join("\n");
  };

  const handleExport = useCallback(async () => {
    await exportXlsModal({
      getLazyQuery: useGetExcelQuery,
      filterVariables: queryParams,
      tableName: "Tyre_Collections_View",
      title: getExcelTitle(),
      columnsDef: [
        { fieldName: "CollectionIID", fieldTitle: "Α/Α", fieldType: "INT" },
        {
          fieldName: "CodeId",
          fieldTitle: "Αριθμός Αίτησης",
          fieldType: "INT",
        },
        {
          fieldName: "Year",
          fieldTitle: "Έτος",
          fieldType: "TEXT",
        },
        {
          fieldName: "CstPointName",
          fieldTitle: "Σημείο Συλλογής",
          fieldType: "TEXT",
        },
        {
          fieldName: "CstPointTypeName",
          fieldTitle: "Κατηγορία Σημείου",
          fieldType: "TEXT",
        },
        {
          fieldName: "CstPointAddress",
          fieldTitle: "Διεύθυνση",
          fieldType: "TEXT",
        },
        {
          fieldName: "CstPointMunicipality",
          fieldTitle: "Δήμος",
          fieldType: "TEXT",
        },
        { fieldName: "CstPointNomos", fieldTitle: "Νομός", fieldType: "TEXT" },
        {
          fieldName: "RequestDate",
          fieldTitle: "Ημερομηνία Αίτησης",
          fieldType: "DATETIME",
        },
        {
          fieldName: "CollectionDate",
          fieldTitle: "Ημερομηνία Συλλογής",
          fieldType: "DATETIME",
        },
        {
          fieldName: "ResponseDays",
          fieldTitle: "Ημέρες Εξυπηρέτησης",
          fieldType: "INTqty",
        },
        {
          fieldName: "TheoriticalWeight",
          fieldTitle: "Θεωρητικό Βάρος (kg)",
          fieldType: "INTqty",
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  if (error) {
    handleGraphqlError("query-get", error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <Card className="shadow overflowscroll">
        <Card.Header>
          <FlexRow>
            <Card.Title className="m-0">
              Ημέρες Εξυπηρέτησης Αιτήσεων & Βάρος Συλλογών
            </Card.Title>
          </FlexRow>
        </Card.Header>
        <Card.Body className="nopadding">
          {/* Φίλτρα */}
          <Filters
            onChange={filtersChanged}
            initialValues={routeFilterValues}
          />

          {/* TableGrid */}
          <LoadingOverlay
            active={loading}
            spinner
            text="Παρακαλώ περιμένετε..."
          >
            {rawData ? (
              <ContainersGrid
                data={data}
                dataSums={dataSums}
                dataCount={dataCount}
                initPageIndex={initPageIndex}
                onPageChange={gridPageChanged}
                handleExport={handleExport}
                buildWhereFromFilterValues={buildWhereFromFilterValues}
                refetch={refetch}
                getTitle={getExcelTitle}
              />
            ) : (
              <div style={{ height: "200px" }}>{}</div>
            )}
          </LoadingOverlay>
        </Card.Body>
      </Card>
    );
  }
};

/* ----------- Grid ------------------ */

const ContainersGrid = ({
  data,
  dataSums,
  dataCount,
  initPageIndex,
  onPageChange,
  handleExport,
  buildWhereFromFilterValues,
  refetch,
  getTitle,
}) => {
  //console.log(props.dataX);

  const location = useLocation<any>();
  const columns = useMemo(
    () => [
      {
        Header: "Αριθμός Αίτησης",
        accessor: "CodeId",
        Cell: ({ row }) => {
          return (
            <div className="text-left">
              {row.original.CodeId} / {row.original.Year}
            </div>
          );
        },
      },
      { Header: "Σημείο Συλλογής", accessor: "CstPointName" },
      { Header: "Διεύθυνση", accessor: "CstPointAddress" },
      { Header: "Δήμος", accessor: "CstPointMunicipality" },
      { Header: "Νομός", accessor: "CstPointNomos" },
      { Header: "Ημ/νία Αίτησης", accessor: "RequestDate", Cell: CellDate },
      { Header: "Ημ/νία Συλλογής", accessor: "CollectionDate", Cell: CellDate },
      {
        Header: "Ημέρες Εξυπηρέτησης",
        accessor: "ResponseDays",
        Cell: CellCenter,
        Footer: () => {
          // return <span>{dataSums?.ResponseDays}</span>;
          return CellFloatCustom(
            10,
            2
          )({ cell: { value: dataSums?.ResponseDays } });
        },
      },
      {
        Header: "Θεωρητικό Βάρος (kg)",
        accessor: "TheoriticalWeight",
        Cell: CellFloatCustom(10, 1),
        Footer: () => {
          // return <span>{dataSums?.TheoriticalWeight}</span>;
          return CellFloatCustom(
            10,
            1
          )({ cell: { value: dataSums?.TheoriticalWeight } });
        },
      },
    ],
    [refetch]
  );
  return (
    <div style={{ minHeight: "260px" }}>
      <TablePaginated
        title={""}
        columns={columns}
        data={data}
        dataCount={dataCount}
        // initPageIndex={initPage}
        // onSortByChange={handleSortByChange}
        onExport={handleExport}
        onPageChange={onPageChange}
        initPageIndex={initPageIndex}
        hasFooter={true}
      />
      {/* <PdfPlannedOrdersRep
        buildWhere={buildWhereFromFilterValues}
        fromDate={location?.state?.filterValues?.collectedStartDate}
        tillDate={location?.state?.filterValues?.collectedEndDate}
        title={getTitle()}
        data={data}
      ></PdfPlannedOrdersRep> */}
    </div>
  );
};

/* ----------- Filters ------------------ */
const responseDaysOptions = [
  { value: "0", label: "Όλες" },
  { value: "1", label: "0 - 3" },
  { value: "2", label: "3,01 - 5" },
  { value: "3", label: "5,01 - max" },
];
const tyreTypesOptions = [
  { value: "1", label: "Όλα εκτός Δ1" },
  { value: "2", label: "Δ1" },
  { value: "3", label: "Όλα" },
];
const cstPointTypeOptions = [
  { value: "0", label: "Καταστήματα" },
  { value: "1", label: "Κάτοχοι εκτος Διαλυτηρίων" },
  { value: "2", label: "Διαλυτήρια" },
];
const Filters = ({ onChange, initialValues }) => {
  function handleFiltersSubmit(values, { setSubmitting }) {
    setTimeout(() => {
      setSubmitting(false);
      console.log("values", values);
      onChange(values);
    }, 400);
  }
  console.log("filters-init ", initialValues);

  const filters = {
    startDate: initialValues?.startDate ?? null,
    endDate: initialValues?.endDate ?? null,
    nomos: initialValues?.nomos ?? null,
    municipality: initialValues?.municipality ?? null,
    responseDays: initialValues?.responseDays ?? "0",
    tyreTypes: initialValues?.tyreTypes ?? "1",
    cstPointType: initialValues?.cstPointType ?? null,
  };
  const { appStore } = useRootStore();

  return (
    <FormFilterCard
      title="Κριτήρια Αναζήτησης"
      onSubmit={handleFiltersSubmit}
      initialValues={{
        startDate: filters?.startDate,
        endDate: filters?.endDate,
        nomos: filters?.nomos,
        municipality: filters?.municipality,
        responseDays: filters?.responseDays,
        tyreTypes: filters?.tyreTypes,
        cstPointType: filters?.cstPointType,
      }}
    >
      <Row>
        <FieldDateRanger
          as={FormCol}
          label="Ημερομηνία από"
          name="startDate"
          name2="endDate"
        />
        <FieldSelectNomoiAll as={FormCol} />
        <FieldSelectMunicipalitiesAll as={FormCol} />
      </Row>
      <Row>
        <Field
          name={"responseDays"}
          title="Ημέρες Εξυπηρέτησης"
          component={CustomSelectComponent}
          options={responseDaysOptions}
        />
        <Field
          name={"tyreTypes"}
          title="Τύπος Ελαστικών"
          component={CustomSelectComponent}
          options={tyreTypesOptions}
        />
        {/* <FieldSelectCstPointTypes as={FormCol} /> */}
        <Field
          name={"cstPointType"}
          title="Κατηγορία Σημείων Παραλαβής"
          component={CustomSelectComponent}
          options={cstPointTypeOptions}
        />
      </Row>
    </FormFilterCard>
  );
};
