import React, { useMemo, useCallback, useState } from "react";
import { Card, Row } from "react-bootstrap";
import { useLazyQuery } from "@apollo/react-hooks";
import { gqlGetRsDeliveriesView } from "./graphql";
import {
  TablePaginated,
  FlexRow,
  FormCol,
  CellDateTime,
  CellFloatCustom,
  CellIntCustom,
} from "components/common";
import { useRootStore } from "store";
import { useHistory } from "react-router";
import { FormFilterCard } from "components/common/formik";
import { FieldInput } from "components/common/formik";
import LoadingOverlay from "react-loading-overlay";
import { useLocation } from "react-router-dom";
import { useHandleError } from "services";
import exportXlsModal from "components/common/export-xls-modal";
import { useEffect } from "react";
import { FieldDateRanger } from "components/common/formik/field-dateranger";
import FieldCheckbox from "components/common/formik/field-checkbox";
import FieldSelectRecyclingStation from "./fs-recycling-stations";
import FieldSelectSalesArea from "./fs-sales-area";
import { format, parseISO } from "date-fns";

export const RsDeliveriesPage = () => {
  const { appStore } = useRootStore();
  const { handleGraphqlError } = useHandleError("RsDeliveriesPage");
  /* ----------------- Route State -------------------------- */
  const location = useLocation<any>();
  const history = useHistory();
  // Στο state του routing αποθηκεύω:
  //    1. την τρέχουσα σελίδα του table pagination (pageIndex)
  //  & 2. τα values των φίλτρων (filterValues)

  /* ------------------- Query ------------------------------- */
  const tablePageSize = appStore.pageSize;
  const [initPageIndex, setInitPageIndex] = useState(
    location?.state?.pageIndex ?? 0
  );

  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);

  const [queryParams, setQueryParams] = useState({
    offset: tablePageSize * initPageIndex,
    limit: tablePageSize,
    order: location?.state?.order ?? "grossDate DESC",
    where: buildWhereFromFilterValues(location?.state?.filterValues),
  });
  const [fetchData, { loading, error, refetch }] = useLazyQuery(
    gqlGetRsDeliveriesView,
    {
      //variables: { ...queryParams },
      fetchPolicy: "network-only",
      onCompleted: (ret) => {
        var lData = ret?.getRsDeliveriesView?.data;
        if (lData) {
          lData = lData.map((i) => {
            return {
              ...i,
              grossDate: i?.grossDate?.slice(0, -1),
              tareDate: i?.tareDate?.slice(0, -1),
            };
          });
        }
        setData(lData);
        setDataCount(ret?.getRsDeliveriesView?.recordCount);
      },
    }
  );

  useEffect(() => {
    async function callAsync() {
      const variables = {
        offset: tablePageSize * initPageIndex,
        limit: tablePageSize,
        order: location?.state?.order ?? "grossDate DESC",
        where: buildWhereFromFilterValues(location?.state?.filterValues),
      };
      fetchData({ variables });
    }
    callAsync();
  }, [fetchData, initPageIndex, location, tablePageSize]);

  /* ------------------- Διαχείριση Φίλτρων ------------------------------ */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function buildWhereFromFilterValues(values): string {
    // Φτιάξε το where για το graphql
    let where = {};
    if (values) {
      if (values.grossStartDate && values.grossEndDate) {
        const newGrossEndDate = new Date(values.grossEndDate);
        newGrossEndDate.setDate(newGrossEndDate.getDate() + 1);
        where = {
          ...where,
          grossDate: {
            $and: [{ $gte: values.grossStartDate }, { $lt: newGrossEndDate }],
          },
        };
      } else if (values.grossStartDate && values.grossEndDate === null) {
        where = {
          ...where,
          $GrossDate$: { $gt: values.grossStartDate },
        };
      } else if (values.grossStartDate === null && values.grossEndDate) {
        const newGrossEndDate = new Date(values.grossEndDate);
        newGrossEndDate.setDate(newGrossEndDate.getDate() + 1);
        where = { ...where, $GrossDate$: { $lt: newGrossEndDate } };
      }

      if (values.searchText) {
        const searchText = "%" + values.searchText + "%";
        const likeObj = { $like: searchText };
        where = {
          ...where,
          $or: [{ $VehPlateNo$: values.searchText }, { $delivId$: likeObj }],
        };
      }

      if (values.recyclingStationId) {
        where = {
          ...where,
          rsId: values.recyclingStationId,
        };
      }

      if (values.salesAreaId) {
        where = {
          ...where,
          salesAreaId: values.salesAreaId,
        };
      }

      if (!!values.tyreType4Only) {
        where = {
          ...where,
          tyreType4Qty: { $gt: 0 },
        };
      }
    }
    //where = { ...where, IsNotActive: false, CollectorId: appStore.userLogin.collectorId  };
    return JSON.stringify(where);
  }

  const filtersChanged = useCallback(
    (values) => {
      console.log("filtersChanged", values);
      // Φτιάξε το where για το graphql
      const where = buildWhereFromFilterValues(values);
      const order = "grossDate DESC";
      // Σύνθεσε τα vars του graphql
      const newQueryParams = {
        ...queryParams,
        offset: 0,
        where: where,
        order: order,
      };
      setQueryParams(newQueryParams); // αποθήκευσε τα vars
      //refetch(newQueryParams); // φερε τα δεδομένα απο τον σερβερ
      setInitPageIndex(0); // αρχικοποίησε το gridTable στην 1η σελίδα

      // Ενημέρωσε το State του Route
      history.replace(location.pathname, {
        filterValues: values,
        order: order,
        pageIndex: 0,
      });
    },
    [buildWhereFromFilterValues, history, location.pathname, queryParams]
  );

  /* handle Pagination */
  const gridPageChanged = useCallback(
    (pageIndex, pageSize: number) => {
      console.log("gridPageChanged", pageIndex, pageSize);
      setInitPageIndex(pageIndex);
      const newQueryParams = {
        ...queryParams,
        offset: pageSize * pageIndex,
        limit: pageSize,
      };
      setQueryParams(newQueryParams);
      refetch(newQueryParams);

      // Ενημέρωσε το State του Route
      const state = {
        ...location.state,
        pageIndex,
      };
      history.replace(location.pathname, state);
    },
    [history, location.pathname, location.state, queryParams, refetch]
  );

  function useGetRsDeliveriesView() {
    return useLazyQuery(gqlGetRsDeliveriesView);
    //return [null, { loading: null, data: null }];
  }

  const handleExport = useCallback(async () => {
    await exportXlsModal({
      getLazyQuery: useGetRsDeliveriesView,
      filterVariables: queryParams,
      tableName: "ΠΑΡΑΔΟΣΕΙΣ",
      columnsDef: [
        { fieldName: "rsName", fieldTitle: "Σταθμός", fieldType: "TEXT" },

        {
          fieldTitle: "Συλλέκτης",
          fieldName: "collectorName",
          fieldType: "TEXT",
        },
        { fieldTitle: "Αρ.Ζύγισης", fieldName: "delivId", fieldType: "TEXT" },
        { fieldTitle: "Όχημα", fieldName: "vehPlateNo", fieldType: "TEXT" },
        { fieldTitle: "Οδηγός", fieldName: "driverName", fieldType: "TEXT" },
        {
          fieldTitle: "Ημ/νία Μικτού",
          fieldName: "grossDate",
          fieldType: "DATETIME",
        },
        {
          fieldTitle: "Μικτό Βάρος (Kg)",
          fieldName: "grossWeight",
          fieldType: "TEXT",
        },
        {
          fieldTitle: "Ημ/νία Απόβαρου",
          fieldName: "tareDate",
          fieldType: "DATETIME",
        },
        {
          fieldTitle: "Απόβαρο (Kg)",
          fieldName: "tareWeight",
          fieldType: "TEXT",
        },
        {
          fieldTitle: "Καθαρό Βάρος (Kg)",
          fieldName: "netWeight",
          fieldType: "TEXT",
        },
        {
          fieldTitle: "Θεωρητικό Βάρος Ελαστικών (Kg)",
          fieldName: "tyreCalcWeight",
          fieldType: "TEXT",
        },
        { fieldTitle: "Υλικό", fieldName: "materialName", fieldType: "TEXT" },
        { fieldTitle: "Παραστατικό", fieldName: "docId", fieldType: "TEXT" },
        {
          fieldTitle: "Τύπος Παραλαβής",
          fieldName: "deliveryTypeName",
          fieldType: "TEXT",
        },
        {
          fieldTitle: "Νομός Συλλογής",
          fieldName: "salesAreaName",
          fieldType: "TEXT",
        },
        {
          fieldTitle: "Αριθμός Δελτίου ΑΠΠ",
          fieldName: "tabletECoNid",
          fieldType: "TEXT",
        },
        {
          fieldTitle: "Ελαστικά Κατηγ. Α",
          fieldName: "tyreType1Qty",
          fieldType: "TEXT",
        },
        {
          fieldTitle: "Ελαστικά Κατηγ. Β",
          fieldName: "tyreType2Qty",
          fieldType: "TEXT",
        },
        {
          fieldTitle: "Ελαστικά Κατηγ. Γ",
          fieldName: "tyreType3Qty",
          fieldType: "TEXT",
        },
        {
          fieldTitle: "Ελαστικά Κατηγ. Δ1",
          fieldName: "tyreType4Qty",
          fieldType: "TEXT",
        },
        {
          fieldTitle: "Ελαστικά Κατηγ. Δ2",
          fieldName: "tyreType5Qty",
          fieldType: "TEXT",
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  //
  if (error) {
    handleGraphqlError("query-get", error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <Card className="shadow overflowscroll">
        <Card.Header>
          <FlexRow>
            <Card.Title className="m-0">
              Παραδόσεις Ελαστικών σε Σταθμούς Ανακύκλωσης
            </Card.Title>
          </FlexRow>
        </Card.Header>
        <Card.Body className="nopadding">
          {/* Φίλτρα */}
          <CollectionPointsFilters
            onChange={filtersChanged}
            initialValues={location?.state?.filterValues ?? {}}
          />

          {/* TableGrid */}
          <LoadingOverlay
            active={loading}
            spinner
            text="Παρακαλώ περιμένετε..."
          >
            {data ? (
              <CollectionPointsGrid
                data={data}
                dataCount={dataCount}
                initPageIndex={initPageIndex}
                onPageChange={gridPageChanged}
                handleExport={handleExport}
              />
            ) : (
              <div style={{ height: "200px" }}>{}</div>
            )}
          </LoadingOverlay>
        </Card.Body>
      </Card>
    );
  }
};

/* ----------- Grid ------------------ */

const CollectionPointsGrid = ({
  data,
  dataCount,
  initPageIndex,
  onPageChange,
  handleExport,
}) => {
  //console.log(props.dataX);
  const columns = useMemo(
    () => [
      { Header: "Σταθμός", accessor: "rsName" },
      { Header: "Συλλέκτης", accessor: "collectorName" },
      { Header: "Αρ.Ζύγισης", accessor: "delivId" },
      { Header: "Όχημα", accessor: "vehPlateNo" },
      { Header: "Οδηγός", accessor: "driverName" },
      { Header: "Ημ/νία Μικτού", accessor: "grossDate", Cell: CellDateTime },
      {
        Header: "Μικτό Βάρος (Kg)",
        accessor: "grossWeight",
        Cell: CellFloatCustom(6, 0),
      },
      { Header: "Ημ/νία Απόβαρου", accessor: "tareDate", Cell: CellDateTime },
      {
        Header: "Απόβαρο (Kg)",
        accessor: "tareWeight",
        Cell: CellFloatCustom(6, 0),
      },
      {
        Header: "Καθαρό Βάρος (Kg)",
        accessor: "netWeight",
        Cell: CellFloatCustom(6, 0),
      },
      {
        Header: "Θεωρητικό Βάρος Ελαστικών (Kg)",
        accessor: "tyreCalcWeight",
        Cell: CellFloatCustom(6, 0),
      },
      { Header: "Υλικό", accessor: "materialName" },
      { Header: "Παραστατικό", accessor: "docId" },
      { Header: "Τύπος Παραλαβής", accessor: "deliveryTypeName" },
      { Header: "Νομός Συλλογής", accessor: "salesAreaName" },
      { Header: "Αριθμός Δελτίου ΑΠΠ", accessor: "tabletECoNid" },
      {
        Header: "Ελαστικά Κατηγ. Α",
        accessor: "tyreType1Qty",
        Cell: CellIntCustom(5),
      },
      {
        Header: "Ελαστικά Κατηγ. Β",
        accessor: "tyreType2Qty",
        Cell: CellIntCustom(5),
      },
      {
        Header: "Ελαστικά Κατηγ. Γ",
        accessor: "tyreType3Qty",
        Cell: CellIntCustom(5),
      },
      {
        Header: "Ελαστικά Κατηγ. Δ1",
        accessor: "tyreType4Qty",
        Cell: CellIntCustom(5),
      },
      {
        Header: "Ελαστικά Κατηγ. Δ2",
        accessor: "tyreType5Qty",
        Cell: CellIntCustom(5),
      },
    ],
    []
  );
  return (
    <TablePaginated
      title={"Σημεία Συλλογής"}
      columns={columns}
      data={data}
      dataCount={dataCount}
      // initPageIndex={initPage}
      // onSortByChange={handleSortByChange}
      onExport={handleExport}
      onPageChange={onPageChange}
      initPageIndex={initPageIndex}
    />
  );
};

/* ----------- Filters ------------------ */
const CollectionPointsFilters = ({ onChange, initialValues }) => {
  function handleFiltersSubmit(values, { setSubmitting }) {
    setTimeout(() => {
      setSubmitting(false);
      console.log("values", values);
      onChange(values);
    }, 400);
  }
  console.log("filters-init ", initialValues);

  const filters = {
    orderBy: initialValues?.orderBy ?? "",
    searchText: initialValues?.searchText ?? "",
    grossStartDate: initialValues?.grossStartDate ?? null,
    grossEndDate: initialValues?.grossEndDate ?? null,
    tyreType4Only: initialValues?.tyreType4Only ?? "",
    salesAreaId: initialValues?.salesAreaId ?? "",
    recyclingStationId: initialValues?.recyclingStationId ?? "",
  };

  return (
    <FormFilterCard
      title="Κριτήρια Αναζήτησης"
      onSubmit={handleFiltersSubmit}
      initialValues={{
        orderBy: filters?.orderBy,
        searchText: filters?.searchText,
        tyreType4Only: filters?.tyreType4Only,
        grossStartDate: filters?.grossStartDate,
        grossEndDate: filters?.grossEndDate,
        salesAreaId: filters?.salesAreaId,
        recyclingStationId: filters?.recyclingStationId,
      }}
    >
      <Row className="mt-4">
        <FieldDateRanger
          as={FormCol}
          label="Ημ/νία Μικτού από"
          name="grossStartDate"
          name2="grossEndDate"
        />
        <FieldSelectRecyclingStation as={FormCol} />
        <FieldSelectSalesArea as={FormCol} />
        <FieldCheckbox
          label="Μόνο Ελαστικά Κατηγορίας Δ1"
          name="tyreType4Only"
        />
      </Row>
      <Row className="mt-4">
        <FieldInput
          as={FormCol}
          name="searchText"
          label="Αναζήτηση (Πινακίδα)"
          type="search"
        />
      </Row>
    </FormFilterCard>
  );
};
