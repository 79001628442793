import * as Yup from 'yup';
import { isDate as fnsIsDate, parseISO } from 'date-fns';
import { checkVAT, countries } from 'jsvat';

// messages
export const msgRequired = 'Το πεδίο δεν μπορεί να είναι κενό';

// strings
export const YupStringReq = Yup.string()
  .nullable()
  .matches(/(\S)/, msgRequired)
  .required(msgRequired);
export const YupString = Yup.string().nullable();

// numbers & indexes
export const YupNumericReq = Yup.number()
  .nullable()
  .required(msgRequired);
export const YupNumeric = Yup.number().nullable();

// boolean
export const YupBoolean = Yup.boolean();

//date
function isDate() {
  return this.test({
    name: 'isDate',
    exclusive: true,
    message: 'Test message',
    test: value => {
      console.log(JSON.stringify({ value, type: typeof value }));
      const result = fnsIsDate(parseISO(value));
      console.log('Validation:', result);
      return result;
    },
  });
}

Yup.addMethod(Yup.string, 'isDate', isDate);

export const YupDate = Yup.string().nullable();

export const YupDateReq = Yup.string()
  .nullable()
  .required(msgRequired);

export const YupWeight = Yup.number()
  .typeError('Στο πεδίο πρέπει να συμπληρωθεί αριθμός')
  .min(1, 'Ελάχιστη τιμή 1')
  .max(3500, 'Μέγιστη Τιμή 3500')
  .nullable();

// export const YupWeightReq = Yup.number()
//   .typeError('Στο πεδίο πρέπει να συμπληρωθεί αριθμός')
//   .min(50, 'Ελάχιστη τιμή 50')
//   .max(3500, 'Μέγιστη Τιμή 3500')
//   .required(msgRequired);

// export const YupPercentage = Yup.number()
//   .typeError('Στο πεδίο πρέπει να συμπληρωθεί αριθμός')
//   .min(0, 'Ελάχιστη τιμή 0')
//   .max(100, 'Μέγιστη Τιμή 100')
//   .required(msgRequired);

// export const YupNumericMinMax = (minValue: number, maxValue: number) =>
//   Yup.number()
//     .min(minValue, `Ελάχιστη τιμή ${minValue}`)
//     .max(maxValue, `Μέγιστη Τιμή ${maxValue}`)
//     .nullable();

// export const YupNumericMinMax = (minValue: number, maxValue: number) =>
// Yup.number().min(minValue, `Ελάχιστη τιμή ${minValue}`).max(maxValue, `Μέγιστη Τιμή ${maxValue}`).nullable();

// export const YupNumericReqMinMax = (minValue: number, maxValue: number) =>
//   Yup.number()
//     .typeError('Στο πεδίο πρέπει να συμπληρωθεί αριθμός')
//     .min(minValue, `Ελάχιστη τιμή ${minValue}`)
//     .max(maxValue, `Μέγιστη Τιμή ${maxValue}`)
//     .required(msgRequired);

// export const YupNumericReqMinMax = (minValue: number, maxValue: number) =>
//Yup.number().typeError('Στο πεδίο πρέπει να συμπληρωθεί αριθμός').min(minValue, `Ελάχιστη τιμή ${minValue}`).max(maxValue, `Μέγιστη Τιμή ${maxValue}`).required(msgRequired);

export const YupVat = Yup.string()
  .test('vat-test', 'Μη αποδεκτός Α.Φ.Μ.', value => (!!value ? checkVAT(`EL${value}`, countries).isValid : true))
  .nullable();

export const YupVatReq = Yup.string()
  .test('vat-test', 'Μη αποδεκτός Α.Φ.Μ.', value => (!!value ? checkVAT(`EL${value}`, countries).isValid : true))
  .required(msgRequired);
