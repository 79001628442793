import React, { ComponentProps } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaSave } from 'react-icons/fa';

type Props = React.PropsWithoutRef<ComponentProps<typeof Button>> & {
  loading?: boolean;
};

const ButtonSubmit = ({ loading = false, ...props }: Props) => { 
  return (
  <Button {...props} style={{ width: '160px' }} variant="success" type="submit" size={'sm'}>
    {loading ? <Spinner animation="border" size="sm" className="mb-1" /> : <FaSave className="mb-1" />}
    {'Αποθήκευση'}
  </Button>
  );
}


export default React.memo(ButtonSubmit);

