import { toast, ToastContent } from 'react-toastify';

export function toastSuccess(message: ToastContent) {
  toast(message, {
    type: toast.TYPE.SUCCESS,
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 4000,
    draggable: false,
    pauseOnFocusLoss: false,
  });
}

export function toastFailure(message: ToastContent) {
  toast(message, {
    type: toast.TYPE.ERROR,
    position: toast.POSITION.TOP_CENTER,
    autoClose: 6000,
    draggable: false,
    pauseOnFocusLoss: false,
    onClose: () => console.error(message),
  });
}

export function toastWarning(message: ToastContent) {
  toast(message, {
    type: toast.TYPE.WARNING,
    position: toast.POSITION.TOP_CENTER,
    autoClose: 4000,
    draggable: false,
    pauseOnFocusLoss: false,
    //onClose: () => console.error(message),
  });
}
