import React, { ComponentProps } from 'react';
import { Button } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

type Props = React.PropsWithoutRef<ComponentProps<typeof Button>> & {};

const ButtonSubmit = (props: Props) => {
  return (
  <Button {...props} style={{ width: '160px' }} variant="success" type="submit">
    <FaSearch className="mb-1" /> {"Αναζήτηση"}
  </Button>
);
}

export default React.memo(ButtonSubmit);
