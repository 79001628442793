import React from 'react';
import { toastFailure } from '../utils/toasts';


// Present error to user
// + Persist Error to DB


export function useHandleError(componentName: string) {

    function handleGraphqlError(location: string, error: any, userPrefix?: string): void {
        //console.log('PersistErrorLog', componentName, location, message);
        let msg: string = '';
        if ((error.graphQLErrors) && (error.graphQLErrors[0]?.message))
            msg = error.graphQLErrors[0]?.message
        else if (error.message)
            msg = error.message
        else
            msg = error
        //const msg = error.graphQLErrors[0]?.message ?? error.message


        let prefix = 'Παρουσιάστηκε κάποιο λάθος στον σέρβερ.';
        if (userPrefix)
            prefix = userPrefix
        else if (location.includes('add') || location.includes('update'))
            prefix = 'Παρουσιάστηκε κάποιο λάθος κατα την αποθήκευση.';
        else if (location.includes('delete'))
            prefix = 'Η διαγραφή της Εγγραφής απέτυχε!';

        toastFailure(<div>{prefix}< br />{msg}</div>)
       /*  logError(`${componentName}.${location}`, msg); */ // Αποθηκεύεται και στον Server
    }
    return { handleGraphqlError };
}
