import React, { FC, ComponentProps } from 'react';
import { Button } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';

type Props = ComponentProps<typeof Button> & {};

const ButtonDelete: FC<Props> = props => {
  return (
  <Button {...props} style={{ width: '160px' }} variant="danger" onClick={props.onClick}>
    <FaTrash className="mb-1" /> {"Διαγραφή"}
  </Button>
);
}

export default ButtonDelete;
