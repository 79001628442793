import React from 'react';
import { Card } from 'react-bootstrap';
import { ButtonBack, FlexRow } from './../index';

type Props = {
  title: string | React.ReactNode;
  idx?: number;
  onReturn?: () => void;
};

const FormHeader = ({ title, idx = null, onReturn = () => {} }: Props) => (
  <Card.Header>
    <FlexRow>
      <Card.Title className="m-0">
        {title}
        {!!idx && <span className="text-muted">{` (${idx})`}</span>}
      </Card.Title>
      <ButtonBack onClick={onReturn} />
    </FlexRow>
  </Card.Header>
);

export default React.memo(FormHeader);
