import { useApolloClient } from '@apollo/react-hooks';
import { useWhyDidYouUpdate } from 'hooks';
import React, { useCallback, useState } from 'react';
import { userLogin } from 'graphql/db';
import { rootStore } from 'store';
import LoginForm from './login-form';
import { useHistory, useLocation } from 'react-router-dom';
import { toastFailure } from 'utils/toasts';

const LoginPage = () => {
  /*-------------------- Body -----------------------------------------------*/
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const history = useHistory();
  const location = useLocation();
  //const { from } = location.state || { from: { pathname: '/' } };
  const state: any = location.state || { from: { pathname: '/' } };
  const from = state.from;
  /* const { handleGraphqlError } = useHandleError('LoginPage'); */

  console.log('[LoginPage] invoked from =', from);
  /*! -------------------- Handlers -------------------------------------------*/
  /**
   * * 1. ΠΑΤΗΘΗΚΕ ΤΟ SUBMIT -> ΕΚΤΕΛΩ ΤΟ QUERY (Callback)
   */
  const handleSubmit = useCallback(
    (values, actions) => {
      //console.log('Submit', values, actions);
      actions.setSubmitting(false);
      setLoading(true);
      userLogin(client, values.name, values.password)
        .then(res => {
          console.log('############', res);
          //Διάβασε το User Type Name
         // getUserTypeName(client, res.userTypeId).then(usertypename => {
            rootStore.appStore.login(values.name, res);
         //   rootStore.appStore.setType(usertypename);
            setLoading(false);
            history.replace(from);
         // });
        })
        .catch(err => {
          toastFailure( err ? err : 'Σφάλμα Εισόδου');
          setError(err);
          console.log(err)
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [client, from, history],
  );

  /*-------------------- Render ---------------------------------------------*/
  useWhyDidYouUpdate('[LoginPage]', {});

  return (
    <div className="d-flex align-items-center justify-content-center" style={{ height: '85vh' }}>
      <LoginForm onSubmit={handleSubmit} message={error} loading={loading} />
    </div>
  );
};

export default LoginPage;
LoginPage.whyDidYouRender = true;
