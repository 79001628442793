import React, { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import * as Lo from "lodash";
import { toastSuccess } from "utils/toasts";
import { useHandleError } from "services";
import { isNumeric } from "utils/helpers";

import { useRootStore } from "store";
import {
  FieldCheckBox,
  FieldDatepicker,
  FieldNumericInput,
  FormCard,
  FormCol,
  FormRow,
  YupBoolean,
  YupDate,
  YupDateReq,
  YupNumeric,
  YupNumericReq,
} from "components/common";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  gqlAddRsCapacityException,
  gqlDeleteRsCapacityException,
  gqlGetRsCapacityExceptionById,
  gqlUpdateRsCapacityException,
  RsExceptionFormInput,
} from "../graphql";
import { Col, Row } from "react-bootstrap";
import FieldSelectRecyclingStation from "pages/reports/rs-deliveries/fs-recycling-stations";
import promiseModalDialog from "components/common/dialogs/modal-dialog-promise";

export const RsExceptionFormPage = () => {
  const { handleGraphqlError } = useHandleError("RsExceptionFormPage");
  const { appStore } = useRootStore();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const idNum = isNumeric(id) ? parseInt(id) : -1;
  const isNew = idNum < 0;

  const formShape = {
    id: YupNumeric,
    RsId: YupNumericReq,
    ExceptionDate: YupDateReq,
    ExceptionDateTo: YupDate,
    IsActive: YupBoolean,
    CapacityWeight: YupNumericReq,
  };

  const validationSchema = Yup.object().shape(formShape);
  const pickProperties = (values) => Lo.pick(values, Lo.keys(formShape));
  type FormValuesType = Yup.InferType<typeof validationSchema>;

  const [addRecord] = useMutation(gqlAddRsCapacityException);
  const [updateRecord] = useMutation(gqlUpdateRsCapacityException);
  const [deleteRecord] = useMutation(gqlDeleteRsCapacityException);
  const [get, { data: rawData, error, refetch }] = useLazyQuery(
    gqlGetRsCapacityExceptionById,
    {
      fetchPolicy: "network-only",
    }
  );
  const data = rawData ? rawData[Object.keys(rawData)[0]] : undefined;

  /* fetch data */
  useEffect(() => {
    if (!isNew) get({ variables: { id: idNum } });
  }, [get, id, idNum, isNew]);

  /**
   * * ΑΠΟΘΗΚΕΥΣΗ - UPDATE/CREATE
   */
  const handleSubmit = (values: FormValuesType, actions) => {
    actions.setSubmitting(false);
    let mutValues = Lo.pick(values, Lo.keys(new RsExceptionFormInput()));
    // Αν είναι νέα εγγραφή τοτε γράφω όλα τα πεδία
    if (isNew) {
      console.log("[RsException] New:", mutValues);
      addRecord({ variables: { data: { ...mutValues } } })
        .then((d) => {
          toastSuccess(
            `Επιτυχής Δημιουργία Εγγραφής [${
              d.data[Object.keys(d.data)[0]]?.id
            }]`
          );
          history.goBack();
        })
        .catch((error) => {
          handleGraphqlError("mutation-add", error);
        });
    } else {
      // Αν είναι update εγγραφής τοτε γράφω μονο τα αλλαγμένα πεδία
      console.log("[RsException] Update:", mutValues);
      updateRecord({ variables: { data: { ...mutValues, id: idNum } } })
        .then((d) => {
          toastSuccess(
            `Επιτυχής Αποθήκευση Εγγραφής [${
              d.data[Object.keys(d.data)[0]].id
            }]`
          );
          refetch();
        })
        .catch((error) => {
          handleGraphqlError("mutation-update", error);
        });
    }
  };

  /**
   * *  DELETE
   */
  const handleDelete = useCallback(async () => {
    const res = await promiseModalDialog({
      title: "Διαγραφή Εγγραφής",
      text:
        "Η Εγγραφή πρόκειται να διαγραφεί. Είστε σίγουροι ότι θέλετε να συνεχίσετε;",
    });
    if (res) {
      deleteRecord({ variables: { id: idNum } })
        .then(() => {
          toastSuccess(`Επιτυχής Διαγραφή Παρατήρησης`);
          history.goBack();
        })
        .catch((error) => {
          handleGraphqlError("mutation-delete", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRecord, idNum]);

  /* **** Render **** */
  if (error) {
    handleGraphqlError("query-get", error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <FormCard
        title={isNew ? `Καινούργια Εξαίρεση` : `Εξαίρεση: ${data?.id}`}
        onSubmit={handleSubmit}
        onReturn={null}
        onDelete={handleDelete}
        permitDelete={true}
        initialValues={pickProperties(data)}
        validationSchema={validationSchema}
        enableReinitialize
        idx={idNum}
      >
        <FormFields isNew={isNew} />
      </FormCard>
    );
  }
};

const FormFields = ({ isNew }) => {
  //const { values, setFieldValue }: any = useFormikContext();
  return (
    <>
      <Row>
        <Col>
          <FieldSelectRecyclingStation
            where={JSON.stringify({ isNotActive: false })}
            name="RsId"
            label="Σταθμός Ανακύκλωσης"
            required={true}
            as={FormCol}
            //defaultValues={!!data ? data[0] : undefined}
          />
          <FieldNumericInput
            label={"Ποσότητα"}
            name="CapacityWeight"
            type="number"
            //placeholder={('- specify a value - ')}
            as={FormCol}
            required={true}
          />
          <FieldDatepicker
            as={FormCol}
            required
            label={"Ημερομηνία"}
            name="ExceptionDate"
          />
          <FieldDatepicker
            as={FormCol}
            label={"Ημερομηνία Μέχρι"}
            name="ExceptionDateTo"
          />
          {!isNew && (
            <FieldCheckBox
              as={FormCol}
              label={"Ενεργό"}
              name="IsActive"
              //placeholder={('- specify a value - ')}
            />
          )}
        </Col>
      </Row>
    </>
  );
};
