import React, { FC, ComponentProps } from 'react';
import { Button } from 'react-bootstrap';
import { FaSignOutAlt } from 'react-icons/fa';

type Props = ComponentProps<typeof Button> & {};

export const ButtonLogout: FC<Props> = props => {
   return (
  <Button
    {...props}
    // style={{ width: '160px' }}
    style={{ color: 'white' }}
    variant="link"
    onClick={props.onClick}
  >
    <FaSignOutAlt size="1rem" className="mb-1" /> {'Αποσύνδεση'}
  </Button>
);
}
