import { FieldLabel } from './../index';
import { format, parseISO } from 'date-fns';
import { isValid } from 'date-fns/esm';
import { FieldAttributes, useField, useFormikContext } from 'formik';
import React, { forwardRef } from 'react';
import { Button, Form, FormControl, InputGroup, OverlayTrigger } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FaRegCalendarAlt } from 'react-icons/fa';
import FieldTooltip from './field-tooltip';

//@ts-ignore
//
//-------------------- Custom Input -------------------------------------------
//
const InputWithButton = forwardRef(({ required, touched, error, disabled, onClick, ...rest }, ref) => (
  <InputGroup style={{ flex: 1 }}>
    <FormControl
      style={required && !disabled ? { backgroundColor: 'lightYellow' } : {}}
      {...rest}
      onClick={() => false}
      isInvalid={touched && !!error}
      disabled={disabled}
      // onFocus={() => false}
      // onChange={() => false }
    />
    <InputGroup.Append>
      <Button
        variant="primary"
        className="react-datepicker-ignore-onclickoutside btn-dark"
        style={{
          paddingTop: '0.1rem',
          paddingBottom: '0.1rem',
          paddingLeft: '0.2rem',
          paddingRight: '0.2rem',
          borderTopRightRadius: '0.3rem',
          borderBottomRightRadius: '0.3rem',
          backgroundColor: '#aeaeae',
          borderColor: '#ced4da',
        }}
        // @ts-ignore
        disabled={disabled}
        // @ts-ignore
        onClick={onClick}
      >
        <FaRegCalendarAlt size="1rem" style={{ marginBottom: 1 }} />
      </Button>
    </InputGroup.Append>
    {/*
 // @ts-ignore   */}
    <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
  </InputGroup>
));
//---------------------------------------------------------------------------
type Props = {
  as?: React.ElementType<any>;
  label: string;
  placeholder?: string;
  dateFormat?: string;
  disabled?: boolean;
  required?: boolean;
  maxDate?: Date;
  minDate?: Date;
} & FieldAttributes<any>;

const FieldDatePicker = ({
  as = 'div',
  name,
  label,
  placeholder = '',
  dateFormat = 'dd/MM/yyyy',
  disabled = false,
  required = false,
  maxDate,
  minDate,
}: Props) => {
  //
  //-------------------- Body -----------------------------------------------
  //
  const [{ value }, { error, initialValue, touched }] = useField<Date>(name);
  const { setFieldValue, setFieldTouched } = useFormikContext<any>();
  // const [actualValue, setActualValue] = useState<Date>(typeof value === 'string' ? parseISO(value) : value);

  // useEffect(() => {
  //   console.log('Value changed:', value, value !== actualValue);
  //   if (value !== actualValue) {
  //     setActualValue(typeof value === 'string' ? parseISO(value) : value);
  //   }
  // }, [value]); // value  only

  //
  //-------------------- Handlers -------------------------------------------
  //
  const setFieldValueAndTouched = async (_value: Date | null, touched: boolean) => {
    if (isValid(_value)) {
      await setFieldValue(name, _value, true); //field,value,shouldValidate
    } else {
      await setFieldValue(name, null, true); //field,value,shouldValidate
    }
    await setFieldTouched(name, touched, false); //field,touched,shouldValidate
  };

  const handleChange = (date: Date) => {
    // setActualValue(date);
    setFieldValueAndTouched(date, true);
  };

  const handleBlur = () => {
    setFieldValueAndTouched(value, true);
  };
  const changed = value !== initialValue;

  // useWhyDidYouUpdate('[FieldDatePicker]', { name, value, error, initialValue, touched, actualValue });
  //
  //-------------------- Render -------------------------------------------
  //
  return (
    <Form.Group as={as} className="mb-2" controlId={name}>
      <FieldLabel text={label} changed={changed} required={required} />
      <OverlayTrigger
        delay={{ show: 1000, hide: 400 }}
        overlay={FieldTooltip({
          text: name,
          value:
            typeof value === 'string'
              ? format(parseISO(value), 'dd/MM/yyyy')
              : !!value
              ? format(value, 'dd/MM/yyyy')
              : '',
        })}
      >
        <InputGroup className="mb-1">
          <DatePicker
            autoComplete="off"
            preventOpenOnFocus
            placeholderText={placeholder}
            dateFormat={dateFormat}
            selected={typeof value === 'string' ? parseISO(value) : value}
            //@ts-ignore
            customInput={
              //@ts-ignore
              <InputWithButton name={name} touched={touched} error={error} />
            }
            onChange={handleChange}
            onBlur={handleBlur}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            disabled={disabled}
            required={required}
            minDate={minDate}
            maxDate={maxDate}
            // required={props.required}
          />
        </InputGroup>
      </OverlayTrigger>
    </Form.Group>
  );
};

export default FieldDatePicker;
