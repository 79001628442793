import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from "react";
import { Card, Row } from "react-bootstrap";
import {
  FormCol,
  CellFloatCustom,
  CellIntCustom,
  CellPercent,
} from "components/common";
import LoadingOverlay from "react-loading-overlay";
import { useRootStore } from "store";
import { useLazyQuery } from "@apollo/react-hooks";
import { useLocation, useHistory } from "react-router-dom";
import { FormFilterCard } from "components/common/formik";
import TableSubSimple from "components/common/table-sub-simple";
import { FieldDateRanger } from "components/common/formik/field-dateranger";
import {
  gqlGetCollectedQuantity,
  gqlGetCollectedWeight,
  gqlGetDeliveredQuantity,
} from "../graphql";
import moment from "moment";
import { PdfRep } from "./pdf-weightDifferences";

interface FilterParams {
  fromDate: Date;
  tillDate: Date;
}
const initFilters: FilterParams = {
  fromDate: moment()
    .startOf("month")
    .toDate(),
  tillDate: moment()
    .endOf("month")
    .toDate(),
};

export const WeightDifferencesPage = () => {
  const location = useLocation<any>();
  const history = useHistory();
  const { appStore } = useRootStore();
  const [routeFilterValues] = useState(
    location?.state?.filterValues ?? initFilters
  );
  const [
    getCollWeight,
    { data: rawDataCollWeight, loading: loadingCollWeight },
  ] = useLazyQuery(gqlGetCollectedWeight, { fetchPolicy: "network-only" });
  const [
    getCollected,
    { data: rawDataCollected, loading: loadingCollected },
  ] = useLazyQuery(gqlGetCollectedQuantity, { fetchPolicy: "network-only" });
  const [
    getDelivered,
    { data: rawDataDelivered, loading: loadingDelivered },
  ] = useLazyQuery(gqlGetDeliveredQuantity, { fetchPolicy: "network-only" });

  const getCollWeightData = (data) => {
    const updatedData = [];
    updatedData.push({
      name: "Συλλογές με θεωρητικό βάρος <=2tns",
      qty: data.ColQtyLT2tn,
      weight: data.ColSumLT2tn,
    });
    updatedData.push({
      name: "Συλλογές με θεωρητικό βάρος >2tns",
      qty: data.ColQtyMT2tn,
      weight: data.ColSumMT2tn,
    });
    return updatedData;
  };

  const getQuantityData = (dataCollected, dataDelivered) => {
    const dataQuantity = [];
    dataQuantity.push({
      name: "Ελαστικά Κατηγορίας Α",
      collected: dataCollected.TyreType1_ColQty,
      delivered: dataDelivered.TyreType1_ColQty,
      difference:
        dataDelivered.TyreType1_ColQty - dataCollected.TyreType1_ColQty,
      unit: "Τεμάχια",
    });
    dataQuantity.push({
      name: "Ελαστικά Κατηγορίας Β",
      collected: dataCollected.TyreType2_ColQty,
      delivered: dataDelivered.TyreType2_ColQty,
      difference:
        dataDelivered.TyreType2_ColQty - dataCollected.TyreType2_ColQty,
      unit: "Τεμάχια",
    });
    dataQuantity.push({
      name: "Ελαστικά Κατηγορίας Γ",
      collected: dataCollected.TyreType3_ColQty,
      delivered: dataDelivered.TyreType3_ColQty,
      difference:
        dataDelivered.TyreType3_ColQty - dataCollected.TyreType3_ColQty,
      unit: "Τεμάχια",
    });
    dataQuantity.push({
      name: "Ελαστικά Κατηγορίας Δ1",
      collected: dataCollected.TyreType4_ColQty,
      delivered: dataDelivered.TyreType4_ColQty,
      difference:
        dataDelivered.TyreType4_ColQty - dataCollected.TyreType4_ColQty,
      unit: "Τεμάχια",
    });
    dataQuantity.push({
      name: "Ελαστικά Κατηγορίας Δ2",
      collected: dataCollected.TyreType5_ColQty,
      delivered: dataDelivered.TyreType5_ColQty,
      difference:
        dataDelivered.TyreType5_ColQty - dataCollected.TyreType5_ColQty,
      unit: "Τεμάχια",
    });
    dataQuantity.push({
      name: "Θεωρητικό Βάρος Ελαστικών",
      collected: dataCollected.TyresWeight,
      delivered: dataDelivered.TyresWeight,
      difference: dataDelivered.TyresWeight - dataCollected.TyresWeight,
      unit: "Κιλά",
    });
    return dataQuantity;
  };

  const getWeightData = (dataCollected, dataDelivered) => {
    const dataWeight = [];
    dataWeight.push({
      name: "Θεωρητικό Βάρος Συλλεχθέντων (α)",
      weight: dataCollected.TyresWeight,
      percent: "",
      unit: "Κιλά",
    });
    dataWeight.push({
      name: "Θεωρητικό Βάρος Παραδιδόμενων (β)",
      weight: dataDelivered.TyresWeight,
      percent: "",
      unit: "Κιλά",
    });
    dataWeight.push({
      name: "Πραγματικό Βάρος Ζυγίσεων (γ)",
      weight: dataDelivered.NetWeight,
      percent: "",
      unit: "Κιλά",
    });
    dataWeight.push({
      name: "Διαφορά (α) - (β)",
      weight: dataCollected.TyresWeight - dataDelivered.TyresWeight,
      percent:
        dataCollected.TyresWeight > dataDelivered.TyresWeight
          ? ((dataCollected.TyresWeight - dataDelivered.TyresWeight) * 100) /
            dataCollected.TyresWeight
          : ((dataDelivered.TyresWeight - dataCollected.TyresWeight) * 100) /
            dataDelivered.TyresWeight,
      unit: "Κιλά",
    });
    dataWeight.push({
      name: "Διαφορά (α) - (γ)",
      weight: dataCollected.TyresWeight - dataDelivered.NetWeight,
      percent:
        dataCollected.TyresWeight > dataDelivered.NetWeight
          ? ((dataCollected.TyresWeight - dataDelivered.NetWeight) * 100) /
            dataCollected.TyresWeight
          : ((dataDelivered.NetWeight - dataCollected.TyresWeight) * 100) /
            dataDelivered.NetWeight,
      unit: "Κιλά",
    });
    dataWeight.push({
      name: "Διαφορά (β) - (γ)",
      weight: dataDelivered.TyresWeight - dataDelivered.NetWeight,
      percent:
        dataDelivered.TyresWeight > dataDelivered.NetWeight
          ? ((dataDelivered.TyresWeight - dataDelivered.NetWeight) * 100) /
            dataDelivered.TyresWeight
          : ((dataDelivered.NetWeight - dataDelivered.TyresWeight) * 100) /
            dataDelivered.NetWeight,
      unit: "Κιλά",
    });
    return dataWeight;
  };

  const dataCollWeight = rawDataCollWeight
    ? getCollWeightData(rawDataCollWeight.getCollectedWeight[0])
    : undefined;

  const dataQuantity =
    rawDataCollected && rawDataDelivered
      ? getQuantityData(
          rawDataCollected.getCollectedQuantity[0],
          rawDataDelivered.getDeliveredQuantity[0]
        )
      : undefined;

  const dataWeight =
    rawDataCollected && rawDataDelivered
      ? getWeightData(
          rawDataCollected.getCollectedQuantity[0],
          rawDataDelivered.getDeliveredQuantity[0]
        )
      : undefined;

  const fetchData = useCallback(
    async (params: FilterParams) => {
      let fromDate = params?.fromDate ?? initFilters.fromDate;
      let tillDate = params?.tillDate ?? initFilters.tillDate;

      getCollWeight({
        variables: {
          CollectorId: appStore.userLogin.collectorId,
          fromDate: fromDate,
          tillDate: tillDate,
        },
      });
      getCollected({
        variables: {
          CollectorId: appStore.userLogin.collectorId,
          fromDate: fromDate,
          tillDate: tillDate,
        },
      });
      getDelivered({
        variables: {
          CollectorId: appStore.userLogin.collectorId,
          fromDate: fromDate,
          tillDate: tillDate,
        },
      });
    },
    [appStore.userLogin.collectorId, getCollWeight, getCollected, getDelivered]
  );

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      const filterParams = location?.state?.filterValues ?? {};
      fetchData(filterParams as FilterParams);
    }
  });

  const filtersChanged = useCallback(
    (values) => {
      //fetchData(values.collectorId);
      // Ενημέρωσε το State του Route => triggers page refresh
      history.replace(location.pathname, { filterValues: values });
    },
    [history, location.pathname]
  );

  const columnsCollWeight = useMemo(
    () => [
      {
        Header: "",
        accessor: "name",
      },
      {
        Header: "Άθροισμα Συλλογών",
        accessor: "qty",
        Cell: CellIntCustom(10),
      },
      {
        Header: "Άθροισμα Θεωρητικού Βάρους Συλλογών",
        accessor: "weight",
        Cell: CellFloatCustom(10, 1),
      },
    ],
    []
  );

  const columnsQuantity = useMemo(
    () => [
      {
        Header: "",
        accessor: "name",
      },
      {
        Header: "Συλλογές από Σημεία Συλλογής",
        accessor: "collected",
        Cell: CellFloatCustom(10, 1),
      },
      {
        Header: "Παραδόσεις σε Σταθμούς Ανακύκλωσης",
        accessor: "delivered",
        Cell: CellFloatCustom(10, 1),
      },
      {
        Header: "Διαφορά Παραδόσεων με Συλλογές",
        accessor: "difference",
        Cell: CellFloatCustom(10, 1),
      },
      {
        Header: "Μονάδες Μέτρησης",
        accessor: "unit",
      },
    ],
    []
  );

  const columnsWeight = useMemo(
    () => [
      {
        Header: "",
        accessor: "name",
      },
      {
        Header: "",
        accessor: "percent",
        Cell: CellPercent,
      },
      {
        Header: "",
        accessor: "weight",
        Cell: CellFloatCustom(10, 1),
      },

      {
        Header: "",
        accessor: "unit",
      },
    ],
    []
  );

  /* console.log(dataToRender); */
  return (
    <>
      <Card className="shadow">
        <Card.Header>
          <Card.Title className="m-0">
            Ανάλυση Συλλεχθέντων με Παραδιδόμενων Ελαστικών
          </Card.Title>
        </Card.Header>

        {/* Φίλτρα */}
        <Filters
          fetchData={fetchData}
          onChange={filtersChanged}
          initialValues={routeFilterValues}
        />
        {/* TableGrid */}
        <LoadingOverlay
          active={loadingCollected || loadingDelivered || loadingCollWeight}
          spinner
          text="Παρακαλώ περιμένετε..."
        >
          {!loadingCollected &&
          !loadingDelivered &&
          !loadingCollWeight &&
          dataQuantity &&
          dataWeight &&
          dataCollWeight ? (
            <div style={{ padding: "1rem" }}>
              <h5>Συλλογή ελαστικών – Θεωρητικό Βάρος</h5>
              <br />

              <TableSubSimple
                title=""
                columns={columnsCollWeight}
                data={dataCollWeight}
              />

              <br />
              <br />
              <h5>
                Συγκριτικός πίνακας Συλλεχθέντων Ελαστικών με Παραδιδόμενων
                Ελαστικών
              </h5>
              <br />

              <TableSubSimple
                title=""
                columns={columnsQuantity}
                data={dataQuantity}
              />

              <br />
              <br />
              <h5>Συγκριτικός πίνακας βαρών</h5>
              <br />
              <TableSubSimple
                title=""
                columns={columnsWeight}
                data={dataWeight}
              />

              <PdfRep
                fromDate={routeFilterValues.fromDate}
                tillDate={routeFilterValues.tillDate}
                dataQuantity={dataQuantity}
                dataWeight={dataWeight}
                dataCollections={dataCollWeight}
              ></PdfRep>
            </div>
          ) : (
            <div style={{ height: "200px" }}></div>
          )}
        </LoadingOverlay>
      </Card>
    </>
  );
};

/* ----------- Filters Component ------------------ */
const Filters = ({ fetchData, onChange, initialValues }) => {
  //
  const handleFiltersSubmit = useCallback(
    (values, { setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
        console.log("values", values);
        onChange(values);
      }, 400);
    },
    [onChange]
  );

  return (
    <FormFilterCard
      disableClear={true}
      title="Κριτήρια Αναζήτησης"
      onSubmit={handleFiltersSubmit}
      initialValues={initialValues}
    >
      <Row>
        <FieldDateRanger
          onSubmit={fetchData}
          as={FormCol}
          label="Από"
          name="fromDate"
          name2="tillDate"
        />
      </Row>
    </FormFilterCard>
  );
};
