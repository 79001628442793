import React, { FC } from 'react';
import { FormHeader, FormFooter } from './';
import { Card, Form } from 'react-bootstrap';
import { Formik, Form as FForm, FormikConfig } from 'formik';

type Props<T = { [key: string]: any }> = FormikConfig<T> & {
  isNew?: boolean;
  title: any;
  idx?: number;
  border?: 'light' | 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark';
  permitDelete?: boolean;
  permitSubmit?: boolean;
  onReturn?: () => void;
  onDelete?: () => void;
  handleApproval?: () => void;
  handleRejection?: () => void;
  actions?: React.ReactNode;
  loading?: boolean;
  noButtons?: boolean;
  otherButtons?: boolean;
  alwaysOn?: boolean;
};

const FormCard: FC<Props> = ({
  isNew,
  title,
  idx = null,
  border = null,
  permitDelete = false,
  onReturn,
  onDelete = null,
  actions = <></>,
  loading = false,
  children = null,
  permitSubmit = false,
  noButtons = false,
  otherButtons = false,
  handleApproval = null,
  handleRejection = null,
  alwaysOn = false,
  ...rest
}) => {
  return (
    <Formik {...rest}>
      <Form as={FForm}>
        <Card className="shadow m-a" border={border ? border : null} bg="light">
          <FormHeader title={title} idx={idx} onReturn={onReturn} />
          <Card.Body>{children}</Card.Body>
          <FormFooter
            disabled={permitSubmit}
            isNew={isNew}
            permitDelete={permitDelete}
            onDelete={onDelete}
            loading={loading}
            noButtons={noButtons}
            otherButtons={otherButtons}
            handleApproval={handleApproval}
            handleRejection={handleRejection}
            alwaysOn={alwaysOn}
          >
            {actions}
          </FormFooter>
        </Card>
      </Form>
    </Formik>
  );
};

export default FormCard;
