import React, { FC, useState } from "react";
import { Form, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getRouteByName } from "./routes";
import { useRootStore } from "../store";
import { ButtonLogout } from "../components/common/buttons/button-logout";
import { NavLinkItem } from "../components/common";

type Props = {
  onLogoutClick?: () => void;
};

const Header: FC<Props> = ({ onLogoutClick }) => {
  /*-------------------- Body -----------------------------------------------*/
  const { appStore } = useRootStore();
  const [expanded, setExpanded] = useState(false);

  /*-------------------- Handlers -------------------------------------------*/

  const handleLogoutClick = () => {
    setExpanded(false);
    onLogoutClick();
  };

  const handleToggle = (state: boolean) => {
    setExpanded(state);
  };

  const handleSelect = () => {
    setExpanded(false);
  };

  const CollectorMenu = () => {
    return (
      <>
        <NavLinkItem
          text="Εκκρεμείς Αιτήσεις"
          to={getRouteByName("CollectionOrders")}
          enable={appStore.isLogIn}
        />

        {appStore.isLogIn && (
          <NavDropdown title="Δρομολόγια" id="reports-nav-dropdown">
            <NavLinkItem
              text="Επιβεβαίωση Δρομολογίων"
              to={getRouteByName("RoutesConfirmation")}
              enable={true}
            />
            <NavLinkItem
              text="Προγραμματισμένα Δρομολόγια"
              to={getRouteByName("PlannedRoutes")}
              enable={true}
            />
          </NavDropdown>
        )}

        <NavLinkItem
          text="Διαχείριση Συλλογών"
          to={getRouteByName("TyreCollections")}
          enable={appStore.isLogIn}
        />

        <NavLinkItem
          text="Σημεία Συλλογής"
          to={getRouteByName("CollectionPoints")}
          enable={appStore.isLogIn}
        />

        <NavLinkItem
          text="Παραδόσεις"
          to={getRouteByName("RsDeliveries")}
          enable={appStore.isLogIn}
        />

        <NavLinkItem
          text="Πλαφόν Σταθμών"
          to={getRouteByName("CollectorStations")}
          enable={appStore.isLogIn}
        />

        {appStore.isLogIn && (
          <NavDropdown title="Αναφορές" id="reports-nav-dropdown">
            <NavLinkItem
              text="Ανάλυση Μέσης Σταθμικής Απόκρισης Συλλογής"
              to={getRouteByName("CollectorResponse")}
              enable={true}
            />
            <NavLinkItem
              text="Ημέρες Εξυπηρέτησης Αιτήσεων"
              to={getRouteByName("ResponseDays")}
              enable={true}
            />
            <NavLinkItem
              text="Ανάλυση Παραλαβών Σταθμών Ανακύκλωσης"
              to={getRouteByName("StationsDeliveries")}
              enable={true}
            />
            <NavLinkItem
              text="Ανάλυση Συλλεχθέντων/Παραδιδόμενων"
              to={getRouteByName("WeightDifferences")}
              enable={true}
            />
          </NavDropdown>
        )}
        {/* <NavLinkItem
          text="Ημερολόγιο Παραδόσεων"
          to={getRouteByName("Calendar")}
          enable={appStore.isLogIn}
        /> */}
      </>
    );
  };

  /*-------------------- Render ---------------------------------------------*/
  return (
    <header>
      <Navbar
        expand="sm"
        bg="dark"
        variant="dark"
        fixed="top"
        onToggle={handleToggle}
        onSelect={handleSelect}
        expanded={expanded}
      >
        <Navbar.Brand as={Link} to={getRouteByName("Home")}>
          <img
            alt=""
            src={process.env.PUBLIC_URL + "/favicon.ico"}
            height={"34"}
            className="d-inline-block align-top mx-1"
            style={{ marginTop: "3px" }}
          />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {appStore.isLogIn && (
            <Nav className="mr-auto">
              {appStore.isCollector && <CollectorMenu />}
              {(appStore.isEA || appStore.isRecyclingStation) && (
                <>
                  <NavLinkItem
                    text="Ημερολόγιο Παραδόσεων"
                    to={getRouteByName("Calendar")}
                    enable={appStore.isLogIn}
                  />
                </>
              )}
              {appStore.isEA && (
                <>
                  <NavLinkItem
                    text="Διαχείριση Σταθμών"
                    to={getRouteByName("CapAndExp")}
                    enable={appStore.isLogIn}
                  />
                </>
              )}
            </Nav>
          )}

          {appStore.isLogIn && (
            <Form inline>{<ButtonLogout onClick={handleLogoutClick} />}</Form>
          )}
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
