import _findIndex from 'lodash/findIndex';
import _orderBy from 'lodash/orderBy';
import React, { FC, useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { LoadingIconProps } from 'react-select/src/components/indicators';
import { useEffectOnce } from 'react-use';

export interface OptionType {
  label: string;
  value: number;
  isActive: boolean;
  notes?: string;
  color?: string;
}

const LoadingIndicator = (props: LoadingIconProps<OptionType>) => {
  return <Spinner animation="border" size="sm" className="m-1" variant="primary" />;
};

// function Menu(props: MenuProps<OptionType>) {
//   return <Dropdown.Menu {...props.innerProps}>{props.children}</Dropdown.Menu>;
// }

// function SingleValue(props: SingleValueProps<OptionType>) {
//   return <div {...props.innerProps}>{props.children}</div>;
// }

const formatOptionLabel = ({ value, label, isActive, notes, color }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {isActive ? (
      <>
        <div style={{ color: !!color ? color : undefined }}>{label}</div>
        <small className="ml-3 text-muted">{notes}</small>
      </>
    ) : (
      <>
        <div style={{ color: !!color ? color : undefined, fontStyle: 'italic' }}>{label}</div>
        <small className="ml-3 text-muted">{notes}</small>
      </>
    )}
  </div>
);

//-------------------------------------------------------------------------------

type Props = {
  id?: string;
  className?: string;
  /** Lazy Query for records  id-> data*/
  hook: () => any;
  disabled?: boolean;
  required?: boolean;
  recordLabel?: string;
  recordLabel2?: string;
  recordLabel3?: string;
  where?: string;
  /** Default Input Box Value if selected is undefined */
  placeholder: string | undefined;
  isInvalid?: boolean;
  disableInactives?: boolean;
  /** value number  */
  value: number | null;
  label: string | null;
  minMenuHeight?: number;
  maxMenuHeight?: number;
  /** On change value */
  onChange?: (option: OptionType) => void;
  onBlur?: () => void;
};

const SelectDB: FC<Props> = ({
  id = 'sbdb',
  className,
  hook,
  disabled = false,
  required = false,
  recordLabel = 'description',
  recordLabel2 = 'description2',
  recordLabel3 = 'description3',
  where = '',
  placeholder,
  isInvalid = false,
  disableInactives = false,
  value,
  label,
  minMenuHeight = 140,
  maxMenuHeight = 300,
  onChange = null,
  onBlur = null,
}) => {
  //
  //-------------------- Body -----------------------------------------------
  //
  const [get, { data, loading }] = hook();
  const queryDone = useRef<boolean>(false);
  const initValue = useRef<number | null>(null);
  const initLabel = useRef<string | null>(null);
  const [selectValue, setSelectValue] = useState<OptionType>({
    value: value,
    label: label,
    isActive: true,
    notes: undefined,
    color: undefined,
  });

  const [options, setOptions] = useState<Array<OptionType>>([]);

  /**
   * * 1. Τη πρώτη φορά αν έχω value αλλά δεν έχω Label τοτε κάνω request
   */
  useEffectOnce(() => {
    initValue.current = value;
    initLabel.current = label;
    const opt = { value: value, label: label, isActive: true };
    setSelectValue(opt);
    //console.log(`[SelectDB]:${id} Value:${value} Label:${label} getting from ${recordLabel}`);
    if (!!value && !label) {
      //console.log(`[SelectDB]:${id} make DB request value:${value}`);
      doQuery();
    }
  });

  /**
   * * 2. Οταν ολοκληρωθεί το διάβασμα από τη βάση γεμίζει ο πίνακας
   * *    των επιλογών και ενημερώνω το initLabel αν υπάρχει initValue
   */
  useEffect(() => {
    if (!!data) {
      const propNames = Object.getOwnPropertyNames(data);
      const records = !!data[propNames[0]] && !!data[propNames[0]]['data'] ? data[propNames[0]]['data'] : [];

      const newOptions: OptionType[] = records.map(record => ({
        value: record.id,
        label: record[recordLabel] + (record[recordLabel2] ?  ' - ' + record[recordLabel2] : '') +  (record[recordLabel3] ?  ' - ' + record[recordLabel3] : '') ,
        isActive: record?.isActive ?? true,
        color: record?.color,
      }));
      setOptions(_orderBy(newOptions, ['isActive', 'label'], ['desc', 'asc']));
      queryDone.current = true;
      //console.log(`[SelectDB]:${id} new Options:`, newOptions);
      /**
       *  Τωρα που εχουμε τα options, αν υπάρχει αρχική τιμή βρίσκω το label
       */
      if (!!initValue.current && !initLabel.current && selectValue.value === initValue.current) {
        const idx = _findIndex(newOptions, ['value', initValue.current]);
        if (idx >= 0) {
          initLabel.current = newOptions[idx].label;
          setSelectValue({ ...newOptions[idx] });
        }
        return;
      }

      if (!!selectValue.value && !selectValue.label) {
        const idx = _findIndex(newOptions, ['value', selectValue.value]);
        if (idx >= 0) {
          const newValue = { ...newOptions[idx] };
          setSelectValue(newValue);
          !!onChange && onChange(newValue);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  /**
   * * 3. Αν υπάρχουν αλλαγές στο value από Props
   */
  useEffect(() => {
    if (value !== selectValue.value) {
      //console.log('[SelectDB] New value prop:', value);
      // Ο χρήστης έσβησε το πεδίο, η τιμή είναι null
      if (value === null) {
        const opt = { value: null, label: null, isActive: false };
        setSelectValue(opt);
        !!onChange && onChange(opt);
        return;
      }
      // Ο χρήστης Αρχικοποίησε το πεδίο, ολα γυρνούν στα initValues
      if (value === initValue.current) {
        const opt = { value: initValue.current, label: initLabel.current, isActive: true };
        setSelectValue(opt);
        !!onChange && onChange(opt);
        return;
      }
      // Αλλαξε η τιμή όμως δεν έχουμε τα options, θα πρέπει να δωθεί προσωρινά τιμή στο
      // selectValue μονο μέ το value και να εκτελεστεί το query για να έρθουν και τα labels
      // Στη φάση αυτή δεν εκτελώ το τελικό onChange, αφήνω το effect που θα αναλάβει το διάβασμα
      // των data να το κάνει.
      if (!queryDone.current) {
        const opt = { value: value, label: null, isActive: false };
        setSelectValue(opt);
        doQuery();
        return;
      }
      // Αλλαξε η τιμή και έχουμε τα options. Ψάχνω και αντιγράφω από τα options
      const idx = _findIndex(options, ['value', value]);
      if (idx >= 0) {
        const opt = {
          value: value,
          label: options[idx].label,
          isActive: options[idx].isActive,
          notes: options[idx].notes,
          color: options[idx].color,
        };
        setSelectValue(opt);
        !!onChange && onChange(opt);
        return;
      }
      // Δεν βρέθηκε η τιμή στα options. Προφανώς πρόκειται περί λάθους. Τα μηδενίζω όλα
      const opt = { value: null, label: null, isActive: false };
      setSelectValue(opt);
      !!onChange && onChange(opt);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  //
  //-------------------- Handlers -------------------------------------------
  //

  /**
   * * 1. To handler της αλλαγής τιμής από το select
   */
  const handleChange = useCallback(
    (selectedOption: OptionType, action) => {
      // console.log('SelectDB Changed:', selectedOption, action);
      if (action.action === 'clear') {
        const opt = {
          value: null,
          label: null,
          isActive: false,
        };
        // console.log('[SelectDB] onChange clear');
        setSelectValue(opt);
        !!onChange && onChange(opt);
        return;
      }
      if (selectedOption.value === initValue.current) {
        const opt = {
          value: initValue.current,
          label: initLabel.current,
          isActive: true,
        };
        // console.log(`[SelectDB]:${id} onChange value:${initValue.current}`);
        setSelectValue(opt);
        !!onChange && onChange(opt);
        return;
      } else {
        const opt = {
          value: selectedOption.value,
          label: selectedOption.label,
          isActive: selectedOption.isActive,
          notes: selectedOption.notes,
          color: selectedOption.color,
        };
        // console.log(`[SelectDB]:${id} onChange newValue:${selectedOption.value}`);
        setSelectValue(opt);
        !!onChange && onChange(opt);
        return;
      }
    },
    [onChange],
  );

  /**
   * * 2. To get του query
   */
  const doQuery = () => {
    if (where !== '') {
      get({ variables: { where } });
    } else {
      get();
    }
  };

  //
  //------------------ STYLING ----------------------------------
  //
  const styles = useMemo(
    () => ({
      container: (base, state) => ({
        ...base,
        flex: 1,
        //display: 'inline-block',
        // width: '250px',
        minHeight: '1px',
        height: '26px',
        marginBottom: '8px',
        //textAlign: 'left',
        // border: 'none',
      }),
      control: (base, state) => ({
        ...base,
        height: '22px',
        minHeight: '22px',
        backgroundColor: disabled ? '#e9ecef' : required ? 'lightYellow' : '#fff',
        borderWidth: '1px',
        boxShadow: !state.isFocused
          ? 'inset 0 1px 1px #00000013'
          : isInvalid
          ? '0 0 0 .2rem #dc354560'
          : '0 0 0 .2rem #007bff40',

        borderColor: isInvalid ? '#dc3545' : '#ced4da',
        '&:hover': {
          borderColor: isInvalid ? '#dc3545' : '#ced4da',
          //   borderColor: state.isFocused ? '#ced4da' : isInvalid ? '#dc3545' : '#ced4da',
        },
      }),
      input: (base, state) => ({
        ...base,
        minHeight: '1px',
        marginTop: '0px',
      }),
      indicatorsContainer: (base, state) => ({
        ...base,
        height: '22px',
        minHeight: '1px',
      }),
      dropdownIndicator: (base, state) => ({
        ...base,
        paddingTop: '0',
        paddingBottom: '0',
        minHeight: '1px',
        // color: '#757575',
      }),
      indicatorSeparator: (base, state) => ({
        ...base,
        minHeight: '1px',
        height: '20px',
        marginTop: '0px',
        marginBottom: '0px',
      }),
      clearIndicator: (base, state) => ({
        ...base,
        paddingTop: '0',
        paddingBottom: '0',
        minHeight: '1px',
      }),
      valueContainer: (base, state) => ({
        ...base,
        minHeight: '1px',
        height: '22px',
        paddingTop: '0',
        paddingBottom: '0',
      }),
      singleValue: (base, state) => ({
        ...base,
        color: '#495057',
        minHeight: '1px',
        paddingTop: '1',
        paddingBottom: '1',
      }),
      loadingIndicator: (base, state) => ({
        ...base,
        paddingTop: '1',
        paddingBottom: '1',
        minHeight: '1px',
        color: '#007bff80',
      }),
      noOptionsMessage: (base, state) => ({
        ...base,
        color: '#dc3545',
      }),
      menu: (base, state) => ({
        ...base,
        zIndex: 3,
        marginTop: '0px',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
      }),
      option: (base, state) => ({
        ...base,
        paddingTop: '4px',
        paddingBottom: '4px',
      }),
    }),
    [disabled, isInvalid, required],
  );

  //
  //-------------------- Render ----------------------------------------------
  //
  // useWhyDidYouUpdate(`[SelectDB]:${id} `, {
  //   className,
  //   hook,
  //   disabled,
  //   recordLabel,
  //   where,
  //   placeholder,
  //   isInvalid,
  //   value,
  //   label,
  //   minMenuHeight,
  //   maxMenuHeight,
  //   onChange,
  //   onBlur,
  //   get,
  //   data,
  //   loading,
  //   initValue: initValue.current,
  //   initLabel: initLabel.current,
  //   selectValue,
  //   options,
  // });

  return (
    <Select
      className={className}
      styles={styles}
      isDisabled={disabled}
      isSearchable={true}
      isClearable={true}
      placeholder={placeholder}
      onMenuOpen={doQuery}
      onFocus={doQuery}
      value={selectValue}
      onChange={handleChange}
      onBlur={onBlur}
      options={options}
      isLoading={loading}
      loadingMessage={() => null}
      noOptionsMessage={() => null}
      menuPlacement="auto"
      maxMenuHeight={maxMenuHeight}
      minMenuHeight={minMenuHeight}
      components={{ LoadingIndicator }}
      formatOptionLabel={formatOptionLabel}
      isOptionDisabled={option => option.isActive === false && disableInactives}
    />
  );
};

export default SelectDB;
