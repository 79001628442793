import React from 'react';
import { Card } from 'react-bootstrap';
import BootstrapSwitchButton from '../../common/bootstrap-switch-button/bootstrap-switch-button-react';
import { ButtonRefresh, FlexRow } from './../index';
import { ButtonNewRecord } from 'components/common/buttons/button-new-record';
type Props = {
  /** Τίτλος του grid */
  title: string | React.ReactNode;
  /** flag για το αν τα φιλτρα είναι ενεργά */
  filters?: boolean;
  /** το event του button Refresh */
  onRefresh?: () => void;
  /** το event του button Create */
  onCreate?: () => void;
  /** το event αλλαγής τιμής του filters */
  onFiltersChange?: (checked: boolean) => void;
};

const GridHeader = ({ title, filters = false, onRefresh = null, onCreate = null, onFiltersChange = null }: Props) => (
  //
  //-------------------------------- Render ----------------------
  //
  <Card.Header>
    <FlexRow>
      {!!onRefresh ? <ButtonRefresh onClick={onRefresh} /> : <div style={{ width: '160px' }} />}
      {!!onCreate ?  <Card.Title className="mb-0" style={{marginRight: '130px'}}>{title}</Card.Title> : <Card.Title className="mb-0">{title}</Card.Title>}
      

      <div>
        {!!onFiltersChange ? (
          // @ts-ignore
          <BootstrapSwitchButton
            width={100}
            checked={filters}
            onlabel="Φίλτρα"
            offlabel="Φίλτρα"
            onChange={onFiltersChange}
          />
        ) : (
          <div />
        )}
        {!!onCreate ?  <ButtonNewRecord onClick={onCreate} /> : <div style={{ width: '160px' }} />}
      </div>
    </FlexRow>
  </Card.Header>
);

export default React.memo(GridHeader);
