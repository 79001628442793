import React from "react";
import { FieldSelectDB } from "./../../../components/common/index";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import { FsProps } from "components/common/formik/FsProps";

const gqlGetRecyclingStations = gql`
  query getRecyclingStations(
    $where: String
    $offset: Int
    $limit: Int
    $order: String
  ) {
    getRecyclingStations(
      where: $where
      offset: $offset
      limit: $limit
      order: $order
    ) {
      data {
        id: ID
        description: name
      }
      recordCount
    }
  }
`;

function useGetRecyclingStationsLazyQuery() {
  return useLazyQuery(gqlGetRecyclingStations, {
    variables: { where: JSON.stringify({ isNotActive: false }) },
    fetchPolicy: "no-cache",
  });
  //return [null, { loading: null, data: null }];
}

const FieldSelectRecyclingStation = ({
  label = "Σταθμός Ανακύκλωσης",
  name = "recyclingStationId",
  defaultValues = {},
  ...rest
}: FsProps) => {
  return (
    <FieldSelectDB
      {...rest}
      label={label}
      name={name}
      // @ts-ignore
      sbLabel={defaultValues?.RecyclingStation?.name ?? undefined}
      hook={useGetRecyclingStationsLazyQuery}
    />
  );
};

export default FieldSelectRecyclingStation;
