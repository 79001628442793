import { GridFooter } from "../common";
import React, { useCallback, useEffect } from "react";
import { Table } from "react-bootstrap";
import {
  Cell,
  Column,
  Row,
  SortingRule,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useRootStore } from "../../store";

const defTableProps = {
  striped: true,
  hover: true,
  bordered: true,
  className: "mb-0",
};
const defHeaderGroupProps = () => ({ className: "text-center" });
const defHeaderProps = () => ({ className: "align-middle" });
const defFooterGroupProps = () => ({ className: "text-center" });

type Props<D extends object = {}> = {
  title?: string;
  columns: Array<Column<D>>;
  data: D[];
  dataCount: number;
  initPageIndex?: number;
  hasInsert?: boolean;
  tableProps?: object;
  bodyProps?: object;
  headerGroupProps?: (headerGroup) => object;
  footerGroupProps?: (footerGroup) => object;
  headerProps?: (column: Column<D>) => object;
  rowProps?: (row: Row<D>) => object;
  cellProps?: (cell: Cell<D>) => object;
  onSelectedRowsChange?: (rows: Array<Row<D>>) => void;
  onPageChange?: (pageIndex, pageSize: number) => void;
  onSortByChange?: (sort: SortingRule<D>[]) => void;
  onExport?: () => void;
  hasFooter?: boolean;
};

function TablePaginated<D extends object = {}>({
  title = "",
  columns,
  data,
  dataCount,
  initPageIndex = 0,
  hasInsert = false,
  onSelectedRowsChange = null,
  onPageChange = null,
  onSortByChange = null,
  onExport,
  tableProps = defTableProps,
  headerGroupProps = defHeaderGroupProps,
  headerProps = defHeaderProps,
  footerGroupProps = defFooterGroupProps,
  bodyProps = null,
  rowProps = null,
  cellProps = null,
  hasFooter = false,
}: Props<D>) {
  //
  //-------------------- Body -----------------------------------------------
  //
  const rootStore = useRootStore();
  const controlledPageCount =
    ~~((dataCount - 1) / rootStore.appStore.pageSize) + 1;
  /**
   * * useTable : Main Table Machine
   */
  const tableInstance = useTable<D>(
    {
      columns,
      data,
      initialState: {
        pageIndex: initPageIndex,
        pageSize: rootStore.appStore.pageSize,
      }, // Pass our hoisted table state
      manualPagination: true, // Enable Manual Pagination
      manualSortBy: true, // Enable Manual Sorting
      disableMultiSort: true, // Disable Miltiple Sorting
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;

  /**
   * * 2. Effect : ΕΚΤΕΛΕΙ ΤΟ gotoPage οταν Αλλάξει το initPageIndex
   */
  useEffect(() => {
    gotoPage(initPageIndex);
  }, [gotoPage, initPageIndex]);

  /**
   * * 3. Effect : ΕΚΤΕΛΕΙΤΑΙ όταν αλλάξει το selectedFlatRows
   */
  useEffect(() => {
    onSelectedRowsChange && onSelectedRowsChange(selectedFlatRows);
  }, [onSelectedRowsChange, selectedFlatRows]);

  /**
   * * 4. Effect : ΕΚΤΕΛΕΙΤΑΙ όταν αλλάξει το sortBy
   */
  useEffect(() => {
    onSortByChange && onSortByChange(sortBy);
  }, [onSortByChange, sortBy]);

  //
  //-------------------- Handlers -------------------------------------------
  //
  const handlePageChange = useCallback(
    (pageNo) => {
      if (onPageChange != null) {
        onPageChange(pageNo, pageSize);
      }
      gotoPage(pageNo);
    },
    [gotoPage, onPageChange, pageSize]
  );

  const handlePageSizeChange = useCallback(
    (n: number) => {
      rootStore.appStore.setPageSize(n);
      setPageSize(n);
      gotoPage(0);
      if (onPageChange != null) onPageChange(0, n);
    },
    [gotoPage, onPageChange, rootStore.appStore, setPageSize]
  );
  //
  //-------------------- Render ----------------------------------------------
  //
  // useWhyDidYouUpdate('[TablePaginated]', {
  //   columns,
  //   data,
  //   dataCount,
  //   get,
  //   initPageIndex,
  //   gotoPage,
  //   onPageChange,
  //   onSelectedRowsChange,
  //   tableProps,
  //   pageIndex,
  //   pageSize,
  //   setPageSize,
  //   selectedFlatRows,
  //   title,
  //   variables,
  //   where,
  // });
  return (
    <>
      <Table {...getTableProps(tableProps || {})}>
        <thead className="thead-dark">
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps(
                (headerGroupProps && headerGroupProps(headerGroup)) || {}
              )}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(
                    (headerProps && headerProps(column)) || {}
                  )}
                >
                  <span /* {...column.getSortByToggleProps()} */>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    {/* {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''} */}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps(bodyProps || {})}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps((rowProps && rowProps(row)) || {})}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps(
                        (cellProps && cellProps(cell)) || {}
                      )}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {hasFooter && (
          <tfoot style={{ backgroundColor: "#e1f3f7" }}>
            {footerGroups.map((footerGroup) => (
              <tr
                {...footerGroup.getFooterGroupProps(
                  (footerGroupProps && footerGroupProps(footerGroup)) || {}
                )}
              >
                {footerGroup.headers.map((column) => (
                  <td {...column.getFooterProps()}>
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </Table>
      <GridFooter
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        recordCount={dataCount}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onExport={onExport}
      />
    </>
  );
}

export default TablePaginated;
