import gql from 'graphql-tag';

export const gqlGetCstPoints = gql`
  query getCstPoints($where: String , $offset: Int, $limit: Int, $order: String ) {
    getCstPoints(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        CstPointId 
ErpID 
Name 
IsNotActive
Address 
Municipality 
Nomos 
District 
ZipCode 
VAT 
IRS 
Phone1 
Phone2 
Phone3 
Fax1
Fax2 
Email 
Contact 
CollectorId 
FirstDate
LastDate
FirstUser 
LastUser 
UserProfile_EMail 
AddressConfirmed
ActivityTypeId 
CstPointNFC 
CstPointTypeId 
CstPointGroupId 
YearStartDay 
YearStartMonth 
WeightChargeType 
TakeNewPhoto 
Photo_TabletECO_Nid 
Latitude 
Longitude 
GDPR_Consent 
WarplyMobile 
TyreType4_CollectorId 
      }
      recordCount
    }
  }
`;