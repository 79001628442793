import React, { useState } from 'react';
import { Form, Button, Card, InputGroup, Spinner } from 'react-bootstrap';
import { FaUser, FaLock, FaEye } from 'react-icons/fa';
import { Formik, Form as FForm } from 'formik';
import { string, object, InferType } from 'yup'; // for only what you need
import { useWhyDidYouUpdate } from '../hooks';
import { getRouteByName } from 'app/routes';
import { generatePath, useHistory } from 'react-router-dom';
/**
 * Setup Yup Validation
 */
const validationSchema = object().shape({
  name: string().required(),
  password: string().required(),
});

export type FormDataType = InferType<typeof validationSchema>;

type Props = {
  message?: string | null;
  loading?: boolean;
  onSubmit: (values, action) => void;
};

const LoginForm = ({ message = '', loading = false, onSubmit }: Props) => {
  /*-------------------- Body -----------------------------------------------*/
  const [show, setShow] = useState(false);
  const history = useHistory();

  const handleRouting = (route) => {
    history.push(
      generatePath(getRouteByName(route))
    );
  };

  useWhyDidYouUpdate('[LoginForm]', { message, onSubmit });
  /*-------------------- Render ---------------------------------------------*/
  return (
    <Formik initialValues={{ name: '', password: '' }} validationSchema={validationSchema} onSubmit={onSubmit}>
      {formikBag => (
        <Card className="bg-light shadow-sm p-3 mb-5" style={{ maxWidth: 640 }}>
         <Card.Img className="bg-light" variant="top" src="index_3D_Logo_GR.jpg" />
      <Card.Body>
        <Card.Title className="text-center mb-4 mt-1" as="h4">
        </Card.Title>
            <p className="text-danger text-center">{message && ''}</p>

            <Form as={FForm}>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FaUser />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    className="rounded-right"
                    id="name"
                    size={"sm"}
                    type="text"
                    placeholder="Email Χρήστη"
                    {...formikBag.getFieldProps('name')}
                    isInvalid={!!formikBag.touched.name && !!formikBag.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    Το email απαιτείται. Δεν μπορεί να είναι κενό
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FaLock />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    className="login-pw"
                    id="password"
                    type={show ? 'text' : 'password'}
                    placeholder="Κωδικός Πρόσβασης"
                    {...formikBag.getFieldProps('password')}
                    isInvalid={!!formikBag.touched.password && !!formikBag.errors.password}
                  />
                  <InputGroup.Append>
                    <Button className="rounded-right" onClick={() => setShow(value => !value)}>
                      <FaEye />
                    </Button>
                  </InputGroup.Append>
                  <Form.Control.Feedback type="invalid">
                    Το password απαιτείται. Δεν μπορεί να είναι κενό
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Button
                block
                variant="success"
                size={"sm"}
                style={{ fontSize: '0.8rem' }}
                type="submit"
                disabled={!(formikBag.isValid && formikBag.dirty)}
              >
                {loading ? <Spinner animation="border" size="sm" className="mx-2" /> : <span></span>}
                <span>{'Είσοδος'}</span>
              </Button>
            </Form>
          </Card.Body>
          <Card.Footer>
          <p className="text-center">
          <Button
            style={{ borderWidth: 0 }}
            variant="outline-success"
            size="sm"
            className="text-center"
            onClick={() => handleRouting('ForgotPw')}
          >
            Ξέχασα τον κωδικό πρόσβασης
          </Button>
        </p>

        <p className="text-center">
          <Button
            style={{ borderWidth: 0 }}
            variant="outline-success"
            size="sm"
            className="text-center"
            onClick={() => handleRouting('ChangePw')}
          >
            Αλλαγή κωδικού πρόσβασης
          </Button>
        </p>

        <p className="text-center">
          <Button
            style={{ borderWidth: 0 }}
            variant="outline-success"
            size="sm"
            className="text-center"
            onClick={() => handleRouting('NewUser')}
          >
            Νέα Εγγραφή Χρήστη
          </Button>
        </p>
          </Card.Footer>
        </Card>
      )}
    </Formik>
  );
};


export default  LoginForm;