import React, { useMemo, useCallback, useState } from 'react';
import { Card, Row} from 'react-bootstrap';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { gqlGetCstPoints } from './graphql';
import { TablePaginated, FlexRow, FormCol} from 'components/common';
import { useRootStore } from 'store';
import { useHistory } from 'react-router';
import { FormFilterCard } from 'components/common/formik';
import { FieldInput } from 'components/common/formik';
import LoadingOverlay from 'react-loading-overlay';
import { useLocation } from 'react-router-dom';
import { useHandleError } from 'services';
import exportXlsModal from 'components/common/export-xls-modal';
import { Field } from 'formik';
import CustomSelectComponent from 'components/custom-select';


/* ----------- Page ------------------ */
export const CollectionPointsPage = () => {
  const { appStore } = useRootStore();
  const { handleGraphqlError } = useHandleError('cstpoints');
  /* ----------------- Route State -------------------------- */
  const location = useLocation<any>();
  const history = useHistory();
  // Στο state του routing αποθηκεύω:
  //    1. την τρέχουσα σελίδα του table pagination (pageIndex)
  //  & 2. τα values των φίλτρων (filterValues)

  /* ------------------- Query ------------------------------- */
  const tablePageSize = appStore.pageSize;
  const [initPageIndex, setInitPageIndex] = useState(location?.state?.pageIndex ?? 0);

  const [queryParams, setQueryParams] = useState({
    offset: tablePageSize * initPageIndex,
    limit: tablePageSize,
    order: location?.state?.order ?? 'Name',
    where: buildWhereFromFilterValues(location?.state?.filterValues),
  });
  const { loading, data: rawData, error, refetch } = useQuery(gqlGetCstPoints, {
    variables: { ...queryParams },
    fetchPolicy: 'network-only',
  });
  const data = rawData?.getCstPoints?.data;
  const dataCount = rawData?.getCstPoints?.recordCount;

  /* ------------------- Διαχείριση Φίλτρων ------------------------------ */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function buildWhereFromFilterValues(values): string {
    // Φτιάξε το where για το graphql
    let where = {};
    if (values) {
      if (values.searchText) {
        const searchText = '%' + values.searchText + '%';
        const likeObj = { $like: searchText };
        where = {
          $or: [
            { '$Name$': likeObj },
            { '$Address$': likeObj },
            { '$ZipCode$': likeObj },
            { '$Phone1$': likeObj },
            { '$Phone2$': likeObj },
            { '$Phone3$': likeObj },
            { '$Nomos$': likeObj },
            { '$Municipality$': likeObj },
            { '$Fax1$': likeObj },
            { '$Fax2$': likeObj },
            { '$Email$': likeObj },
            { '$Contact$': likeObj },
            { '$VAT$': likeObj },
            { '$IRS$': likeObj },
            { '$Latitude$': likeObj },
            { '$Longitude$': likeObj },
          ],
        };
      }
    }
    where = { ...where, IsNotActive: false, CollectorId: appStore.userLogin.collectorId  };
    return JSON.stringify(where);
  }

  const filtersChanged = useCallback(
    values => {
      console.log('filtersChanged', values);
      // Φτιάξε το where για το graphql
      const where = buildWhereFromFilterValues(values);
      const order = values.orderBy !== '' ? values.orderBy : undefined;
      // Σύνθεσε τα vars του graphql
      const newQueryParams = {
        ...queryParams,
        offset: 0,
        where: where,
        order: order,
      };
      setQueryParams(newQueryParams); // αποθήκευσε τα vars
      refetch(newQueryParams); // φερε τα δεδομένα απο τον σερβερ
      setInitPageIndex(0); // αρχικοποίησε το gridTable στην 1η σελίδα

      // Ενημέρωσε το State του Route
      history.replace(location.pathname, {
        filterValues: values,
        order: order,
        pageIndex: 0,
      });
    },
    [buildWhereFromFilterValues, history, location.pathname, queryParams, refetch],
  );

  /* handle Pagination */
  const gridPageChanged = useCallback(
    (pageIndex, pageSize: number) => {
      console.log('gridPageChanged', pageIndex, pageSize);
      setInitPageIndex(pageIndex);
      const newQueryParams = { ...queryParams, offset: pageSize * pageIndex, limit: pageSize };
      setQueryParams(newQueryParams);
      refetch(newQueryParams);

      // Ενημέρωσε το State του Route
      const state = {
        ...location.state,
        pageIndex,
      };
      history.replace(location.pathname, state);
    },
    [history, location.pathname, location.state, queryParams, refetch],
  );


  function useGetCollectionpoints() {
    return useLazyQuery(gqlGetCstPoints);
    //return [null, { loading: null, data: null }];
  }

  const getTitle = () => {
    const search = location?.state?.filterValues?.searchText ? ' Αναζήτηση: ' + location?.state?.filterValues?.searchText  : '';
    // eslint-disable-next-line no-useless-concat
    const title = appStore.userLogin.collector + " - " + " Σημεία Συλλογής " + search;
    return title;
  }
  const handleExport = useCallback(async () => {
    await exportXlsModal({
      getLazyQuery: useGetCollectionpoints,
      filterVariables: queryParams,
      tableName: 'collectionPoints',
      title: getTitle(),
      columnsDef: [
        { fieldName: 'CstPointId', fieldTitle: 'Κωδικός', fieldType: 'INT' },
        { fieldName: 'Name', fieldTitle: 'Επωνυμία', fieldType: 'TEXT' },
        { fieldName: 'Address', fieldTitle: 'Διεύθυνση', fieldType: 'TEXT' },
        { fieldName: 'Municipality', fieldTitle: 'Δήμος', fieldType: 'TEXT' },
        { fieldName: 'ZipCode', fieldTitle: 'Τ.Κ.', fieldType: 'TEXT' },
        { fieldName: 'Nomos', fieldTitle: 'Νομός', fieldType: 'TEXT' },
        { fieldName: 'Latitude', fieldTitle: 'Γεωγραφικό μήκος', fieldType: 'TEXT' },
        { fieldName: 'Longitude', fieldTitle: 'Γεωγραφικό πλάτος', fieldType: 'TEXT' },
        { fieldName: 'Phone1', fieldTitle: 'Τηλ.1', fieldType: 'TEXT' },
        { fieldName: 'Phone2', fieldTitle: 'Τηλ.2', fieldType: 'TEXT' },
        { fieldName: 'Phone3', fieldTitle: 'Τηλ.3', fieldType: 'TEXT' },
        { fieldName: 'Fax1', fieldTitle: 'Fax1', fieldType: 'TEXT' },
        { fieldName: 'Fax2', fieldTitle: 'Fax2', fieldType: 'TEXT' },
        { fieldName: 'Email', fieldTitle: 'Email', fieldType: 'TEXT' },
        { fieldName: 'Contact', fieldTitle: 'Επικοινωνία', fieldType: 'TEXT' },
        { fieldName: 'VAT', fieldTitle: 'ΑΦΜ', fieldType: 'TEXT' },
        { fieldName: 'IRS', fieldTitle: 'ΔΟΥ', fieldType: 'TEXT' },
      ],
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  //console.log('loading', loading);
  //console.log('rawData', rawData);
  if (error) {
    handleGraphqlError('query-get', error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <Card className="shadow overflowscroll">
        <Card.Header>
          <FlexRow>
            <Card.Title className="m-0">Σημεία Συλλογής Ελαστικών</Card.Title>
          </FlexRow>
        </Card.Header>
        <Card.Body className="nopadding">
          {/* Φίλτρα */}
          <CollectionPointsFilters onChange={filtersChanged} initialValues={location?.state?.filterValues ?? {}} />

          {/* TableGrid */}
          <LoadingOverlay active={loading} spinner text="Παρακαλώ περιμένετε...">
            {rawData ? (
              <CollectionPointsGrid
                data={data}
                dataCount={dataCount}
                initPageIndex={initPageIndex}
                onPageChange={gridPageChanged}
                handleExport={handleExport}
              />
            ) : (
              <div style={{ height: '200px' }}>{}</div>
            )}
          </LoadingOverlay>
        </Card.Body>
      </Card>
    );
  }
};

/* ----------- Grid ------------------ */

const CollectionPointsGrid = ({ data, dataCount, initPageIndex, onPageChange, handleExport }) => {
  //console.log(props.dataX);
  const columns = useMemo(
    () => [
      {
        /* ΚΟΛΩΝΑ ΜΕ RedEye και Click handler */
        id: 'select',
        Header: 'Κωδικός',
        Cell: ({ row }) => (
          <div
            className="text-center"
          >
          {row.original.CstPointId}
          </div>
        ),
      },
      // { Header: 'Id', accessor: 'id' },
      { Header: 'Επωνυμία', accessor: 'Name' },
      {
        Header: 'Διεύθυνση',
        accessor: 'Address',
        Cell: ({ row }) => {
          return (
            <div className="text-left">
              {row.original.Address? (
                <div>{row.original.Address}</div>
              ) : null}
              {row.original.ZipCode? <div>{row.original.ZipCode}</div> : null}
              {row.original.Municipality ? <div>{row.original.Municipality}</div> : null}
              {row.original.Nomos ? <div>{row.original.Nomos}</div> : null}
            </div>
          );
        },
      },
      {
        Header: 'Γεωγραφικό μήκος',
        accessor: 'Latitude',
      },
      {
        Header: 'Γεωγραφικό πλάτος',
        accessor: 'Longitude',
      },
      {
        Header: 'Τηλ.1',
        accessor: 'Phone1',
      },
      {
        Header: 'Τηλ.2',
        accessor: 'Phone2',
      },
      {
        Header: 'Τηλ.3',
        accessor: 'Phone3',
      },
      {
        Header: 'Fax1',
        accessor: 'Fax1',
      },
      {
        Header: 'Fax2',
        accessor: 'Fax2',
      },
      {
        Header: 'Email',
        accessor: 'Email',
      },
      {
        Header: 'Επικοινωνία',
        accessor: 'Contact',
      },
      {
        Header: 'ΑΦΜ',
        accessor: 'VAT',
      },
      {
        Header: 'ΔΟΥ',
        accessor: 'IRS',
      },
    ],
    [],
  );
  return (
    <TablePaginated
      title={'Σημεία Συλλογής'}
      columns={columns}
      data={data}
      dataCount={dataCount}
      // initPageIndex={initPage}
      // onSortByChange={handleSortByChange}
      onExport={handleExport}
      onPageChange={onPageChange}
      initPageIndex={initPageIndex}
    />
  );
};

/* ----------- Filters ------------------ */
const CollectionPointsFilters = ({ onChange, initialValues }) => {
  function handleFiltersSubmit(values, { setSubmitting }) {
    setTimeout(() => {
      setSubmitting(false);
      console.log('values', values);
      onChange(values);
    }, 400);
  }
  console.log('filters-init ', initialValues);

  const options = [
    { value: 'Name DESC', label: 'Επωνυμία (Φθίνουσα)' },
    { value: 'Name ASC', label: 'Επωνυμία (Αύξουσα)' },
    { value: 'Nomos DESC', label: 'Νομό (Φθίνουσα)' },
    { value: 'Nomos ASC', label: 'Νομό (Αύξουσα)' },
    { value: 'Municipality DESC', label: 'Δήμο (Φθίνουσα)' },
    { value: 'Municipality ASC', label: 'Δήμο (Αύξουσα)' },
    { value: 'ZipCode DESC', label: 'T.K. (Φθίνουσα)' },
    { value: 'ZipCode ASC', label: 'T.K. (Αύξουσα)' },
  ];

  const filters = {
    orderBy: initialValues?.orderBy ?? '',
    searchText: initialValues?.searchText ?? '',
  };

  return (
    <FormFilterCard
      title="Κριτήρια Αναζήτησης"
      onSubmit={handleFiltersSubmit}
      initialValues={{
        orderBy: filters?.orderBy,
        searchText: filters?.searchText,
      }}
    >
      <Row>
        <FieldInput as={FormCol} name="searchText" label="Αναζήτηση" type="search" />
        <Field name={'orderBy'} title="Ταξινόμηση ανά" component={CustomSelectComponent} options={options} />
      </Row>
    </FormFilterCard>
  );
};
