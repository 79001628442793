import React, { ComponentProps } from 'react';
import { Button } from 'react-bootstrap';
import { FaFileExcel } from 'react-icons/fa';

type Props = React.PropsWithoutRef<ComponentProps<typeof Button>> & {};

const ButtonExport = (props: Props) => {
  return (
  <Button {...props} style={{ width: '160px' }} variant="warning">
    <FaFileExcel className="mb-1" /> {"Εξαγωγή σε Excel"}
  </Button>
  );
}

export default React.memo(ButtonExport);
