import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import * as Lo from "lodash";
import { toastSuccess } from "utils/toasts";
import { useHandleError } from "services";
import { isNumeric } from "utils/helpers";

import { useRootStore } from "store";
import {
  FieldInput,
  FieldNumericInput,
  FormCard,
  FormCol,
  YupNumeric,
  YupNumericReq,
  YupString,
  YupWeight,
} from "components/common";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  gqlGetRsCapacityById,
  gqlUpdateRsCapacity,
  RsCapacityFormInput,
} from "../graphql";
import { Col, Row } from "react-bootstrap";
import { SketchPicker } from "react-color";
import { useFormikContext } from "formik";

export const RsCapacityFormPage = () => {
  const { handleGraphqlError } = useHandleError("RsCapacityFormPage");
  const { appStore } = useRootStore();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const idNum = isNumeric(id) ? parseInt(id) : -1;

  const formShape = {
    id: YupNumeric,
    RsId: YupNumericReq,
    RsColor: YupString,
    Day1: YupNumericReq,
    Day2: YupNumericReq,
    Day3: YupNumericReq,
    Day4: YupNumericReq,
    Day5: YupNumericReq,
    Day6: YupNumericReq,
    Day7: YupNumericReq,
  };

  const validationSchema = Yup.object().shape(formShape);
  const pickProperties = (values) => Lo.pick(values, Lo.keys(formShape));
  type FormValuesType = Yup.InferType<typeof validationSchema>;

  const [updateRecord] = useMutation(gqlUpdateRsCapacity);
  const [get, { data: rawData, error, refetch }] = useLazyQuery(
    gqlGetRsCapacityById,
    {
      fetchPolicy: "network-only",
    }
  );
  const data = rawData ? rawData[Object.keys(rawData)[0]] : undefined;

  /* fetch data */
  useEffect(() => {
    get({ variables: { id: idNum } });
  }, [get, id, idNum]);

  /**
   * * ΑΠΟΘΗΚΕΥΣΗ - UPDATE/CREATE
   */
  const handleSubmit = (values: FormValuesType, actions) => {
    actions.setSubmitting(false);
    let mutValues = Lo.pick(values, Lo.keys(new RsCapacityFormInput()));
    // Αν είναι update εγγραφής τοτε γράφω μονο τα αλλαγμένα πεδία
    console.log("[RsCapacity] Update:", mutValues);
    updateRecord({ variables: { data: { ...mutValues, id: idNum } } })
      .then((d) => {
        toastSuccess(
          `Επιτυχής Αποθήκευση [${d.data[Object.keys(d.data)[0]].id}]`
        );
        refetch();
      })
      .catch((error) => {
        handleGraphqlError("mutation-update", error);
      });
  };

  /* **** Render **** */
  if (error) {
    handleGraphqlError("query-get", error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <FormCard
        title={`Δυναμικότητα: ${data?.RecyclingStation?.name}`}
        onSubmit={handleSubmit}
        onReturn={null}
        permitDelete={false}
        initialValues={pickProperties(data)}
        validationSchema={validationSchema}
        enableReinitialize
        idx={idNum}
      >
        <FormFields />
      </FormCard>
    );
  }
};

const FormFields = () => {
  const { values, setFieldValue }: any = useFormikContext();
  return (
    <>
      <Row>
        <Col>
          <FieldNumericInput
            as={FormCol}
            label={"Δευτέρα"}
            name="Day2"
            type="number"
            //placeholder={('- specify a value - ')}
            required={true}
          />
          <FieldNumericInput
            as={FormCol}
            label={"Τρίτη"}
            name="Day3"
            type="number"
            //placeholder={('- specify a value - ')}
            required={true}
          />
          <FieldNumericInput
            as={FormCol}
            label={"Τετάρτη"}
            name="Day4"
            type="number"
            //placeholder={('- specify a value - ')}
            required={true}
          />
          <FieldNumericInput
            as={FormCol}
            label={"Πέμπτη"}
            name="Day5"
            type="number"
            //placeholder={('- specify a value - ')}
            required={true}
          />
          <FieldNumericInput
            as={FormCol}
            label={"Παρασκευή"}
            name="Day6"
            type="number"
            //placeholder={('- specify a value - ')}
            required={true}
          />
          <FieldNumericInput
            as={FormCol}
            label={"Σάββατο"}
            name="Day7"
            type="number"
            //placeholder={('- specify a value - ')}
            required={true}
          />
          <FieldNumericInput
            as={FormCol}
            label={"Κυριακή"}
            name="Day1"
            type="number"
            //placeholder={('- specify a value - ')}
            required={true}
          />

          <FieldInput
            as={FormCol}
            label={"Χρώμα Εμφάνισης"}
            name="RsColor"
            type="text"
            placeholder={"#FFFFFF"}
            required={false}
          />
          <div style={{ paddingLeft: "7.5px" }}>
            <SketchPicker
              color={values?.RsColor ?? ""}
              onChangeComplete={(color) => setFieldValue("RsColor", color.hex)}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
