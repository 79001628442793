import { JwtToken } from "./jwt";
import { UserRole } from "./constants";

export function jwtToken(context: any): JwtToken {
  return context?.jwtToken ?? {};
}

export function userIsEcoElastika(context: any): Boolean {
  const jwt = jwtToken(context);
  return jwt.userRole === UserRole.EcoPointUser;
}

export function userIsCollector(context: any): Boolean {
  const jwt = jwtToken(context);
  return jwt.userRole === UserRole.Collector;
}

export function userIsRecyclingStation(context: any): Boolean {
  const jwt = jwtToken(context);
  return jwt.userRole === UserRole.RecyclingStation;
}

export function recyclingStationId(context: any): Number {
  const jwt = jwtToken(context);
  return jwt.recyclingStationId;
}
