import { pdf, View, Text, StyleSheet, Font } from "@react-pdf/renderer";
import React from "react";
import styled from "@react-pdf/styled-components";
import { parseISO, format } from "date-fns";
import _ from "lodash";

export async function pdfDocOpen(
  pdfFilename: string,
  pdfDoc: any
): Promise<void> {
  const blob = await pdf(pdfDoc).toBlob();

  //   saveAs(blob, pdfFilename);
  //   toastSuccess(`To PDF Δημιουργήθηκε`);

  const url = URL.createObjectURL(blob);
  window.open(url, "_blank");
  //win.print();
}

Font.register({
  family: "Lato",
  src: process.env.PUBLIC_URL + "/fonts/Lato-Regular.ttf",
});
Font.register({
  family: "Lato",
  fontStyle: "bold",
  src: process.env.PUBLIC_URL + "/fonts/Lato-Bold.ttf",
});

export const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    fontFamily: "Lato",
    fontSize: "1vh",
  },
  body: {
    padding: "10",
  },
  table: {
    display: "flex",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#0f0f0f",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderColor: "#0f0f0f",
    borderBottomColor: "#000",
    backgroundColor: "#5dd67a",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    borderStyle: "solid",
    borderColor: "#0f0f0f",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: "5",
    fontSize: 9,
    fontWeight: 800,
    textAlign: "center",
  },
  tableCell: {
    margin: "2",
    fontSize: 9,
    textAlign: "center",
  },
  tableCellLeft: {
    margin: "5",
    fontSize: 9,
    textAlign: "left",
  },
});

export const BoldText = styled.Text`
  font-style: bold;
`;

export const PdfTable = ({
  data,
  columns,
  headers = true,
  footer = false,
  fontSize = 9,
  headerFontSize = 9,
}) => (
  <View style={styles.table}>
    {headers && <PdfTableHeaders fontSize={headerFontSize} columns={columns} />}
    <PdfTableRows
      footer={footer}
      fontSize={fontSize}
      data={data}
      columns={columns}
    />
  </View>
);

const PdfTableHeaders = ({ columns, fontSize }) => (
  <View style={styles.tableRow}>
    {columns.map((column) => {
      const colWidth = column.width ? column.width : `${100 / columns.length}%`;
      return (
        <View
          style={
            (styles.tableColHeader = {
              ...styles.tableColHeader,
              width: colWidth,
            })
          }
        >
          <Text style={{ ...styles.tableCellHeader, fontSize: fontSize }}>
            {column.header}
          </Text>
        </View>
      );
    })}
  </View>
);

const PdfTableRows = ({ data, columns, fontSize, footer }) =>
  data.map((record, i) => {
    return (
      <>
        <View style={styles.tableRow}>
          {columns.map((column) => {
            const colWidth = column.width
              ? column.width
              : `${100 / columns.length}%`;

            return (
              <View
                style={
                  (styles.tableCol = {
                    ...styles.tableCol,
                    width: colWidth,
                  })
                }
              >
                {column.type === "int" ||
                (record.int && column.name === record.int) ? (
                  <PdfCellInt
                    fontSize={fontSize}
                    left={column.left}
                    value={_.get(record, column.name)}
                  />
                ) : column.type === "float" ? (
                  <PdfCellFloat
                    fontSize={fontSize}
                    left={column.left}
                    value={_.get(record, column.name)}
                  />
                ) : column.type === "status" ? (
                  <PdfCellStatus
                    fontSize={fontSize}
                    left={column.left}
                    value={_.get(record, column.name)}
                  />
                ) : column.type === "percent" ? (
                  <PdfCellPercent
                    fontSize={fontSize}
                    left={column.left}
                    value={_.get(record, column.name)}
                  />
                ) : column.type === "date" ||
                  (record.date && column.name === record.date) ? (
                  <PdfCellDate
                    fontSize={fontSize}
                    left={column.left}
                    value={_.get(record, column.name)}
                  />
                ) : (
                  <PdfCellString
                    fontSize={fontSize}
                    left={column.left}
                    wordWrap={column.wordWrap}
                    bold={record.bold && column.name === record.bold}
                    value={_.get(record, column.name)}
                    maxCharWidth={column.maxCharWidth}
                  />
                )}
              </View>
            );
          })}
        </View>
        {footer && i === data.length - 1 && (
          <View style={{ ...styles.tableRow, backgroundColor: "#e1f3f7" }}>
            {columns.map((column) => {
              const colWidth = column.width
                ? column.width
                : `${100 / columns.length}%`;

              return (
                <View
                  style={
                    (styles.tableCol = {
                      ...styles.tableCol,
                      width: colWidth,
                    })
                  }
                >
                  {column.type === "int" ||
                  (record.int && column.name === record.int) ? (
                    <PdfCellInt
                      fontSize={fontSize}
                      left={column.left}
                      value={_.sum(_.map(data, (d) => d[column.name]))}
                    />
                  ) : column.type === "float" ? (
                    <PdfCellFloat
                      fontSize={fontSize}
                      left={column.left}
                      value={_.sum(_.map(data, (d) => d[column.name]))}
                    />
                  ) : (
                    undefined
                  )}
                </View>
              );
            })}
          </View>
        )}
      </>
    );
  });

function wordwrap(str, width, brk, cut) {
  brk = brk || "n";
  width = width || 75;
  cut = cut || false;

  if (!str) {
    return str;
  }

  // eslint-disable-next-line no-useless-escape
  var regex =
    ".{1," + width + "}(s|$)" + (cut ? "|.{" + width + "}|.+$" : "|S+?(s|$)");

  return str.match(RegExp(regex, "g")).join(brk);
}

const PdfCellString = ({
  value,
  left,
  bold,
  fontSize,
  wordWrap,
  maxCharWidth = 10,
}) =>
  bold ? (
    <BoldText
      style={
        left
          ? { ...styles.tableCellLeft, fontSize: fontSize }
          : { ...styles.tableCell, fontSize: fontSize }
      }
    >
      {" "}
      {wordWrap ? wordwrap(value, 10, "\n", true) : value}
    </BoldText>
  ) : (
    <Text
      style={
        left
          ? { ...styles.tableCellLeft, fontSize: fontSize }
          : { ...styles.tableCell, fontSize: fontSize }
      }
    >
      {" "}
      {wordWrap ? wordwrap(value, maxCharWidth, "\n", true) : value}
    </Text>
  );

const PdfCellInt = ({ value, left, fontSize }) => {
  let txt = "0";
  if (typeof value === "string") {
    const i = parseInt(value, 10);
    if (isNaN(i)) txt = value;
    else txt = i.toLocaleString();
  } else if (typeof value === "number") {
    txt = value.toLocaleString();
  }
  // pad to 10 chars FIGURE SPACE = &numsp; U+2007
  // if (4 > txt.length) txt = '\u2007'.repeat(4 - txt.length) + txt;
  return (
    <Text
      style={
        left
          ? { ...styles.tableCellLeft, fontSize: fontSize }
          : { ...styles.tableCell, fontSize: fontSize }
      }
    >
      {txt}
    </Text>
  );
};

const PdfCellStatus = ({ value, left, fontSize }) =>
  value === 1 ? (
    <Text
      style={
        left
          ? { ...styles.tableCellLeft, fontSize: fontSize }
          : { ...styles.tableCell, fontSize: fontSize }
      }
    >
      {"Εχει Γίνει η Συλλογή"}
    </Text>
  ) : value === 2 ? (
    <Text
      style={
        left
          ? { ...styles.tableCellLeft, fontSize: fontSize }
          : { ...styles.tableCell, fontSize: fontSize }
      }
    >
      {"Εχει Ολοκληρωθεί η Συλλογή"}
    </Text>
  ) : (
    <Text
      style={
        left
          ? { ...styles.tableCellLeft, fontSize: fontSize }
          : { ...styles.tableCell, fontSize: fontSize }
      }
    >
      {"Εκρεμεί η Συλλογή"}
    </Text>
  );

const PdfCellFloat = ({ value, left, fontSize }) => {
  let txt = "-";
  if (typeof value === "string") {
    const i = parseFloat(value);
    if (isNaN(i)) txt = value;
    else {
      //txt = i.toLocaleString();
      txt = i.toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });
    }
  } else if (typeof value === "number") {
    txt = value.toLocaleString(undefined, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });
  }
  // pad to 10 chars FIGURE SPACE = &numsp; U+2007
  //console.log(value, numberWidth, txt.length);
  if (10 > txt.length) txt = "\u2007".repeat(10 - txt.length) + txt;
  return (
    <Text
      style={
        left
          ? { ...styles.tableCellLeft, fontSize: fontSize }
          : { ...styles.tableCell, fontSize: fontSize }
      }
    >
      {txt}
    </Text>
  );
};

const PdfCellPercent = ({ value, left, fontSize }) => {
  let txt = "-";
  // pad to 10 chars FIGURE SPACE = &numsp; U+2007
  if (value) {
    txt = parseFloat(value).toFixed(2);
    txt = "\u2007".repeat(6 - txt.length) + txt + " %";
  }
  return (
    <Text
      style={
        left
          ? { ...styles.tableCellLeft, fontSize: fontSize }
          : { ...styles.tableCell, fontSize: fontSize }
      }
    >
      {txt}
    </Text>
  );
};

const PdfCellDate = ({ value, left, fontSize }) =>
  value === null ? (
    <Text
      style={
        left
          ? { ...styles.tableCellLeft, fontSize: fontSize }
          : { ...styles.tableCell, fontSize: fontSize }
      }
    >
      {" "}
    </Text>
  ) : (
    <Text
      style={
        left
          ? { ...styles.tableCellLeft, fontSize: fontSize }
          : { ...styles.tableCell, fontSize: fontSize }
      }
    >
      {!!value ? format(parseISO(value), "dd/MM/yyyy") : ""}
    </Text>
  );
