import * as React from "react";
import { FieldProps } from "formik";
import Select from "react-select";
import { FormGroup } from "react-bootstrap";
import { FormCol, FieldLabel } from "components/common";

const styles = {
  container: (base, state) => ({
    ...base,
    flex: 1,
    //display: 'inline-block',
    // width: '250px',
    minHeight: "1px",
    height: "26px",
    marginBottom: "8px",
    //textAlign: 'left',
    // border: 'none',
  }),
  control: (base, state) => ({
    ...base,
    height: "22px",
    minHeight: "22px",
    backgroundColor: "#fff",
    borderWidth: "1px",
    boxShadow: !state.isFocused
      ? "inset 0 1px 1px #00000013"
      : "0 0 0 .2rem #007bff40",

    borderColor: "#ced4da",
    "&:hover": {
      borderColor: "#ced4da",
      //   borderColor: state.isFocused ? '#ced4da' : isInvalid ? '#dc3545' : '#ced4da',
    },
  }),
  input: (base, state) => ({
    ...base,
    minHeight: "1px",
    marginTop: "0px",
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    height: "22px",
    minHeight: "1px",
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    paddingTop: "0",
    paddingBottom: "0",
    minHeight: "1px",
    // color: '#757575',
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    minHeight: "1px",
    height: "20px",
    marginTop: "0px",
    marginBottom: "0px",
  }),
  clearIndicator: (base, state) => ({
    ...base,
    paddingTop: "0",
    paddingBottom: "0",
    minHeight: "1px",
  }),
  valueContainer: (base, state) => ({
    ...base,
    minHeight: "1px",
    height: "22px",
    paddingTop: "0",
    paddingBottom: "0",
  }),
  singleValue: (base, state) => ({
    ...base,
    color: "#495057",
    minHeight: "1px",
    paddingTop: "1",
    paddingBottom: "1",
  }),
  loadingIndicator: (base, state) => ({
    ...base,
    paddingTop: "1",
    paddingBottom: "1",
    minHeight: "1px",
    color: "#007bff80",
  }),
  noOptionsMessage: (base, state) => ({
    ...base,
    color: "#dc3545",
  }),
  menu: (base, state) => ({
    ...base,
    zIndex: 3,
    marginTop: "0px",
    borderTopLeftRadius: "0px",
    borderTopRightRadius: "0px",
  }),
  option: (base, state) => ({
    ...base,
    paddingTop: "4px",
    paddingBottom: "4px",
  }),
};



 const CustomSelectComponent = ({
   isMobile,
  title,
  field, // { name, value, onChange, onBlur }
  form: { values, touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps & {
  isMobile: boolean;
  title: string;
  label: string;
  options: Array<{ value: string; label: string }>;
}) => {
  const { options } = props;

  return (
    <FormGroup as={FormCol}>
      <FieldLabel text={title} />
      <Select
        {...field}
        {...props}
        options={options}
        value={
          (values[field.name] === ""
            ? ""
            : options
            ? options.find((option) => option.value === field.value)
            : "") as any
        }
        onChange={(option) =>
          setFieldValue(field?.name, (option as any)?.value)
        }
        styles={styles}
        isClearable={true}
        placeholder={""}
      />
    </FormGroup>
  );
};

export default CustomSelectComponent;
