import React from "react";
import { format, parseISO } from "date-fns";

export const CellDefault = ({ cell: { value } }) => <div>{value || ""}</div>;

export const CellActive = ({ cell: { value } }) => (
  <>
    {value ? (
      <div className="text-center text-success font-weight-bold">{"NAI"}</div>
    ) : (
      <div className="text-center text-danger font-weight-bold">{"OXI"}</div>
    )}
  </>
);

export const CellCenter = ({ cell: { value } }) => (
  <div className="text-center">{value || ""}</div>
);

export const CellPercent = ({ cell: { value } }) => {
  //<div className="text-center">{`${parseFloat(value).toFixed(2)} %`}</div>
  let txt = "-";
  // pad to 10 chars FIGURE SPACE = &numsp; U+2007
  if (value) {
    txt = parseFloat(value).toFixed(2);
    txt = "\u2007".repeat(6 - txt.length) + txt + " %";
  }

  return <div className="text-center">{txt}</div>;
};

export const DateFormat = (value) =>
  !!value ? format(parseISO(value), "dd/MM/yyyy") : "";

export const DateTimeFormat = (value) =>
  !!value ? format(parseISO(value), "dd/MM/yyyy HH:mm") : "";

export const CellDate = ({ cell: { value } }) => (
  <div className="text-center">
    {!!value ? format(parseISO(value), "dd/MM/yyyy") : ""}
  </div>
);

export const CellDateTime = ({ cell: { value } }) => (
  <div className="text-center">
    {!!value ? format(parseISO(value), "dd/MM/yyyy HH:mm") : ""}
  </div>
);

export const CellNoActive = ({ cell: { value } }) => (
  <>
    {!value ? (
      <div className="text-center text-success font-weight-bold">{"OXI"}</div>
    ) : (
      <div className="text-center text-danger font-weight-bold">{"NAI"}</div>
    )}
  </>
);

export const CellYesNo = ({ cell: { value } }) => (
  <div className="text-center">
    {value ? (
      <div className="text-center text-success font-weight-bold">{"NAI"}</div>
    ) : (
      <div className="text-center text-danger font-weight-bold">{"OXI"}</div>
    )}
  </div>
);

export const CellStatus = ({ cell: { value } }) => (
  <div /* className="text-center" */>
    {value === 1 ? (
      <div /* className="text-center" */>{"Εχει Γίνει η Συλλογή"}</div>
    ) : value === 2 ? (
      <div /* className="text-center" */>{"Εχει Ολοκληρωθεί η Συλλογή"}</div>
    ) : (
      <div /* className="text-center" */>{"Εκρεμεί η Συλλογή"}</div>
    )}
  </div>
);
export const CellOkError = ({ cell: { value } }) => (
  <div className="text-center">
    {value ? (
      <div className="text-center text-success font-weight-bold">{"OK"}</div>
    ) : (
      <div className="text-center text-danger font-weight-bold">{"ERROR"}</div>
    )}
  </div>
);
export const CellYesNoNull = ({ cell: { value } }) => (
  <div className="text-center">
    {value === null ? (
      <div className="text-center font-weight-bold">{"ΑΝΑΜΟΝΗ"}</div>
    ) : value ? (
      <div className="text-center text-success font-weight-bold">{"NAI"}</div>
    ) : (
      <div className="text-center text-danger font-weight-bold">{"OXI"}</div>
    )}
  </div>
);

export function CellIntCustom(numberWidth: number) {
  return ({ cell: { value } }) => {
    let txt = "0";
    if (typeof value === "string") {
      const i = parseInt(value, 10);
      if (isNaN(i)) txt = value;
      else txt = i.toLocaleString();
    } else if (typeof value === "number") {
      txt = value.toLocaleString();
    }
    // pad to 10 chars FIGURE SPACE = &numsp; U+2007
    txt = "\u2007".repeat(numberWidth - txt.length) + txt;
    return <div className="text-center">{txt}</div>;
  };
}

export function CellFloatCustom(numberWidth: number, decimalsDigits: number) {
  return ({ cell: { value } }) => {
    let txt = "-";
    if (typeof value === "string") {
      const i = parseFloat(value);
      if (isNaN(i)) txt = value;
      else {
        //txt = i.toLocaleString();
        txt = i.toLocaleString(undefined, {
          minimumFractionDigits: decimalsDigits,
          maximumFractionDigits: decimalsDigits,
        });
      }
    } else if (typeof value === "number") {
      txt = value.toLocaleString(undefined, {
        minimumFractionDigits: decimalsDigits,
        maximumFractionDigits: decimalsDigits,
      });
    }
    // pad to 10 chars FIGURE SPACE = &numsp; U+2007
    //console.log(value, numberWidth, txt.length);
    if (numberWidth > txt.length)
      txt = "\u2007".repeat(numberWidth - txt.length) + txt;
    return <div className="text-center">{txt}</div>;
  };
}

export function FloatCustom(
  value,
  numberWidth: number,
  decimalsDigits: number
) {
  let txt = "-";
  if (typeof value === "string") {
    const i = parseFloat(value);
    if (isNaN(i)) txt = value;
    else {
      //txt = i.toLocaleString();
      txt = i.toLocaleString(undefined, {
        minimumFractionDigits: decimalsDigits,
        maximumFractionDigits: decimalsDigits,
      });
    }
  } else if (typeof value === "number") {
    txt = value.toLocaleString(undefined, {
      minimumFractionDigits: decimalsDigits,
      maximumFractionDigits: decimalsDigits,
    });
  }
  // pad to 10 chars FIGURE SPACE = &numsp; U+2007
  //console.log(value, numberWidth, txt.length);
  if (numberWidth > txt.length)
    txt = "\u2007".repeat(numberWidth - txt.length) + txt;
  return <div className="text-center">{txt}</div>;
}

export const CellInt = ({ cell: { value } }) => {
  return CellIntCustom(10);
};
