import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** DateTime type */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type Query = {
    version?: Maybe<Scalars['String']>;
    hello?: Maybe<Scalars['String']>;
    userLogin?: Maybe<UserLogin>;
    userDecodeJwt?: Maybe<Scalars['String']>;
    jwtRefresh?: Maybe<UserLogin>;
  };

  export type UserLogin = {
    jwt?: Maybe<Scalars['String']>;
    userMustChangePassword?: Maybe<Scalars['Boolean']>;
    expirationDate?: Maybe<Scalars['String']>;
    userId?: Maybe<Scalars['Int']>;
    collectorId?: Maybe<Scalars['Int']>;
    collector?: Maybe<Scalars['String']>;
  };

  export type UserLoginQueryVariables = {
    email?: Maybe<Scalars['String']>;
    userPassword?: Maybe<Scalars['String']>;
  };

  export type UserLoginQuery = {
    userLogin: Maybe<
      Pick<
        UserLogin,
        | 'jwt'
        | 'userMustChangePassword'
        | 'expirationDate'
        | 'userId'
        | 'collectorId'
        | 'collector'
      >
    >;
  };

  export const UserLoginDocument = gql`
  query userLogin($email: String, $userPassword: String) {
    userLogin(email: $email, userPassword: $userPassword) {
      jwt
      userMustChangePassword
      expirationDate
      userId
      collectorId
      collector
    }
  }
`;


export function useUserLoginQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<UserLoginQuery, UserLoginQueryVariables>,
  ) {
    return ApolloReactHooks.useQuery<UserLoginQuery, UserLoginQueryVariables>(UserLoginDocument, baseOptions);
  }
  export function useUserLoginLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserLoginQuery, UserLoginQueryVariables>,
  ) {
    return ApolloReactHooks.useLazyQuery<UserLoginQuery, UserLoginQueryVariables>(UserLoginDocument, baseOptions);
  }
  export type UserLoginQueryHookResult = ReturnType<typeof useUserLoginQuery>;
  export type UserLoginLazyQueryHookResult = ReturnType<typeof useUserLoginLazyQuery>;
  export type UserLoginQueryResult = ApolloReactCommon.QueryResult<UserLoginQuery, UserLoginQueryVariables>;
  
  export type UserSetNewPasswordMutationVariables = {
    email?: Maybe<Scalars['String']>;
    userNewPassword?: Maybe<Scalars['String']>;
  };
  
  export type UserSetNewPasswordMutation = {
    userSetNewPassword: Maybe<
      Pick<
        UserLogin,
        | 'jwt'
        | 'userMustChangePassword'
        | 'expirationDate'
        | 'userId'
        | 'collectorId'
        | 'collector'
      >
    >;
  };
  
  export const UserSetNewPasswordDocument = gql`
    mutation userSetNewPassword($email: String!, $userNewPassword: String!) {
      userSetNewPassword(email: $email, userNewPassword: $userNewPassword)
    }
  `;
  export type UserSetNewPasswordMutationFn = ApolloReactCommon.MutationFunction<
    UserSetNewPasswordMutation,
    UserSetNewPasswordMutationVariables
  >;
  
  export function useUserSetNewPasswordMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<UserSetNewPasswordMutation, UserSetNewPasswordMutationVariables>,
  ) {
    return ApolloReactHooks.useMutation<UserSetNewPasswordMutation, UserSetNewPasswordMutationVariables>(
      UserSetNewPasswordDocument,
      baseOptions,
    );
  }
  export type UserSetNewPasswordMutationHookResult = ReturnType<typeof useUserSetNewPasswordMutation>;
  export type UserSetNewPasswordMutationResult = ApolloReactCommon.MutationResult<UserSetNewPasswordMutation>;
  export type UserSetNewPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
    UserSetNewPasswordMutation,
    UserSetNewPasswordMutationVariables
  >;

  export type ReportTable = {
    tableName?: Maybe<Scalars['String']>;
    iNo?: Maybe<Scalars['Int']>;
    isActive?: Maybe<Scalars['Int']>;
    fieldName?: Maybe<Scalars['String']>;
    fieldType?: Maybe<Scalars['String']>;
    fieldFormat?: Maybe<Scalars['String']>;
    fieldDescription?: Maybe<Scalars['String']>;
  };
  export type GetReportTablesQueryVariables = {
    where?: Maybe<Scalars['String']>;
    offset?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    order?: Maybe<Scalars['String']>;
  };
  
  export type GetReportTablesQuery = {
    getReportTables: Maybe<
      Pick<ReportTablesOutput, 'recordCount'> & {
        data: Maybe<
          Array<
            Maybe<
              Pick<
                ReportTable,
                'tableName' | 'iNo' | 'isActive' | 'fieldName' | 'fieldType' | 'fieldFormat' | 'fieldDescription'
              >
            >
          >
        >;
      }
    >;
  };
  
  export type ReportTableInput = {
    tableName?: Maybe<Scalars['String']>;
    iNo?: Maybe<Scalars['Int']>;
    isActive?: Maybe<Scalars['Int']>;
    fieldName?: Maybe<Scalars['String']>;
    fieldType?: Maybe<Scalars['String']>;
    fieldFormat?: Maybe<Scalars['String']>;
    fieldDescription?: Maybe<Scalars['String']>;
  };
  
  export type ReportTablesOutput = {
    data?: Maybe<Array<Maybe<ReportTable>>>;
    recordCount?: Maybe<Scalars['Int']>;
  };
  export const GetReportTablesDocument = gql`
    query getReportTables($where: String, $offset: Int, $limit: Int, $order: String) {
      getReportTables(where: $where, offset: $offset, limit: $limit, order: $order) {
        data {
          tableName
          iNo
          isActive
          fieldName
          fieldType
          fieldFormat
          fieldDescription
        }
        recordCount
      }
    }
  `;

  export function useGetReportTablesQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<GetReportTablesQuery, GetReportTablesQueryVariables>,
  ) {
    return ApolloReactHooks.useQuery<GetReportTablesQuery, GetReportTablesQueryVariables>(
      GetReportTablesDocument,
      baseOptions,
    );
  }
  export function useGetReportTablesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReportTablesQuery, GetReportTablesQueryVariables>,
  ) {
    return ApolloReactHooks.useLazyQuery<GetReportTablesQuery, GetReportTablesQueryVariables>(
      GetReportTablesDocument,
      baseOptions,
    );
  }
  export type GetReportTablesQueryHookResult = ReturnType<typeof useGetReportTablesQuery>;
  export type GetReportTablesLazyQueryHookResult = ReturnType<typeof useGetReportTablesLazyQuery>;
  export type GetReportTablesQueryResult = ApolloReactCommon.QueryResult<
    GetReportTablesQuery,
    GetReportTablesQueryVariables
  >;