import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import shortid from 'shortid';

type Props = {
  enable: boolean;
  text: string;
  to: string | object;
  exact?: boolean;
  strict?: boolean;
};

const NavLinkItem = ({ enable, text, to, exact = false, strict = false }: Props) => (
  <>
    {enable && (
      <Nav.Link as={NavLink} exact={exact} strict={strict} className="pl-3" to={to} eventKey={shortid()}>
        {text}
      </Nav.Link>
    )}
  </>
);

export default NavLinkItem;
