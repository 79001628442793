import React, { useMemo, useCallback, useState } from "react";
import { Badge, Button, Card, Row } from "react-bootstrap";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import {
  gqlGetTyreCollectionsView,
  gqlUpdateTyreCollectionStatus,
  gqlUpdateTyreCollectionNotes,
} from "./graphql";
import {
  TablePaginated,
  FlexRow,
  FormCol,
  CellIntCustom,
  CellFloatCustom,
  DateFormat,
} from "components/common";
import { useRootStore } from "store";
import { useHistory } from "react-router";
import { FormFilterCard } from "components/common/formik";
import { FieldInput } from "components/common/formik";
import LoadingOverlay from "react-loading-overlay";
import { useLocation } from "react-router-dom";
import { useHandleError } from "services";
import { FieldDateRanger } from "components/common/formik/field-dateranger";
import exportXlsModal from "components/common/export-xls-modal";
import { toastSuccess } from "utils/toasts";
import { format } from "date-fns";
import { PdfRep } from "./pdf-OrderRep";
import { FaEdit } from "react-icons/fa";
import promiseModalInput from "components/modal-input-promise";
import { PdfOrdersRep } from "./pdf-OrdersRep";
import { Field } from "formik";
import CustomSelectComponent from "components/custom-select";
import {
  RouteConfirmationButton,
  RouteChangeButton,
} from "components/route-buttons";
import FieldSelectMunicipalityFromCollections from "components/fs-municipality";
import FieldSelectNomosFromCollections from "components/fs-nomos";
import { parseISO } from "date-fns";

function getBusinessDateCount(startDate, endDate) {
  let elapsed;
  let daysBeforeFirstSaturday;
  let daysAfterLastSunday;
  var ifThen = function(a, b, c) {
    return a == b ? c : a;
  };
  elapsed = endDate - startDate;
  elapsed /= 86400000;
  daysBeforeFirstSaturday = (7 - startDate.getDay()) % 7;
  daysAfterLastSunday = endDate.getDay();
  elapsed -= daysBeforeFirstSaturday + daysAfterLastSunday;
  elapsed = (elapsed / 7) * 5;
  elapsed +=
    ifThen(daysBeforeFirstSaturday - 1, -1, 0) +
    ifThen(daysAfterLastSunday, 6, 5);

  return Math.ceil(elapsed);
}

/* ----------- Page ------------------ */
export const CollectionOrdersPage = () => {
  const { appStore } = useRootStore();
  const { handleGraphqlError } = useHandleError("containerorders");
  /* ----------------- Route State -------------------------- */
  const location = useLocation<any>();
  const history = useHistory();
  // Στο state του routing αποθηκεύω:
  //    1. την τρέχουσα σελίδα του table pagination (pageIndex)
  //  & 2. τα values των φίλτρων (filterValues)

  /* ------------------- Query ------------------------------- */
  const tablePageSize = appStore.pageSize;
  const [initPageIndex, setInitPageIndex] = useState(
    location?.state?.pageIndex ?? 0
  );

  const [queryParams, setQueryParams] = useState({
    offset: tablePageSize * initPageIndex,
    limit: tablePageSize,
    order: location?.state?.order ?? "CollectionIID DESC",
    where: buildWhereFromFilterValues(location?.state?.filterValues),
  });
  const { loading, data: rawData, error, refetch } = useQuery(
    gqlGetTyreCollectionsView,
    {
      variables: { ...queryParams },
      fetchPolicy: "network-only",
    }
  );
  const data = rawData?.getTyreCollectionsView?.data;
  const dataCount = rawData?.getTyreCollectionsView?.recordCount;

  const [updateRecord] = useMutation(gqlUpdateTyreCollectionStatus);
  const [updateNotes] = useMutation(gqlUpdateTyreCollectionNotes);

  /* ------------------- Διαχείριση Φίλτρων ------------------------------ */
  function buildWhereFromFilterValues(values): string {
    // Φτιάξε το where για το graphql
    let where = {};
    if (values) {
      if (values.searchText) {
        const searchText = "%" + values.searchText + "%";
        const likeObj = { $like: searchText };
        where = {
          $or: [
            { $CstPointName$: likeObj },
            { $CstPointAddress$: likeObj },
            { $CstPointPhone1$: likeObj },
            { $CodeId$: likeObj },
          ],
        };
      }
      if (values.collectedStartDate && values.collectedEndDate) {
        const newCollectedEndDate = new Date(values.collectedEndDate);
        newCollectedEndDate.setDate(newCollectedEndDate.getDate() + 1);
        where = {
          ...where,
          RequestDate: {
            $and: [
              { $gte: values.collectedStartDate },
              { $lt: newCollectedEndDate },
            ],
          },
        };
      } else if (
        values.collectedStartDate &&
        values.collectedEndDate === null
      ) {
        where = {
          ...where,
          $RequestDate$: { $gt: values.collectedStartDate },
        };
      } else if (
        values.collectedStartDate === null &&
        values.collectedEndDate
      ) {
        const newCollectedEndDate = new Date(values.collectedEndDate);
        newCollectedEndDate.setDate(newCollectedEndDate.getDate() + 1);
        where = { ...where, $RequestDate$: { $lt: newCollectedEndDate } };
      }
      if (values.nomos) {
        where = { ...where, cstPointNomos: values.nomos };
      }
      if (values.municipality) {
        where = { ...where, cstPointMunicipality: values.municipality };
      }
    }
    where = {
      ...where,
      CollectorId: appStore.userLogin.collectorId,
      Status: 0,
    };
    return JSON.stringify(where);
  }

  const filtersChanged = useCallback(
    (values) => {
      console.log("filtersChanged", values);
      // Φτιάξε το where για το graphql
      const where = buildWhereFromFilterValues(values);
      const order = values.orderBy !== "" ? values.orderBy : undefined;
      // Σύνθεσε τα vars του graphql
      const newQueryParams = {
        ...queryParams,
        offset: 0,
        where: where,
        order: order,
      };
      setQueryParams(newQueryParams); // αποθήκευσε τα vars
      refetch(newQueryParams); // φερε τα δεδομένα απο τον σερβερ
      setInitPageIndex(0); // αρχικοποίησε το gridTable στην 1η σελίδα

      // Ενημέρωσε το State του Route
      history.replace(location.pathname, {
        filterValues: values,
        order: order,
        pageIndex: 0,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, location.pathname, queryParams, refetch]
  );

  /* handle Pagination */
  const gridPageChanged = useCallback(
    (pageIndex, pageSize: number) => {
      console.log("gridPageChanged", pageIndex, pageSize);
      setInitPageIndex(pageIndex);
      const newQueryParams = {
        ...queryParams,
        offset: pageSize * pageIndex,
        limit: pageSize,
      };
      setQueryParams(newQueryParams);
      refetch(newQueryParams);

      // Ενημέρωσε το State του Route
      const state = {
        ...location.state,
        pageIndex,
      };
      history.replace(location.pathname, state);
    },
    [history, location.pathname, location.state, queryParams, refetch]
  );

  const handleUpdate = useCallback(
    (row) => {
      if (
        !window.confirm(
          `Είστε σίγουρος ότι θέλετε να αλλάξετε την κατάσταση της αίτησης;`
        )
      )
        return;
      updateRecord({ variables: { CollectionIID: row.original.CollectionIID } })
        .then((d) => {
          toastSuccess(`Επιτυχής Αλλαγή Κατάστασης`);
          refetch();
        })
        .catch((error) => {
          handleGraphqlError("mutation-updateStatus", error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleUpdateNotes = async (row) => {
    const res = await promiseModalInput({
      title: "Παρατηρήσεις",
      text: "Εισάγετε τις παρατηρήσεις σας:",
      defaultText: row.original.CollectorNotes,
    });
    if (res) {
      console.log(res);
      updateNotes({
        variables: { CollectionIID: row.original.CollectionIID, Notes: res },
      })
        .then(() => {
          toastSuccess(`Επιτυχής Προσθήκη Παρατηρήσεων`);
          refetch();
        })
        .catch((error) => {
          handleGraphqlError("mutation-updateNotes", error);
        });
    }
  };

  function useGetTyreCollections() {
    return useLazyQuery(gqlGetTyreCollectionsView);
    //return [null, { loading: null, data: null }];
  }

  const getTitle = () => {
    const filters = location?.state?.filterValues ?? undefined;
    let titles = ["Εκκρεμείς Αιτήσεις Συλλογής Ελαστικών"];
    if (filters?.startDate || filters?.endDate) {
      const startDate = filters?.startDate
        ? format(filters.startDate, "dd/MM/yyyy")
        : " - ";
      const endDate = filters?.endDate
        ? format(filters.endDate, "dd/MM/yyyy")
        : " - ";
      titles.push("Αιτήσεις Συλλογής Από: " + startDate + "  Έως: " + endDate);
    }
    if (filters?.nomos) {
      titles.push("Νομός: " + filters.nomos);
    }
    if (filters?.municipality) {
      titles.push("Δήμος: " + filters.municipality);
    }
    if (filters?.searchText) {
      titles.push("Αναζήτηση: " + filters.searchText);
    }
    return titles.join("\n");
  };
  const handleExport = useCallback(async () => {
    await exportXlsModal({
      getLazyQuery: useGetTyreCollections,
      filterVariables: queryParams,
      tableName: "Tyre_Collections_View",
      title: getTitle(),
      columnsDef: [
        { fieldName: "CollectionIID", fieldTitle: "Α/Α", fieldType: "INT" },
        {
          fieldName: "CodeId",
          fieldTitle: "Αριθμός Αίτησης",
          fieldType: "INT",
        },
        {
          fieldName: "Year",
          fieldTitle: "Έτος",
          fieldType: "TEXT",
        },
        {
          fieldName: "CstPointName",
          fieldTitle: "Σημείο Συλλογής",
          fieldType: "TEXT",
        },
        {
          fieldName: "CstPointTypeName",
          fieldTitle: "Κατηγορία Σημείου",
          fieldType: "TEXT",
        },
        {
          fieldName: "CstPointAddress",
          fieldTitle: "Διεύθυνση",
          fieldType: "TEXT",
        },
        {
          fieldName: "CstPointMunicipality",
          fieldTitle: "Δήμος",
          fieldType: "TEXT",
        },
        { fieldName: "CstPointNomos", fieldTitle: "Νομός", fieldType: "TEXT" },
        { fieldName: "CstPointZipCode", fieldTitle: "Τ.Κ.", fieldType: "TEXT" },
        {
          fieldName: "CstPointPhone1",
          fieldTitle: "Τηλέφωνο",
          fieldType: "TEXT",
        },
        {
          fieldName: "RequestDate",
          fieldTitle: "Ημερομηνία Αίτησης",
          fieldType: "DATETIME",
        },
        {
          fieldName: "Status",
          fieldTitle: "Κατάσταση",
          fieldType: "STATUS",
        },
        {
          fieldName: "Notes",
          fieldTitle: "Σημειώσεις",
          fieldType: "TEXT",
        },
        {
          fieldName: "TyreType1_ReqQty",
          fieldTitle: "Κατηγορία Α",
          fieldType: "INTqty",
        },
        {
          fieldName: "TyreType2_ReqQty",
          fieldTitle: "Κατηγορία Β",
          fieldType: "INTqty",
        },
        {
          fieldName: "TyreType3_ReqQty",
          fieldTitle: "Κατηγορία Γ",
          fieldType: "INTqty",
        },
        {
          fieldName: "TyreType4_ReqQty",
          fieldTitle: "Κατηγορία Δ1",
          fieldType: "INTqty",
        },
        {
          fieldName: "TyreType5_ReqQty",
          fieldTitle: "Κατηγορία Δ2",
          fieldType: "INTqty",
        },
        {
          fieldName: "ReqTyresWeight",
          fieldTitle: "Θεωρητικό Βάρος (kg)",
          fieldType: "INTqty",
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  if (error) {
    handleGraphqlError("query-get", error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <Card className="shadow overflowscroll">
        <Card.Header>
          <FlexRow>
            <Card.Title className="m-0">
              Εκκρεμείς Αιτήσεις Συλλογής Ελαστικών
            </Card.Title>
          </FlexRow>
        </Card.Header>
        <Card.Body className="nopadding">
          {/* Φίλτρα */}
          <Filters
            onChange={filtersChanged}
            initialValues={location?.state?.filterValues ?? {}}
          />

          {/* TableGrid */}
          <LoadingOverlay
            active={loading}
            spinner
            text="Παρακαλώ περιμένετε..."
          >
            {rawData ? (
              <ContainersGrid
                data={data}
                dataCount={dataCount}
                initPageIndex={initPageIndex}
                onPageChange={gridPageChanged}
                handleUpdate={handleUpdate}
                handleExport={handleExport}
                handleUpdateNotes={handleUpdateNotes}
                buildWhereFromFilterValues={buildWhereFromFilterValues}
                refetch={refetch}
              />
            ) : (
              <div style={{ height: "200px" }}>{}</div>
            )}
            {/* {rawData && canShowImportButton() && <ImportExcel refetch={refetch} queryParams={queryParams} />} */}
          </LoadingOverlay>
        </Card.Body>
      </Card>
    );
  }
};

/* ----------- Grid ------------------ */

const ContainersGrid = ({
  data,
  dataCount,
  initPageIndex,
  onPageChange,
  handleUpdate,
  handleExport,
  handleUpdateNotes,
  buildWhereFromFilterValues,
  refetch,
}) => {
  //console.log(props.dataX);

  const location = useLocation<any>();
  const columns = useMemo(
    () => [
      {
        /* ΚΟΛΩΝΑ ΜΕ RedEye και Click handler */
        id: "select",
        Header: "A/A",
        Cell: ({ row }) => (
          <div className="text-center">{row.original.CollectionIID}</div>
        ),
      },
      {
        Header: "Αριθμός Αίτησης",
        accessor: "CodeId",
        Cell: ({ row }) => {
          return (
            <div className="text-left">
              {row.original.CodeId} / {row.original.Year}
            </div>
          );
        },
      },
      {
        Header: "Σημείο Συλλογής",
        accessor: "CstPointName",
        Cell: ({ row }) => {
          return (
            <div className="text-left">
              {row.original.CstPointName ? (
                <div>{row.original.CstPointName}</div>
              ) : null}
              {row.original.CstPointAddress ? (
                <div>{row.original.CstPointAddress}</div>
              ) : null}
              {row.original.CstPointMunicipality ? (
                <div>{row.original.CstPointMunicipality}</div>
              ) : null}
              {row.original.CstPointZipCode ? (
                <div>{`Τ.Κ. ${row.original.CstPointZipCode}`}</div>
              ) : null}
              {row.original.CstPointNomos ? (
                <div>{row.original.CstPointNomos}</div>
              ) : null}
              {row.original.CstPointPhone1 ? (
                <div>{row.original.CstPointPhone1}</div>
              ) : null}
            </div>
          );
        },
      },
      { Header: "Κατηγορία Σημείου", accessor: "CstPointTypeName" },
      {
        Header: "Ημ/νία Αίτησης",
        accessor: "RequestDate",
        Cell: ({ cell: { value } }) => {
          const dt = parseISO(value);
          dt.setDate(dt.getDate() + 3);
          const count = getBusinessDateCount(dt, new Date());
          const isOverdue = count > 3;
          return (
            <div
              className="text-center"
              style={{ color: isOverdue ? "red" : "black" }}
            >
              {!!value ? format(parseISO(value), "dd/MM/yyyy") : ""}
              {isOverdue && (
                <div className="mt-2">
                  <Badge variant="danger"> Καθυστερημένη</Badge>
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "Δρομολόγηση",
        accessor: "PlannedDate",
        Cell: ({ row }) => {
          return (
            <div className="text-left">
              {DateFormat(row.original.plannedDate)}
              {row.original.plannedDate &&
                !row.original.isPlannedDateConfirmed && (
                  <RouteConfirmationButton
                    collectionIID={row.original.CollectionIID}
                    refetch={refetch}
                  />
                )}
              {row.original.plannedDate && (
                <RouteChangeButton
                  collectionIID={row.original.CollectionIID}
                  refetch={refetch}
                  label={"Αλλαγή ημ."}
                />
              )}
            </div>
          );
        },
      },
      { Header: "Σημειώσεις", accessor: "Notes" },
      {
        Header: "Κατηγορία Α",
        accessor: "TyreType1_ReqQty",
        Cell: CellIntCustom(7),
      },
      {
        Header: "Κατηγορία Β",
        accessor: "TyreType2_ReqQty",
        Cell: CellIntCustom(7),
      },
      {
        Header: "Κατηγορία Γ",
        accessor: "TyreType3_ReqQty",
        Cell: CellIntCustom(7),
      },
      {
        Header: "Κατηγορία Δ1",
        accessor: "TyreType4_ReqQty",
        Cell: CellIntCustom(7),
      },
      {
        Header: "Κατηγορία Δ2",
        accessor: "TyreType5_ReqQty",
        Cell: CellIntCustom(7),
      },
      {
        Header: "Θεωρητικό Βάρος (kg)",
        accessor: "ReqTyresWeight",
        Cell: CellFloatCustom(10, 1),
      },
      {
        id: "updateNotes",
        Header: `Παρατηρήσεις`,
        accessor: "action",
        Cell: ({ row }) => (
          <div className="text-center">
            {row.original.CollectorNotes}{" "}
            <FaEdit
              size={15}
              style={{ cursor: "pointer" }}
              onClick={() => handleUpdateNotes(row)}
            />
          </div>
        ),
      },
      {
        id: "updateOrder",
        Header: `Αλλαγές`,
        accessor: "action",
        Cell: ({ row }) => (
          <div className="text-center">
            <Button
              className="p-0"
              variant="link"
              /*  style={{ color: 'Blue' }} */
              onClick={() => {
                handleUpdate(row);
              }}
            >
              Αλλαγή Κατάστασης
            </Button>
          </div>
        ),
      },
      {
        id: "pdf",
        Header: `Εκτυπώσεις`,
        accessor: "action",
        Cell: ({ row }) => (
          <div className="text-center">
            <PdfRep data={row.original}></PdfRep>
          </div>
        ),
      },
    ],
    [handleUpdate, handleUpdateNotes, refetch]
  );
  return (
    <>
      <TablePaginated
        title={""}
        columns={columns}
        data={data}
        dataCount={dataCount}
        // initPageIndex={initPage}
        // onSortByChange={handleSortByChange}
        onExport={handleExport}
        onPageChange={onPageChange}
        initPageIndex={initPageIndex}
      />
      <PdfOrdersRep
        buildWhere={buildWhereFromFilterValues}
        fromDate={location?.state?.filterValues?.collectedStartDate}
        tillDate={location?.state?.filterValues?.collectedEndDate}
        data={data}
      ></PdfOrdersRep>
    </>
  );
};

/* ----------- Filters ------------------ */
const Filters = ({ onChange, initialValues }) => {
  const { appStore } = useRootStore();
  function handleFiltersSubmit(values, { setSubmitting }) {
    setTimeout(() => {
      setSubmitting(false);
      console.log("values", values);
      onChange(values);
    }, 400);
  }
  console.log("filters-init ", initialValues);

  const options = [
    { value: "RequestDate DESC", label: "Ημ/νία Αίτησης (Φθίνουσα)" },
    { value: "RequestDate ASC", label: "Ημ/νία Αίτησης (Αύξουσα)" },
    { value: "CstPointNomos DESC", label: "Νομό (Φθίνουσα)" },
    { value: "CstPointNomos ASC", label: "Νομό (Αύξουσα)" },
    { value: "CstPointMunicipality DESC", label: "Δήμο (Φθίνουσα)" },
    { value: "CstPointMunicipality ASC", label: "Δήμο (Αύξουσα)" },
    { value: "ReqTyresWeight DESC", label: "Θεωρητικό Βάρος (Φθίνουσα)" },
    { value: "ReqTyresWeight ASC", label: "Θεωρητικό Βάρος (Αύξουσα)" },
    { value: "CstPointZipCode", label: "T.K." },
  ];

  const filters = {
    orderBy: initialValues?.orderBy ?? "",
    searchText: initialValues?.searchText ?? "",
    collectedStartDate: initialValues?.collectedStartDate ?? null,
    collectedEndDate: initialValues?.collectedEndDate ?? null,
    nomos: initialValues?.nomos ?? null,
    municipality: initialValues?.municipality ?? null,
  };

  return (
    <FormFilterCard
      title="Κριτήρια Αναζήτησης"
      onSubmit={handleFiltersSubmit}
      initialValues={{
        orderBy: filters?.orderBy,
        searchText: filters?.searchText,
        collectedStartDate: filters?.collectedStartDate,
        collectedEndDate: filters?.collectedEndDate,
        nomos: filters?.nomos,
        municipality: filters?.municipality,
      }}
    >
      <Row>
        <FieldDateRanger
          as={FormCol}
          label="Ημερομηνία από"
          name="collectedStartDate"
          name2="collectedEndDate"
        />
        <Field
          name={"orderBy"}
          title="Ταξινόμηση ανά"
          component={CustomSelectComponent}
          options={options}
        />
        <FieldSelectNomosFromCollections
          as={FormCol}
          collectorId={appStore.userLogin.collectorId}
          filterId={3}
        />
        <FieldSelectMunicipalityFromCollections
          as={FormCol}
          collectorId={appStore.userLogin.collectorId}
          filterId={3}
        />
      </Row>
      <Row>
        <FieldInput
          as={FormCol}
          name="searchText"
          label="Αναζήτηση (Επωνυμία, Τηλέφωνο, Διεύθυνση, Αριθμός Αίτησης)"
          type="search"
        />
      </Row>
    </FormFilterCard>
  );
};
