import React from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import ApolloClient from "apollo-client";
import { Button } from "react-bootstrap";
import { useApolloClient } from "@apollo/react-hooks";
import { FaFilePdf } from "react-icons/fa";
import { format } from "date-fns";
import { pdfDocOpen, styles, BoldText, PdfTable } from "utils/pdfhelpfunc";
import { useRootStore } from "store";

const columns = [
  { header: "Σταθμός Ανακύκλωσης", name: "Name", type: "string" },
  {
    header: "Μηνιαίο όριο Παραδόσεων(Kg)",
    name: "MonthlyKgLimit",
    type: "float",
  },
  { header: "Άθροισμα Παραδόσεων(Kg)", name: "NetWeight", type: "float" },
  {
    header: "Υπόλοιπο για Παραλαβή(Kg)",
    name: "RemainingWeight",
    type: "float",
  },
];

const FirstPage = ({ collector, fromDate, tillDate, data }) => (
  <Page size="A4" style={styles.page}>
    <View
      style={{
        width: 580,
        height: 820,
        display: "flex",
        padding: "50,30,25,30",
      }}
    >
      <View style={{ flexDirection: "row" }}>
        <View style={{ flex: 1 }}>
          <Image
            style={{ width: 200, height: 50 }}
            src={process.env.PUBLIC_URL + "/ecoelastika_logo.jpg"}
          />
        </View>
        <View style={{ flexDirection: "column" }}>
          <View
            style={{
              flex: 3,
              fontSize: "1.3vh",
              alignSelf: "flex-end",
              marginRight: "30px",
              alignItems: "center",
            }}
          >
            <BoldText>{"ΠΛΑΦΟΝ ΠΑΡΑΔΟΣΕΩΝ"}</BoldText>
            <BoldText>{"ΑΝΑ ΣΤΑΘΜΟ ΑΝΑΚΥΚΛΩΣΗΣ"}</BoldText>
          </View>
        </View>
      </View>

      <View style={{ marginTop: "50px" }}>
        <Text
          style={{ alignSelf: "center", fontSize: "1.3vh" }}
        >{`${collector}`}</Text>
        <Text style={{ alignSelf: "center", fontSize: "1.3vh" }}>
          {"Ημερομηνία από:  "}
          {format(fromDate, "dd-MM-yyyy")} {" έως  "}
          {format(tillDate, "dd-MM-yyyy")}
        </Text>
      </View>
      <View>
        <PdfTable data={data} columns={columns} footer={true} />
      </View>

      <View
        style={{
          flexDirection: "column",
          alignItems: "center",
          marginTop: "auto",
          fontSize: "1vh",
        }}
      >
        <BoldText>{"ECOELASTIKA AE"}</BoldText>
        <Text>{"A.E. Οικολογικής Διαχείρισης Ελαστικών"}</Text>
        <Text>{"Α.Φ.Μ.: 999402660 Δ.Ο.Υ.: Φ.Α.Ε. ΑΘΗΝΩΝ"}</Text>
        <Text>{"Αρ.Γ.Ε.Μ.Η.: 005728201000"}</Text>
        <Text>
          {"ΚΑΛΑΜΑ ΠΟΤΑΜΟΥ 32 & Λ. ΚΗΦΙΣΙΑΣ 326 , ΤΚ 152 33 ΧΑΛΑΝΔΡΙ"}
        </Text>
        <Text>{"Τηλ: 210-6128260∙370 - Φαξ: 210-6128659"}</Text>
      </View>
    </View>
  </Page>
);

// Create Document Component
const MyDocument = ({ collector, allData, fromDate, tillDate }) => (
  <Document>
    <FirstPage
      data={allData}
      fromDate={fromDate}
      tillDate={tillDate}
      collector={collector}
    ></FirstPage>
  </Document>
);

export const PdfRep = ({ data, fromDate, tillDate }) => {
  const client = useApolloClient();
  const { appStore } = useRootStore();

  const records = [...data];
  async function printPdf(client: ApolloClient<object>): Promise<void> {
    appStore.setBusy();
    await pdfDocOpen(
      `collectorStationsReport`,
      <MyDocument
        fromDate={fromDate}
        tillDate={tillDate}
        collector={appStore.userLogin.collector}
        allData={records}
      />
    );
    appStore.clearBusy();
  }

  return (
    <Button
      style={{ width: "160px", marginLeft: "27px" }}
      variant="success"
      onClick={() => printPdf(client)}
    >
      <FaFilePdf className="mb-1" /> Εξαγωγή σε PDF
    </Button>
  );
};
