import React from "react";
import { Button } from "react-bootstrap";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import promiseModalSelectDateOnly from "components/common/dialogs/modal-select-dateOnly";
import moment from "moment";
import promiseModalDialog from "components/common/dialogs/modal-dialog-promise";

const gqlConfirmRouteEstimationDate = gql`
  mutation confirmRouteEstimationDate($collectionIID: Int!) {
    confirmRouteEstimationDate(collectionIID: $collectionIID)
  }
`;
export const RouteConfirmationButton = ({ collectionIID, refetch }) => {
  const [mutate] = useMutation(gqlConfirmRouteEstimationDate);
  const handleClick = async () => {
    const ret = await promiseModalDialog({
      title: "Επιβεβαίωση",
      text: "Επιβεβαίωση Ημερομηνίας Δρομολογίου;",
    });
    if (!ret) return;
    await mutate({ variables: { collectionIID } });
    refetch();
  };
  return (
    <Button
      block
      variant="success"
      style={{ marginTop: "4px" }}
      onClick={handleClick}
    >
      Επιβεβαίωση
    </Button>
  );
};

const gqlChangeRoutePlannedDate = gql`
  mutation changeRoutePlannedDate(
    $collectionIID: Int!
    $plannedDate: DateTime!
  ) {
    changeRoutePlannedDate(
      collectionIID: $collectionIID
      plannedDate: $plannedDate
    )
  }
`;

export const RouteChangeButton = ({
  collectionIID,
  refetch,
  label = undefined,
}) => {
  const [mutate] = useMutation(gqlChangeRoutePlannedDate);
  const handleClick = async () => {
    const ret = await promiseModalSelectDateOnly();
    if (ret === false) return;

    const date = moment(ret.date).format("YYYY-MM-DDTHH:mm:ss") + "+00:00"; // ditch timezone
    await mutate({
      variables: {
        collectionIID,
        plannedDate: date,
      },
    });
    refetch();
  };
  return (
    <Button
      variant="warning"
      block
      style={{ marginTop: "4px" }}
      onClick={handleClick}
    >
      {label ? label : "Αλλαγή ημ/νίας"}
    </Button>
  );
};
