import React from 'react';
import FlexRow from './flex-row';
import { Spinner } from 'react-bootstrap';

const FlexSpinner = () => (
  <FlexRow>
    <div></div>
    <Spinner className="m-4" animation="border" role="status" />
    <div></div>
  </FlexRow>
);

export default FlexSpinner;
