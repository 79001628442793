import React from 'react';
import { Form, Button, Card, InputGroup, Spinner } from 'react-bootstrap';
import { FaUser} from 'react-icons/fa';
import { Formik, Form as FForm } from 'formik';
import { string, object, InferType } from 'yup'; // for only what you need
import { useWhyDidYouUpdate } from '../hooks';
/**
 * Setup Yup Validation
 */
const validationSchema = object().shape({
  name: string().required(),
});

export type FormDataType = InferType<typeof validationSchema>;

type Props = {
  message?: string | null;
  loading?: boolean;
  onSubmit: (values, action) => void;
};

const ForgotPwForm = ({ message = '', loading = false, onSubmit }: Props) => {
  /*-------------------- Body -----------------------------------------------*/

  useWhyDidYouUpdate('[ForgotPwForm]', { message, onSubmit });
  /*-------------------- Render ---------------------------------------------*/
  return (
    <Formik initialValues={{ name: '' }} validationSchema={validationSchema} onSubmit={onSubmit}>
      {formikBag => (
        <Card className="bg-light shadow-sm p-3 mb-5" style={{ maxWidth: 640 }}>
         <Card.Img className="bg-light" variant="top" src="index_3D_Logo_GR.jpg" />
      <Card.Body>
        <Card.Title className="text-center mb-4 mt-1" as="h4">
          Αποστολή Προσωρινού Κωδικού
        </Card.Title>
            <p className="text-danger text-center">{message && ''}</p>

            <Form as={FForm}>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FaUser />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    className="rounded-right"
                    id="name"
                    size={"sm"}
                    type="text"
                    placeholder="Email Χρήστη"
                    {...formikBag.getFieldProps('name')}
                    isInvalid={!!formikBag.touched.name && !!formikBag.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    Το email απαιτείται. Δεν μπορεί να είναι κενό
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>

              <Button
                block
                variant="success"
                size={"sm"}
                style={{ fontSize: '0.8rem' }}
                type="submit"
                disabled={!(formikBag.isValid && formikBag.dirty)}
              >
                {loading ? <Spinner animation="border" size="sm" className="mx-2" /> : <span></span>}
                <span>{'Αποστολή'}</span>
              </Button>
            </Form>
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
};


export default  ForgotPwForm;