import React, { useCallback } from 'react';

import ReactPaginate from 'react-paginate';

type Props = {
  current: number;
  total: number;
  onChange: (page: number) => void;
};

const Pagination = ({ current, total, onChange }: Props) => {
  /*-------------------- Body -----------------------------------------------*/
  /*-------------------- Handlers -------------------------------------------*/
  const handlePageChange = useCallback(
    data => {
      onChange(data.selected);
    },
    [onChange],
  );
  /*-------------------- Render ---------------------------------------------*/
  return (
    <ReactPaginate
      pageCount={total}
      forcePage={current}
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
      previousLabel={'<'}
      nextLabel={'>'}
      breakLabel="..."
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination my-1"
      subContainerClassName=""
      pageClassName="page-item"
      pageLinkClassName="page-link"
      activeClassName="active"
      activeLinkClassName="page-link"
      previousClassName="page-item"
      nextClassName="page-item"
      previousLinkClassName="page-link"
      nextLinkClassName="page-link"
      disabledClassName="disabled"
      onPageChange={handlePageChange}
    />
  );
};

export default React.memo(Pagination);
