import React, { FC } from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import { FlexSpinner } from 'components/common';

type Props = {
  show: boolean;
  progress: number;
  title: string;
};

const ModalSpinner: FC<Props> = ({ title, show, progress }) => {
  return (
    <Modal show={show} centered backdrop="static">
      <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      <Modal.Body>
        <FlexSpinner />
        {progress > 0 && <ProgressBar now={progress} />}
      </Modal.Body>
    </Modal>
  );
};

export default ModalSpinner;
