import { FlexSpinner, ModalSpinner } from '../components/common';
import { useWhyDidYouUpdate } from '../hooks';
import { observer, Observer } from 'mobx-react-lite';
import React, { FC, Suspense, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import shortid from 'shortid';
import { useRootStore } from '../store';
import styled from 'styled-components';
import Footer from './footer';
import Header from './header';
import { routes } from './routes';
import { useHistory } from 'react-router';
import LoginPage from 'login';
import setPasswordModal from './set-password-modal';
import { ChangePwPage, ForgotPwPage, NewUserPage } from 'pages';

/******************************************************************************
 * Ειδικό component για να "κρύψω" τα routes που δεν θέλω να υπάρχουν όταν
 * ο χρήστης δεν έχει κάνει ακόμα login. Στην ουσία είναι κατα συνθήκη είτε
 * Route ή Redirect στο login
 */

type Props = Route['props'] & {
  component: React.ComponentType<any>;
};

const PrivateRoute: FC<Props> = ({ component: Component, children, ...rest }) => {
  const { appStore } = useRootStore();

  return (
    <Route
    {...rest}
    render={props =>
      appStore.isLogIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
    />
  );
};


/**
 * Ειδικό component για να δρομολογήσω το login sto Home οταν ο χρήστης έχει κάνει login
 */
/* const PublicRoute: FC<Props> = ({ component: Component, ...rest }) => {
  const { appStore } = useRootStore();
  return <Route {...rest} render={props => (!appStore.isLogIn ? <Component {...props} /> : <Redirect to={'/'} />)} />;
}; */

/******************************************************************************
 * Βασικό route component AppRoute
 *
 * Κάθε φορά που γίνεται update το appStore το AppRoute γινεται update και δρομολογεί αναλόγως
 * την σελίδα που πρέπει. Με τον τρόπο αυτό οι σελίδες κατά κανόνα δεν χρειάζεται να ελέγχουν
 * το login/logout ή κάποιο πεδίο του appStore.
 *
 */
export const AppRoute = observer(() => {
  const { appStore } = useRootStore();
  const history = useHistory();
  const AbsoluteWrapper = styled.div``;

  
  useEffect(() => {
    if (appStore.userLogin.userMustChangePassword) {
      setPasswordModal().then(res => null);
    }
  }, [appStore.userLogin.userMustChangePassword]);

  useWhyDidYouUpdate('[AppRoute]', appStore.userLogin );
  return (
    <AbsoluteWrapper className="App">
      <Header
        onLogoutClick={() => {
          history.push('/home');
          localStorage.clear();
          appStore.logOut();
        }}
      />
      <Container fluid className="d-flex flex-column flex-grow-1" style={{ paddingTop: 54, paddingBottom: 48 }}>
      <Observer>{() => <ModalSpinner title={appStore.progressTitle} show={appStore.busy} progress={appStore.progress} />}</Observer>
        {/* {process.env.REACT_APP_DEMO === '1' && (
          <Alert variant="warning" className="py-1 mb-2">
            <FlexRow>
              <div />
              <h6 className="m-0">DEMO VERSION</h6>
              <div />
            </FlexRow>
          </Alert>
        )} */}
        <Suspense fallback={<FlexSpinner />}>
          <Switch>
            {/* <PublicRoute path={'/login'} exact strict component={LoginPage} /> */}
            <Route path={'/login'}>
              {' '}
              <LoginPage />{' '}
            </Route>

            <Route path={'/changepw'}>
              {' '}
              <ChangePwPage />{' '}
            </Route>
            
            <Route path={'/forgotpw'}>
              {' '}
              <ForgotPwPage />{' '}
            </Route>

            <Route path={'/newuser'}>
              {' '}
              <NewUserPage />{' '}
            </Route>

            {routes.map(route => (
              <PrivateRoute key={shortid.generate()} path={route.path} component={route.component} exact strict />
            ))}
            <Route render={() => <Redirect to={'/'}></Redirect>} />
          </Switch>
        </Suspense>
      </Container>
      <Footer />
    </AbsoluteWrapper>
  );
});
