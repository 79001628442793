import React, { useCallback, useMemo } from 'react';
import { Form, OverlayTrigger } from 'react-bootstrap';
import { useField, useFormikContext, FieldAttributes } from 'formik';
import BootstrapSwitchButton from './../bootstrap-switch-button/bootstrap-switch-button-react';

import { FieldLabel } from './../index';

import FieldTooltip from './field-tooltip';



type Props = {
  [key: string]: any;
  as?: React.ElementType<any>;
  label: string;
  onLabel?: string;
  offLabel?: string;
  width?: number;
  onStyle?: string;
  offStyle?: string;
  disabled?: boolean;
} & FieldAttributes<any>;

const FieldCheckbox = ({
  as = 'div',
  name,
  label,
  onLabel = ' NAI ',
  offLabel = ' OXI ',
  width = 80,
  disabled = false,
  onStyle = undefined,
  offStyle = undefined,
}: Props) => {
  //
  //-------------------- Body -----------------------------------------------
  //
  const [{ value }, { initialValue }] = useField<boolean>(name);
  const { setFieldValue, setFieldTouched } = useFormikContext<any>();

  const setFieldValueAndTouched = useCallback(
    async (value, touched: boolean) => {
      await setFieldValue(name, value, true); //field,value,shouldValidate
      await setFieldTouched(name, touched); //field,touched,shouldValidate
    },
    [name, setFieldTouched, setFieldValue],
  );
  //
  //-------------------- Handlers -------------------------------------------
  //
  const handleChange = useCallback(
    checked => {
      setFieldValueAndTouched(checked, true);
    },
    [setFieldValueAndTouched],
  );

  const changed = value !== initialValue;

  // useWhyDidYouUpdate(`[FieldCheckbox:${name}]`, {
  //   label,
  //   disabled,
  //   name,
  //   handleChange,
  //   setFieldValueAndTouched,
  //   value,
  //   initialValue,
  // });
  //
  //-------------------- Render -------------------------------------------
  //
  return useMemo(
    () => (
      <Form.Group as={as} controlId={'formGroup_' + name} className="mb-2">
        <FieldLabel text={label} changed={changed} />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <OverlayTrigger
            delay={{ show: 1000, hide: 200 }}
            overlay={FieldTooltip({ text: name, value: value ? " ΝΑΙ " : " ΟΧΙ " })}
            placement="top"
          >
            <div>
              <BootstrapSwitchButton
                checked={value || false}
                onlabel={" ΝΑΙ "}
                offlabel={" ΟΧΙ "}
                width={width}
                onChange={handleChange}
                onstyle={!!onStyle ? onStyle : disabled ? 'dark' : 'primary'}
                offstyle={!!offStyle ? offStyle : disabled ? 'light' : 'warning'}
                disabled={disabled}
              />
            </div>
          </OverlayTrigger>
        </div>
      </Form.Group>
    ),
    [as, name, label, changed, value, width, handleChange, onStyle, disabled, offStyle],
  );
};

export default FieldCheckbox;
