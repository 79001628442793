import React, { useState } from 'react';
import { Modal, Button, ModalProps } from 'react-bootstrap';
import { createModal } from 'react-modal-promise';

type Props = {
  open: boolean;
  close: (result: any) => void;
  title: string;
  /** body of dialog */
  text: string | React.ReactNode;
  /** accept text */
  acceptText?: string;
  /** deny text */
  denyText?: string;
  type?: string;
  defaultText?: string;
} & ModalProps;

const ModalInput = ({ open, close, title, text, acceptText = 'ΟΚ', type = 'text', defaultText }: Props) => {
  const [input, setInput] = useState( defaultText ? defaultText : '');

  const handleChange = e => {
    setInput(e.target.value);
  };
  return (
    <>
      <Modal show={open} centered backdrop="static">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>{text}</label>
          <input className="form-control" onChange={handleChange} type={type} value={input} />{' '}
        </Modal.Body>
        <Modal.Footer>
          {/*  <Button variant="danger" onClick={() => close(false)}>
            {denyText}
          </Button> */}
          <Button variant="primary" onClick={() => close(input)}>
            {acceptText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const promiseModalInput = createModal(ModalInput);

export default promiseModalInput;
