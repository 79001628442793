import React from 'react';
import { Card } from 'react-bootstrap';
import { FlexRow, ButtonClear, ButtonSearch } from './../index';
import { useFormikContext } from 'formik';

type Props = {
  /** Title of form */
  title: string;
  /** Optional title suffix muted */
  idx?: number;
  /** Optional onReturn event, enabled only if form is not dirty */
  onReturn?: () => void;
  onClear?: () => void;
  disableClear?: boolean;
  disableSubmit?: boolean;
};

const FormFilterHeader = ({ title, idx = null, onReturn = null, onClear = null, disableClear= false, disableSubmit= false }: Props) => {
  const { isValid, setFieldValue, values } = useFormikContext();
  // Reset filter values at any point
  const resetAll = () => {
    if (typeof onClear === 'function') {
      onClear();
    }
    // Clear Values
    Object.keys(values).forEach(k => (k.includes('Date') ? setFieldValue(k, null) : k.includes('StoreLocName') ? undefined : setFieldValue(k, '')));
  };
  return (
    <Card.Header className="py-1">
      <Card.Title className="m-0">
        <FlexRow>
          <h5>{title}</h5>
          <div>
          {!disableSubmit && <ButtonSearch disabled={!isValid} type="submit" size="sm" className="mr-2" /> }   
         {!disableClear && <ButtonClear onClick={resetAll} size="sm" /> }   
          </div>
        </FlexRow>
      </Card.Title>
    </Card.Header>
  );
};

export default React.memo(FormFilterHeader);
