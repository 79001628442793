import React from 'react';
import ReactDOM from 'react-dom';

import './styles/index.scss';
import App from './app/app';
import gitnum from 'gitnum';
import * as app from '../package.json';
//import * as serviceWorker from './serviceWorker';

/* if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js');
  whyDidYouRender(React);
} */
console.log('Running application version:', app.version + '-' + gitnum.git);
console.log('WEBAPI= ', process.env.REACT_APP_WEBAPI);
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
