import React from 'react';
import ModalContainer from 'react-modal-promise';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RootStoreProvider, rootStore, useRootStore } from '../store';
import { AppRoute } from './app-route';
// Apollo Client Imports
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { ApolloProvider } from '@apollo/react-hooks';
import { Observer } from 'mobx-react-lite';
import { persistLogError } from '../services';

const App = () => {
  const { appStore } = useRootStore();
  // * APOLLO CONFIG 1 : Plain HttpLink
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_WEBAPI,
    credentials: 'same-origin',
  });
  // * APOLLO CONFIG 2 : Cache
  const cache = new InMemoryCache();
  // * APOLLO CONFIG 3 : Authentication Middleware (optional)
  const authMiddleware = new ApolloLink((operation, forward) => {
    // get the authentication token from local storage if it exists
    const tokenValue = rootStore.appStore.userLogin.jwt;
    // return the headers to the context so httpLink can read them
    operation.setContext({
      headers: {
        authorization: tokenValue ? `bearer ${tokenValue}` : null,
      },
    });
    return forward(operation);
  });
  // * APOLLO CONFIG 4 : Error Handler (optional)
  const errorLink = onError(err => {
    //console.log('[Apollo.onError]', err);
    const operationName = err?.operation?.operationName ?? 'unknown'; // i.e. updateBatteriescollection
    const statusCode = (err.networkError as any)?.statusCode ?? 'unknown'; // i.e. 400 Bad Request
    if (err.graphQLErrors)
      // eslint-disable-next-line array-callback-return
      err.graphQLErrors.map(({ message, locations, path }) => {
        //console.log(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
        if (message === 'webapi Context creation failed: jwt must be provided') return null;

        persistLogError(client, 'App', `Apollo.onError.${operationName}`, message);
        // return toast(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`, {
        //   type: toast.TYPE.ERROR,
        //   position: toast.POSITION.BOTTOM_CENTER,
        //   autoClose: 6000,
        //   draggable: false,
        // });
      });
    if (err.networkError) {
      if (statusCode === 400) {
        // Bad Request
        toast(`Ο σέρβερ επέστρεψε: Ζητήθηκε λάθος λειτουργία (${statusCode})`, {
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 6000,
          draggable: false,
        });
        persistLogError(client, 'App', `Apollo.onError.${operationName}`, `${err.networkError}`);
      }

      // toast(`[Network error]: ${err.networkError}`, {
      //   type: toast.TYPE.ERROR,
      //   position: toast.POSITION.BOTTOM_CENTER,
      //   autoClose: 6000,
      //   draggable: false,
      // });
      // OXI ΤΑ NETWORK ERRORS... ΘΑ ΚΑΝΕΙ LOOP
      //persistError(networkError.message);
    }
  });
  // * APOLLO make Links array
  //const links = [httpLink];
  const links = [errorLink, authMiddleware, httpLink];
  // * ApolloClient Instance
  const client = new ApolloClient({
    link: ApolloLink.from(links),
    cache: cache,
    defaultOptions: { watchQuery: { errorPolicy: 'all' }, query: { errorPolicy: 'all' } },
    connectToDevTools: true,
  });

  /*-------------------- Render ---------------------------------------------*/
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <RootStoreProvider>
          <ToastContainer style={{ width: 500 }} />
          <ModalContainer />
          <Observer>{() => appStore.initialized && <AppRoute />}</Observer>
        </RootStoreProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
};

export default App;
