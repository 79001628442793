import React from 'react';
import { Col } from 'react-bootstrap';

const FormCol = props => (
  <Col sm={6} lg={3} xl={3}>
    {props.children}
  </Col>
);

export default FormCol;
