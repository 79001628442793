import { observable, action, configure, computed } from "mobx";
import { persist } from "mobx-persist";
import { UserLogin } from "../graphql/types";
import { log } from "../services";
import { parseISO, differenceInSeconds } from "date-fns";
import { decodeJwtToken } from "share/jwt";
import {
  recyclingStationId,
  userIsCollector,
  userIsEcoElastika,
  userIsRecyclingStation,
} from "share/authorizations";

configure({ enforceActions: "always" }); // strict mode

const initialUserLogin: UserLogin = {
  expirationDate: "",
  jwt: null,
  userId: null,
  userMustChangePassword: false,
  collectorId: null,
  collector: "",
};

//------------------------------------------------------
// *    --- Functions ---
//

// *    --- Store Class ---
//
export class AppStore {
  @observable initialized = false;
  @persist("object") @observable userLogin = { ...initialUserLogin };
  @persist @observable user = "";
  @persist @observable type = "";
  @persist @observable pageSize = 10;
  @observable jwtExpireLeft = 0;
  @action setPageSize(n: number): void {
    if (n > 0) this.pageSize = n;
  }

  @action setType(s: string): void {
    this.type = s;
  }

  /**
   * Global Busy Logic
   */
  @observable private alertActivated = false;
  @observable private busyCnt = 0;
  @observable private busyReg = false;
  @observable private progressCnt = 0;
  @observable private progressTitleString = "";
  @computed get alert(): boolean {
    return this.alertActivated;
  }
  @computed get busy(): boolean {
    return this.busyCnt > 0 || this.busyReg;
  }
  @computed get progress(): number {
    return Math.ceil(this.progressCnt);
  }
  @computed get progressTitle(): string {
    return this.progressTitleString;
  }
  @action setAlert(): void {
    this.alertActivated = true;
    //log.log('[AppStore]: Busy +', this.busyCnt);
  }
  @action regBusy(value: boolean): void {
    //log.log('[AppStore]: regBusy +', value);
    this.busyReg = value;
  }
  @action setBusy(): void {
    this.busyCnt++;
    //log.log('[AppStore]: Busy +', this.busyCnt);
  }
  @action clearBusy(): void {
    this.busyCnt--;
    //log.log('[AppStore]: Busy -', this.busyCnt);
  }
  @action setProgress(value: number): void {
    this.progressCnt = value;
    //log.log('[AppStore]: Busy +', this.busyCnt);
  }
  @action setProgressTitle(value: string): void {
    this.progressTitleString = value;
    //log.log('[AppStore]: Busy +', this.busyCnt);
  }
  @action clearProgress(): void {
    this.progressCnt = 0;
    //log.log('[AppStore]: Busy -', this.busyCnt);
  }
  @action clearProgressTitle(): void {
    this.progressTitleString = "";
    //log.log('[AppStore]: Busy -', this.busyCnt);
  }

  // *
  // * --------------- Getters -------------------------------
  @computed get isLogIn(): boolean {
    return !!this.userLogin.jwt;
  }

  buildContext = function(): any {
    return { jwtToken: decodeJwtToken(this.userLogin.jwt) };
  };

  // Get RecyclingStationId
  @computed get getRsId(): Number {
    const context: any = this.buildContext();
    return recyclingStationId(context);
  }

  // User type is Eco Elastika
  @computed get isEA(): Boolean {
    const context: any = this.buildContext();
    return userIsEcoElastika(context);
  }
  // User type is collector
  @computed get isCollector(): Boolean {
    const context: any = this.buildContext();
    return userIsCollector(context);
  }
  // User type is collector driver
  @computed get isRecyclingStation(): Boolean {
    const context: any = this.buildContext();
    return userIsRecyclingStation(context);
  }

  @computed get calcJwtExpireLeft(): number {
    if (!this.isLogIn) return 0;
    return differenceInSeconds(
      parseISO(this.userLogin.expirationDate),
      new Date()
    );
  }
  // * --------------- Methods -------------------------------

  @action login(user: string, userData: UserLogin) {
    this.user = user;
    this.userLogin = { ...initialUserLogin, ...userData };
  }

  @action logOut() {
    this.user = "";
    this.type = "";
    this.alertActivated = false;
    this.userLogin = { ...initialUserLogin };
  }

  @action private setJwtExpiredLeft(): void {
    this.jwtExpireLeft = this.calcJwtExpireLeft;
    if (this.jwtExpireLeft < 0) this.logOut();
  }

  @action initialize(): void {
    this.setJwtExpiredLeft();
    this.initialized = true;
  }

  constructor() {
    this.init();
  }

  @action private init() {
    log.log(`[AppStore] init`);
    console.log(`[AppStore] init values= `, this.user, this.userLogin);
    this.user = "";
    this.userLogin = { ...initialUserLogin };
    // setInterval(() => {
    //   this.setJwtExpiredLeft();
    // }, 1000);
  }
}
