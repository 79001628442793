import React from "react";
import { FieldSelectDB } from "./common/index";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";

const gqlGet = gql`
  query getNomos {
    getNomos {
      data {
        id
        name
      }
    }
  }
`;

const FieldSelectNomoiAll = ({
  label = "Νομός",
  name = "nomos",
  defaultValues = {},
  ...rest
}: any) => {
  function useGetLazyQuery() {
    return useLazyQuery(gqlGet, {
      fetchPolicy: "cache-and-network",
    });
    //return [null, { loading: null, data: null }];
  }
  return (
    <FieldSelectDB
      {...rest}
      label={label}
      name={name}
      // @ts-ignore
      sbLabel={defaultValues?.nomos ?? undefined}
      hook={useGetLazyQuery}
      recordLabel="name"
    />
  );
};

export default FieldSelectNomoiAll;
