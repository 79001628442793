import React from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import { createModal } from 'react-modal-promise';

type Props = {
  readonly open: boolean;
  readonly close: (result: boolean) => void;
  readonly title: string;
  /** body of dialog */
  readonly text: string | React.ReactNode;
  /** accept text */
  readonly acceptText?: string;
  /** deny text */
  readonly denyText?: string;
} & ModalProps;

const ModalDialog = ({
  open,
  close,
  title,
  text,
  acceptText = 'ΝΑΙ',
  denyText = 'ΟΧΙ',
}: Props) => {
  return (
    <>
      <Modal show={open} centered backdrop="static">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{text}</Modal.Body>
        <Modal.Footer>
          <Button
            className="p-3"
            style={{ minWidth: '5rem' }}
            variant="primary"
            onClick={() => close(true)}
          >
            &nbsp;{acceptText}&nbsp;
          </Button>
          <Button
            className="p-3"
            style={{ minWidth: '5rem' }}
            variant="danger"
            onClick={() => close(false)}
          >
            &nbsp;{denyText}&nbsp;
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const promiseModalDialog = createModal(ModalDialog);

export default promiseModalDialog;
