import React, { useMemo, useCallback, useState } from "react";
import { Card, Row } from "react-bootstrap";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { gqlGetTyreCollectionsView } from "./graphql";
import {
  TablePaginated,
  FlexRow,
  FormCol,
  CellDate,
  CellStatus,
  CellFloatCustom,
} from "components/common";
import { useRootStore } from "store";
import { useHistory } from "react-router";
import { FieldInput, FormFilterCard } from "components/common/formik";
import LoadingOverlay from "react-loading-overlay";
import { useLocation } from "react-router-dom";
import { useHandleError } from "services";
import { FieldDateRanger } from "components/common/formik/field-dateranger";
import CustomSelectComponent from "components/custom-select";
import { Field } from "formik";
import exportXlsModal from "components/common/export-xls-modal";

/* ----------- Page ------------------ */
export const TyreCollectionsPage = () => {
  const { appStore } = useRootStore();
  const { handleGraphqlError } = useHandleError("tyrecollections");
  /* ----------------- Route State -------------------------- */
  const location = useLocation<any>();
  const history = useHistory();
  // Στο state του routing αποθηκεύω:
  //    1. την τρέχουσα σελίδα του table pagination (pageIndex)
  //  & 2. τα values των φίλτρων (filterValues)

  /* ------------------- Query ------------------------------- */
  const tablePageSize = appStore.pageSize;
  const [initPageIndex, setInitPageIndex] = useState(
    location?.state?.pageIndex ?? 0
  );

  const [queryParams, setQueryParams] = useState({
    offset: tablePageSize * initPageIndex,
    limit: tablePageSize,
    order: location?.state?.order ?? "CollectionIID DESC",
    where: buildWhereFromFilterValues(location?.state?.filterValues),
  });
  const { loading, data: rawData, error, refetch } = useQuery(
    gqlGetTyreCollectionsView,
    {
      variables: { ...queryParams },
      fetchPolicy: "network-only",
    }
  );
  const data = rawData?.getTyreCollectionsView?.data;
  const dataCount = rawData?.getTyreCollectionsView?.recordCount;

  /* ------------------- Διαχείριση Φίλτρων ------------------------------ */
  function buildWhereFromFilterValues(values): string {
    // Φτιάξε το where για το graphql
    let where = {};
    if (values) {
      if (values.searchText) {
        const searchText = "%" + values.searchText + "%";
        const likeObj = { $like: searchText };
        where = {
          $or: [{ $CodeId$: likeObj }],
        };
      }
      if (values.collectedStartDate && values.collectedEndDate) {
        const newCollectedEndDate = new Date(values.collectedEndDate);
        newCollectedEndDate.setDate(newCollectedEndDate.getDate() + 1);
        where = {
          ...where,
          CollectionDate: {
            $and: [
              { $gte: values.collectedStartDate },
              { $lt: newCollectedEndDate },
            ],
          },
        };
      } else if (
        values.collectedStartDate &&
        values.collectedEndDate === null
      ) {
        where = {
          ...where,
          $CollectionDate$: { $gt: values.collectedStartDate },
        };
      } else if (
        values.collectedStartDate === null &&
        values.collectedEndDate
      ) {
        const newCollectedEndDate = new Date(values.collectedEndDate);
        newCollectedEndDate.setDate(newCollectedEndDate.getDate() + 1);
        where = { ...where, $CollectionDate$: { $lt: newCollectedEndDate } };
      }
    }
    where = {
      ...where,
      CollectorId: appStore.userLogin.collectorId,
      Status: 2,
    };
    return JSON.stringify(where);
  }

  const filtersChanged = useCallback(
    (values) => {
      console.log("filtersChanged", values);
      // Φτιάξε το where για το graphql
      const where = buildWhereFromFilterValues(values);
      const order = values.orderBy !== "" ? values.orderBy : undefined;
      // Σύνθεσε τα vars του graphql
      const newQueryParams = {
        ...queryParams,
        offset: 0,
        order: order,
        where: where,
      };
      setQueryParams(newQueryParams); // αποθήκευσε τα vars
      refetch(newQueryParams); // φερε τα δεδομένα απο τον σερβερ
      setInitPageIndex(0); // αρχικοποίησε το gridTable στην 1η σελίδα

      // Ενημέρωσε το State του Route
      history.replace(location.pathname, {
        filterValues: values,
        order: order,
        pageIndex: 0,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, location.pathname, queryParams, refetch]
  );

  /* handle Pagination */
  const gridPageChanged = useCallback(
    (pageIndex, pageSize: number) => {
      console.log("gridPageChanged", pageIndex, pageSize);
      setInitPageIndex(pageIndex);
      const newQueryParams = {
        ...queryParams,
        offset: pageSize * pageIndex,
        limit: pageSize,
      };
      setQueryParams(newQueryParams);
      refetch(newQueryParams);

      // Ενημέρωσε το State του Route
      const state = {
        ...location.state,
        pageIndex,
      };
      history.replace(location.pathname, state);
    },
    [history, location.pathname, location.state, queryParams, refetch]
  );

  const getTitle = () => {
    const search = location?.state?.filterValues?.searchText
      ? " Αναζήτηση: " + location?.state?.filterValues?.searchText
      : "";
    // eslint-disable-next-line no-useless-concat
    const title =
      appStore.userLogin.collector + " - " + " Συλλογές Ελαστικών " + search;
    return title;
  };

  function useGetTyreCollectionsView() {
    return useLazyQuery(gqlGetTyreCollectionsView);
    //return [null, { loading: null, data: null }];
  }

  const handleExport = useCallback(async () => {
    await exportXlsModal({
      getLazyQuery: useGetTyreCollectionsView,
      filterVariables: queryParams,
      tableName: "Tyre_Collections_View",
      title: getTitle(),
      columnsDef: [
        { fieldName: "CollectionIID", fieldTitle: "A/A", fieldType: "INT" },
        {
          fieldName: "CodeId",
          fieldTitle: "Αριθμός Αίτησης",
          fieldType: "INT",
        },
        { fieldName: "Year", fieldTitle: "Έτος", fieldType: "TEXT" },
        {
          fieldName: "CstPointName",
          fieldTitle: "Σημείο Συλλογής",
          fieldType: "TEXT",
        },
        {
          fieldName: "CstPointAddress",
          fieldTitle: "Διεύθυνση",
          fieldType: "TEXT",
        },
        {
          fieldName: "RequestDate",
          fieldTitle: "Ημ/νία Αίτησης",
          fieldType: "DATETIME",
        },
        {
          fieldName: "Status",
          fieldTitle: "Κατάσταση Αίτησης",
          fieldType: "STATUS",
        },
        {
          fieldName: "ColTyresWeight",
          fieldTitle: "Θεωρητικό Βάρος Συλλογής(kg)",
          fieldType: "INTqty",
        },
        {
          fieldName: "CollectionDate",
          fieldTitle: "Ημ/νία Συλλογής",
          fieldType: "DATETIME",
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  if (error) {
    handleGraphqlError("query-get", error);
    return <h3 className="text-center">{error.message}</h3>;
  } else {
    return (
      <Card className="shadow">
        <Card.Header>
          <FlexRow>
            <Card.Title className="m-0">
              Διαχείριση Αιτήσεων Συλλογής Ελαστικών
            </Card.Title>
          </FlexRow>
        </Card.Header>
        <Card.Body className="nopadding">
          {/* Φίλτρα */}
          <Filters
            onChange={filtersChanged}
            initialValues={location?.state?.filterValues ?? {}}
          />

          {/* TableGrid */}
          <LoadingOverlay
            active={loading}
            spinner
            text="Παρακαλώ περιμένετε..."
          >
            {rawData ? (
              <ContainersGrid
                data={data}
                dataCount={dataCount}
                initPageIndex={initPageIndex}
                onPageChange={gridPageChanged}
                handleExport={handleExport}
              />
            ) : (
              <div style={{ height: "200px" }}>{}</div>
            )}
            {/* {rawData && canShowImportButton() && <ImportExcel refetch={refetch} queryParams={queryParams} />} */}
          </LoadingOverlay>
        </Card.Body>
      </Card>
    );
  }
};

/* ----------- Grid ------------------ */

const ContainersGrid = ({
  data,
  dataCount,
  initPageIndex,
  onPageChange,
  handleExport,
}) => {
  //console.log(props.dataX);

  const columns = useMemo(
    () => [
      {
        /* ΚΟΛΩΝΑ ΜΕ RedEye και Click handler */
        id: "select",
        Header: "A/A",
        Cell: ({ row }) => (
          <div className="text-center">{row.original.CollectionIID}</div>
        ),
      },
      { Header: "Αριθμός Αίτησης", accessor: "CodeId" },
      { Header: "Έτος", accessor: "Year" },
      {
        Header: "Σημείο Συλλογής",
        accessor: "CstPointName",
        Cell: ({ row }) => {
          return (
            <div className="text-left">
              {row.original.CstPointName ? (
                <div>{row.original.CstPointName}</div>
              ) : null}
              {row.original.CstPointAddress ? (
                <div>{row.original.CstPointAddress}</div>
              ) : null}
            </div>
          );
        },
      },
      { Header: "Ημ/νία Αίτησης", accessor: "RequestDate", Cell: CellDate },
      { Header: "Κατάσταση Αίτησης", accessor: "Status", Cell: CellStatus },
      {
        Header: "Θεωρητικό Βάρος Συλλογής(kg)",
        accessor: "ColTyresWeight",
        Cell: CellFloatCustom(10, 1),
      },
      { Header: "Ημ/νία Συλλογής", accessor: "CollectionDate", Cell: CellDate },
    ],
    []
  );
  return (
    <TablePaginated
      title={""}
      columns={columns}
      data={data}
      dataCount={dataCount}
      // initPageIndex={initPage}
      // onSortByChange={handleSortByChange}
      onExport={handleExport}
      onPageChange={onPageChange}
      initPageIndex={initPageIndex}
    />
  );
};

/* ----------- Filters ------------------ */
const Filters = ({ onChange, initialValues }) => {
  function handleFiltersSubmit(values, { setSubmitting }) {
    setTimeout(() => {
      setSubmitting(false);
      console.log("values", values);
      onChange(values);
    }, 400);
  }
  console.log("filters-init ", initialValues);

  const options = [
    { value: "CollectionDate DESC", label: "Ημ/νία Συλλογής (Φθίνουσα)" },
    { value: "CollectionDate ASC", label: "Ημ/νία Συλλογής (Αύξουσα)" },
    { value: "ColTyresWeight DESC", label: "Θεωρητικό Βάρος (Φθίνουσα)" },
    { value: "ColTyresWeight ASC", label: "Θεωρητικό Βάρος (Αύξουσα)" },
  ];

  const filters = {
    orderBy: initialValues?.orderBy ?? "",
    collectedStartDate: initialValues?.collectedStartDate ?? null,
    collectedEndDate: initialValues?.collectedEndDate ?? null,
    searchText: initialValues?.searchText ?? "",
  };

  return (
    <FormFilterCard
      title="Κριτήρια Αναζήτησης"
      onSubmit={handleFiltersSubmit}
      initialValues={{
        orderBy: filters?.orderBy,
        collectedStartDate: filters?.collectedStartDate,
        collectedEndDate: filters?.collectedEndDate,
        searchText: filters?.searchText,
      }}
    >
      <Row>
        <FieldInput
          as={FormCol}
          name="searchText"
          label="Αναζήτηση (Αριθμός Αίτησης)"
          type="search"
        />
        <FieldDateRanger
          as={FormCol}
          label="Συλλογή από"
          name="collectedStartDate"
          name2="collectedEndDate"
        />
        <Field
          name={"orderBy"}
          title="Ταξινόμηση ανά"
          component={CustomSelectComponent}
          options={options}
        />
      </Row>
    </FormFilterCard>
  );
};
