import React, { ComponentProps } from 'react';
import { Button } from 'react-bootstrap';
import { FaTimesCircle } from 'react-icons/fa';

type Props = React.PropsWithoutRef<ComponentProps<typeof Button>> & {};

const ButtonClear = (props: Props) => {
  return (
  <Button {...props} style={{ width: '160px' }} variant="danger" onClick={props.onClick}>
    <FaTimesCircle className="mb-1" /> {"Καθάρισμα"}
  </Button>
);
}
export default React.memo(ButtonClear);
