import { FieldLabel, FlexRow } from "../index";
import { parseISO } from "date-fns";
import { isValid } from "date-fns/esm";
import { FieldAttributes, useField, useFormikContext } from "formik";
import React, { forwardRef, useState } from "react";
import { Button, Form, FormControl, InputGroup, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  FaRegCalendarAlt,
  FaChevronRight,
  FaChevronLeft,
} from "react-icons/fa";
import "./field-dateranger.css";
import moment from "moment";

//@ts-ignore
//
//-------------------- Custom Input -------------------------------------------
//
const InputWithButton = forwardRef(
  // @ts-ignore
  ({ required, touched, error, disabled, onClick, ...rest }, ref) => {
    return (
      <InputGroup style={{ flex: 1 }}>
        <FormControl
          style={
            required && !disabled ? { backgroundColor: "lightYellow" } : {}
          }
          {...rest}
          onClick={() => false}
          isInvalid={touched && !!error}
          disabled={disabled}
        />
        <InputGroup.Append>
          <Button
            variant="primary"
            className="react-datepicker-ignore-onclickoutside btn-dark"
            style={{
              paddingTop: "0.1rem",
              paddingBottom: "0.1rem",
              paddingLeft: "0.2rem",
              paddingRight: "0.2rem",
              borderTopRightRadius: "0.3rem",
              borderBottomRightRadius: "0.3rem",
              backgroundColor: "#aeaeae",
              borderColor: "#ced4da",
            }}
            // @ts-ignore
            //disabled={disabled}
            // @ts-ignore
            onClick={onClick}
          >
            <FaRegCalendarAlt size="1rem" style={{ marginBottom: 1 }} />
          </Button>
        </InputGroup.Append>
        {/*
       // @ts-ignore   */}
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </InputGroup>
    );
  }
);

//---------------------------------------------------------------------------
type Props = {
  as?: React.ElementType<any>;
  label: string;
  placeholder?: string;
  dateFormat?: string;
  disabled?: boolean;
  required?: boolean;
  maxDate?: Date;
  minDate?: Date;
  onSubmit?: any;
} & FieldAttributes<any>;

const FieldDateRanger = ({
  as = "div",
  name,
  name2,
  label,
  placeholder = "",
  dateFormat = "dd/MM/yyyy",
  disabled = false,
  required = false,
  maxDate,
  minDate,
  onSubmit,
}: Props) => {
  //
  //-------------------- Body -----------------------------------------------
  //
  const [{ value }, { error, initialValue, touched }] = useField<Date>(name);
  const [{ value: value2 }, { error: error2, touched: touched2 }] = useField<
    Date
  >(name2);
  const { setFieldValue, setFieldTouched } = useFormikContext<any>();
  const {
    setFieldValue: setFieldValue2,
    setFieldTouched: setFieldTouched2,
  } = useFormikContext<any>();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  // const [actualValue, setActualValue] = useState<Date>(typeof value === 'string' ? parseISO(value) : value);

  // useEffect(() => {
  //   console.log('Value changed:', value, value !== actualValue);
  //   if (value !== actualValue) {
  //     setActualValue(typeof value === 'string' ? parseISO(value) : value);
  //   }
  // }, [value]); // value  only

  //
  //-------------------- Handlers -------------------------------------------
  //
  const setFieldValueAndTouched = async (
    _value: Date | null,
    touched: boolean
  ) => {
    if (isValid(_value)) {
      await setFieldValue(name, _value, true); //field,value,shouldValidate
    } else {
      await setFieldValue(name, null, true); //field,value,shouldValidate
    }
    await setFieldTouched(name, touched, false); //field,touched,shouldValidate
  };

  const handleChange = (date: Date) => {
    // setActualValue(date);
    setFieldValueAndTouched(date, true);
  };

  const handleBlur = () => {
    setFieldValueAndTouched(value, true);
  };
  const changed = value !== initialValue;

  const setFieldValueAndTouched2 = async (
    _value2: Date | null,
    touched2: boolean
  ) => {
    if (isValid(_value2)) {
      await setFieldValue2(name2, _value2, true); //field,value,shouldValidate
    } else {
      await setFieldValue2(name2, null, true); //field,value,shouldValidate
    }
    await setFieldTouched2(name2, touched2, false); //field,touched,shouldValidate
  };

  const handleChange2 = (date: Date) => {
    // setActualValue(date);
    setFieldValueAndTouched2(date, true);
  };

  const handleBlur2 = () => {
    setFieldValueAndTouched2(value2, true);
  };

  const handlePeriodClick = (direction: "next" | "previous") => {
    // Μετάβαση σε Επόμενο ή Προηγούμενο Χρονικό Διάστημα
    let fromDate: Date = value;
    let tillDate: Date = value2;
    if (fromDate === null && tillDate === null) {
      // Αρχικοποίηση στο σήμερα
      fromDate = moment()
        .startOf("day")
        .toDate();
      tillDate = moment()
        .endOf("day")
        .toDate();
    } else if (fromDate !== null && tillDate !== null) {
      // keep date only & disgard time
      fromDate = new Date(
        value.getFullYear(),
        value.getMonth(),
        value.getDate()
      );
      tillDate = new Date(
        value2.getFullYear(),
        value2.getMonth(),
        value2.getDate()
      );

      // Περίοδος = 1 ημέρα
      if (tillDate.valueOf() === fromDate.valueOf()) {
        // Μετακινήσου 1 ημέρα
        const days2add = direction === "next" ? 1 : -1;
        fromDate.setDate(fromDate.getDate() + days2add);
        tillDate = fromDate;
      } else {
        // Περίοδος = x Μήνες
        const monthsDiff = moment(tillDate)
          .add(1, "days")
          .diff(moment(fromDate), "months", true);
        if (Number.isInteger(monthsDiff)) {
          if (direction === "next") {
            fromDate = moment(tillDate)
              .add(1, "days")
              .toDate();
            tillDate = moment(fromDate)
              .add(monthsDiff, "months")
              .add(-1, "days")
              .toDate();
          } else if (direction === "previous") {
            tillDate = moment(fromDate)
              .add(-1, "days")
              .toDate();
            fromDate = moment(fromDate)
              .add(-monthsDiff, "months")
              .toDate();
          }
        }
        // Περίοδος = x Εβδομάδες
        else {
          // diff in days
          const msDiff = tillDate.valueOf() - fromDate.valueOf();

          if (direction === "next") {
            tillDate.setDate(tillDate.getDate() + 1);
            fromDate = tillDate;
            tillDate = new Date(tillDate.valueOf() + msDiff);
          } else if (direction === "previous") {
            fromDate.setDate(fromDate.getDate() - 1);
            tillDate = fromDate;
            fromDate = new Date(fromDate.valueOf() - msDiff);
          }
        }
      }
    }
    setFieldValue(name, fromDate, false);
    setFieldValue(name2, tillDate, false);
    if (onSubmit) {
      onSubmit({ fromDate: fromDate, tillDate: tillDate });
    }
  };

  // useWhyDidYouUpdate('[FieldDatePicker]', { name, value, error, initialValue, touched, actualValue });
  //
  //-------------------- Render -------------------------------------------
  //
  return (
    <Form.Group as={as} className="mb-2" controlId={name}>
      <FlexRow>
        <Col style={{ paddingLeft: "0px" }}>
          <FieldLabel text={label} changed={changed} required={required} />

          <DatePicker
            todayButton="Σήμερα"
            autoComplete="off"
            preventOpenOnFocus
            onCalendarOpen={() => setOpen(true)}
            onCalendarClose={() => setOpen(false)}
            placeholderText={placeholder}
            dateFormat={dateFormat}
            selected={typeof value === "string" ? parseISO(value) : value}
            //@ts-ignore
            customInput={
              //@ts-ignore
              <InputWithButton name={name} touched={touched} error={error} />
            }
            onChange={handleChange}
            onBlur={handleBlur}
            isClearable
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            disabled={disabled || open2}
            required={required}
            minDate={minDate}
            maxDate={maxDate}
            selectsStart
            startDate={typeof value === "string" ? parseISO(value) : value}
            endDate={typeof value2 === "string" ? parseISO(value2) : value2}
            // required={props.required}
          />
        </Col>
        <Col style={{ paddingRight: "0px" }}>
          <FieldLabel text={"Έως"} changed={changed} required={required} />
          <DatePicker
            todayButton="Σήμερα"
            autoComplete="off"
            preventOpenOnFocus
            onCalendarOpen={() => setOpen2(true)}
            onCalendarClose={() => setOpen2(false)}
            placeholderText={placeholder}
            dateFormat={dateFormat}
            selected={typeof value2 === "string" ? parseISO(value2) : value2}
            //@ts-ignore
            customInput={
              //@ts-ignore
              <InputWithButton name={name2} touched={touched2} error={error2} />
            }
            popperModifiers={{
              offset: {
                enabled: true,
                offset: "5px, 10px",
              },
              preventOverflow: {
                enabled: true,
                escapeWithReference: false,
                boundariesElement: "viewport",
              },
            }}
            onChange={handleChange2}
            onBlur={handleBlur2}
            isClearable
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            disabled={disabled || open}
            required={required}
            minDate={typeof value === "string" ? parseISO(value) : value}
            maxDate={maxDate}
            endDate={typeof value2 === "string" ? parseISO(value2) : value2}
            // required={props.required}
          />
        </Col>
        <Col xs="auto" style={{ paddingRight: "0px", paddingTop: "0.8rem" }}>
          {/* priorPeriod */}
          <Button
            type="button"
            className="btn btn-secondary"
            style={{
              marginRight: "3px",
              backgroundColor: "#aeaeae",
              borderColor: "#aeaeae",
              height: "1.4rem",
              width: "1.4rem",
              paddingLeft: "4px",
              paddingRight: "4px",
            }}
            onClick={(e) => {
              handlePeriodClick("previous");
            }}
          >
            <FaChevronLeft size="0.8rem" style={{ marginBottom: 1 }} />
          </Button>
          {/* nextPeriod */}
          <Button
            type="button"
            className="btn btn-secondary"
            style={{
              marginRight: "0px",
              backgroundColor: "#aeaeae",
              borderColor: "#aeaeae",
              height: "1.4rem",
              width: "1.4rem",
              paddingLeft: "4px",
              paddingRight: "4px",
            }}
            onClick={(e) => {
              handlePeriodClick("next");
            }}
          >
            <FaChevronRight size="0.8rem" style={{ marginBottom: 1 }} />
          </Button>
        </Col>
      </FlexRow>
    </Form.Group>
  );
};

function buildWhereForDates(
  startDate: string,
  endDate: string,
  fieldName: string
) {
  /*eslint-disable*/
  let where: any = {};

  // remove time from start-date
  const newStartDate = new Date(startDate);
  newStartDate.setHours(0, 0, 0, 0); // remove time

  // add 24 hours to end-date
  const newEndDate = new Date(endDate);
  newEndDate.setHours(0, 0, 0, 0); // remove time
  newEndDate.setDate(newEndDate.getDate() + 1); // add 24 hrs

  if (startDate && endDate) {
    where[fieldName] = { $and: [{ $gte: newStartDate }, { $lt: newEndDate }] };
  } else if (startDate && (!endDate || endDate === null)) {
    where[fieldName] = { $gte: newStartDate };
  } else if ((!startDate || startDate === null) && endDate) {
    where[fieldName] = { $lt: newEndDate };
  }

  return where;
}

export { FieldDateRanger, buildWhereForDates };
