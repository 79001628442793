import React, { ComponentProps } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaThumbsDown } from 'react-icons/fa';

type Props = React.PropsWithoutRef<ComponentProps<typeof Button>> & {
  loading?: boolean;
};

const ButtonReject = ({ loading = false, ...props }: Props) => (
  <Button {...props} variant="danger" type="button" onClick={props.onClick}>
    {loading ? <Spinner animation="border" size="sm" className="mb-1" /> : <FaThumbsDown className="mb-1" />}
    {' Απόρριψη'}
  </Button>
);
export default React.memo(ButtonReject);
