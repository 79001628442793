import React from 'react';
import { ButtonSearch, ButtonClear } from './../index';

type Props = {
  isDirty?: boolean;
  isValid?: boolean;
  onSearch?: () => void;
  onClear?: () => void;
};

const FormFiltersButtonsSm = ({ isDirty = true, isValid = true, onSearch = () => {}, onClear = () => {} }: Props) => (
  <div>
    <ButtonSearch disabled={!isValid} onClick={onSearch} size="sm" className="mr-2" />
    <ButtonClear disabled={!isDirty} onClick={onClear} size="sm" />
  </div>
);

export default React.memo(FormFiltersButtonsSm);
