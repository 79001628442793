import React, { FC, ComponentProps } from 'react';
import { Button } from 'react-bootstrap';
import { FaArrowAltCircleLeft } from 'react-icons/fa';

type Props = ComponentProps<typeof Button> & {};

const ButtonBack: FC<Props> = props => (
  <Button {...props} style={{ width: '160px' }} variant="primary" onClick={props.onClick}>
    <span>
      <FaArrowAltCircleLeft className="mb-1" /> Επιστροφή
    </span>
  </Button>
);

export default ButtonBack;
