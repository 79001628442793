import gitnum from '../gitnum';
import React from 'react';
import { Navbar } from 'react-bootstrap';
import * as app from '../../package.json';

//type Props = {};

const Footer = () => (
  <footer>
    <Navbar className="justify-content-between mb-1" bg="dark" variant="dark" fixed="bottom">
      <Navbar.Text className="p-0">
        <small>
          <a href="https://ecoelastika.gr/" target="_blank" rel="noopener noreferrer">
            ECOELASTIKA
          </a>{' '}
          {'\u00A92020'}
        </small>
      </Navbar.Text>
      <Navbar.Text className="p-0">
        <small>
          created by{' '}
          <a href="http://www.motion.gr" target="_blank" rel="noopener noreferrer">
            Motion Hellas Ltd.
          </a> {'  '}
          <span style={{ color: 'white' }}>
            {'  '}Έκδοση {app.version + '-' + gitnum.git}
          </span>
        </small>
      </Navbar.Text>
    </Navbar>
  </footer>
);

export default Footer;
