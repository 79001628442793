import React, { FC } from 'react';
import FormFilterHeader from './form-filter-header';
import { Card, Form } from 'react-bootstrap';
import { Formik, Form as FForm, FormikConfig } from 'formik';

type Props<T = { [key: string]: any }> = FormikConfig<T> & {
  title: string;
  onClear?: any;
  disableClear?: boolean;
  disableSubmit?: boolean;
};

const FormFilterCard: FC<Props> = ({ onClear, title, children = null, disableClear, disableSubmit, ...rest }) => {
  return (
    <Card bg="light" className="rounded-0">
      <Formik {...rest}>
        <Form as={FForm}>
          <FormFilterHeader disableSubmit={disableSubmit} disableClear={disableClear} onClear={onClear} title={title} />
          <Card.Body className="py-2">{children}</Card.Body>
        </Form>
      </Formik>
    </Card>
  );
};

export default FormFilterCard;
