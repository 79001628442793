import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from "react";
import { Button, Card, Row, Tab, Tabs } from "react-bootstrap";
import {
  FormCol,
  CellFloatCustom,
  CellIntCustom,
  CellPercent,
  CellDate,
  ButtonRedEye,
  TablePaginated,
  CellActive,
} from "components/common";
import LoadingOverlay from "react-loading-overlay";
import { useRootStore } from "store";
import { useLazyQuery } from "@apollo/react-hooks";
import { useLocation, useHistory, generatePath } from "react-router-dom";
import { FormFilterCard } from "components/common/formik";
import TableSubSimple from "components/common/table-sub-simple";
import { FieldDateRanger } from "components/common/formik/field-dateranger";
import moment from "moment";
import {
  gqlGetRsCapacities,
  gqlGetRsCapacityExceptions,
  gqlGetRsCollectors,
} from "./graphql";
import FieldSelectRecyclingStation from "pages/reports/rs-deliveries/fs-recycling-stations";
import { getRouteByName } from "app/routes";
import { FaPlus } from "react-icons/fa";
import "../calendar/tab-styles.css";
interface FilterParams {
  recyclingStationId: number;
}
const initFilters: FilterParams = {
  recyclingStationId: undefined,
};

export const CapacitiesAndExceptionsPage = () => {
  const location = useLocation<any>();
  const history = useHistory();
  const { appStore } = useRootStore();
  const [key, setKey] = useState("capacity");
  const [routeFilterValues] = useState(
    location?.state?.filterValues ?? initFilters
  );
  const [
    getRsCapacities,
    { data: rawDataRsCapacities, loading: loadingRsCapacities },
  ] = useLazyQuery(gqlGetRsCapacities, { fetchPolicy: "network-only" });
  const [
    getRsExceptions,
    { data: rawDataRsExceptions, loading: loadingRsExceptions },
  ] = useLazyQuery(gqlGetRsCapacityExceptions, { fetchPolicy: "network-only" });
  const [
    getRsCollectors,
    { data: rawDataRsCollectors, loading: loadingRsCollectors },
  ] = useLazyQuery(gqlGetRsCollectors, { fetchPolicy: "network-only" });

  const dataRsCapacities = rawDataRsCapacities
    ? rawDataRsCapacities.getRsCapacities.data
    : undefined;

  const dataRsExceptions = rawDataRsExceptions
    ? rawDataRsExceptions.getRsCapacityExceptions.data
    : undefined;

  const dataRsCollectors = rawDataRsCollectors
    ? rawDataRsCollectors.getRsCollectors.data
    : undefined;

  const fetchData = useCallback(
    async (params: FilterParams) => {
      let RsId = params?.recyclingStationId ?? initFilters.recyclingStationId;
      getRsCapacities({ variables: { where: JSON.stringify({ RsId: RsId }) } });
      getRsExceptions({ variables: { where: JSON.stringify({ RsId: RsId }) } });
      getRsCollectors({ variables: { where: JSON.stringify({ RsId: RsId }) } });
    },
    [getRsCapacities, getRsExceptions, getRsCollectors]
  );

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      const filterParams = location?.state?.filterValues ?? {};
      fetchData(filterParams as FilterParams);
    }
  });

  const filtersChanged = useCallback(
    (values) => {
      //fetchData(values.collectorId);
      // Ενημέρωσε το State του Route => triggers page refresh
      history.replace(location.pathname, { filterValues: values });
    },
    [history, location.pathname]
  );

  const capRowClicked = useCallback(
    (row) => {
      history.push({
        pathname: generatePath(getRouteByName("RsCapacity"), {
          id: row.original.id,
        }),
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state]
  );

  const columnsRsCapacities = useMemo(
    () => [
      {
        /* ΚΟΛΩΝΑ ΜΕ RedEye και Click handler */
        id: "select",
        Header: () => <div className="align-middle"></div>,
        Cell: ({ row }) => (
          <div className="text-center">
            <ButtonRedEye onClick={() => capRowClicked(row)} />
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: "Σταθμός Ανακύκλωσης",
        accessor: "RecyclingStation.name",
      },
      {
        Header: "Δευτέρα",
        accessor: "Day2",
        Cell: CellFloatCustom(10, 1),
      },
      {
        Header: "Τρίτη",
        accessor: "Day3",
        Cell: CellFloatCustom(10, 1),
      },
      {
        Header: "Τετάρτη",
        accessor: "Day4",
        Cell: CellFloatCustom(10, 1),
      },
      {
        Header: "Πέμπτη",
        accessor: "Day5",
        Cell: CellFloatCustom(10, 1),
      },
      {
        Header: "Παρασκευή",
        accessor: "Day6",
        Cell: CellFloatCustom(10, 1),
      },
      {
        Header: "Σάββατο",
        accessor: "Day7",
        Cell: CellFloatCustom(10, 1),
      },
      {
        Header: "Κυριακή",
        accessor: "Day1",
        Cell: CellFloatCustom(10, 1),
      },
      {
        Header: "Χρώμα",
        accessor: "RsColor",
      },
    ],
    []
  );

  const expRowClicked = useCallback(
    (row) => {
      history.push({
        pathname: generatePath(getRouteByName("RsException"), {
          id: row.original.id,
        }),
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state]
  );

  const columnsRsExceptions = useMemo(
    () => [
      {
        /* ΚΟΛΩΝΑ ΜΕ RedEye και Click handler */
        id: "select",
        Header: () => <div className="align-middle"></div>,
        Cell: ({ row }) => (
          <div className="text-center">
            <ButtonRedEye onClick={() => expRowClicked(row)} />
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: "Σταθμός Ανακύκλωσης",
        accessor: "RecyclingStation.name",
      },

      {
        Header: "Ημερομηνία",
        accessor: "ExceptionDate",
        Cell: CellDate,
      },
      {
        Header: "Ημερομηνία Μέχρι",
        accessor: "ExceptionDateTo",
        Cell: CellDate,
      },
      {
        Header: "Ποσότητα",
        accessor: "CapacityWeight",
        Cell: CellFloatCustom(10, 1),
      },
      {
        Header: "Ενεργό",
        accessor: "IsActive",
        Cell: CellActive,
      },
    ],
    []
  );

  const handleCreateNewException = useCallback(() => {
    history.push({
      pathname: generatePath(getRouteByName("RsException"), { id: -1 }),
      state: {
        pathname: location.pathname,
        ...location.state,
      },
    });
  }, [history, location.pathname, location.state]);

  const colRowClicked = useCallback(
    (row) => {
      history.push({
        pathname: generatePath(getRouteByName("RsCollector"), {
          id: row.original.id,
        }),
        state: {
          pathname: location.pathname,
          ...location.state,
        },
      });
    },
    [history, location.pathname, location.state]
  );

  const columnsRsCollectors = useMemo(
    () => [
      {
        /* ΚΟΛΩΝΑ ΜΕ RedEye και Click handler */
        id: "select",
        Header: () => <div className="align-middle"></div>,
        Cell: ({ row }) => (
          <div className="text-center">
            <ButtonRedEye onClick={() => colRowClicked(row)} />
          </div>
        ),
        disableSortBy: true,
      },
      {
        Header: "Σταθμός Ανακύκλωσης",
        accessor: "RecyclingStation.name",
      },

      {
        Header: "Συλλέκτης",
        accessor: "Collector.Name",
      },

      {
        Header: "Ενεργό",
        accessor: "IsActive",
        Cell: CellActive,
      },
    ],
    []
  );

  const handleCreateNewRsCollector = useCallback(() => {
    history.push({
      pathname: generatePath(getRouteByName("RsCollector"), { id: -1 }),
      state: {
        pathname: location.pathname,
        ...location.state,
      },
    });
  }, [history, location.pathname, location.state]);

  /* console.log(dataToRender); */
  return (
    <>
      <Card className="shadow">
        <Card.Header>
          <Card.Title className="m-0">Διαχείριση Σταθμών</Card.Title>
        </Card.Header>

        {/* Φίλτρα */}
        <Filters
          fetchData={fetchData}
          onChange={filtersChanged}
          initialValues={routeFilterValues}
        />
        {/* TableGrid */}
        <LoadingOverlay
          active={loadingRsExceptions || loadingRsCapacities}
          spinner
          text="Παρακαλώ περιμένετε..."
        >
          <Tabs id="management" activeKey={key} onSelect={(k) => setKey(k)}>
            <Tab eventKey="capacity" title={"Δυναμικότητα Απορρόφησης"}>
              {!loadingRsCapacities && dataRsCapacities ? (
                <TableSubSimple
                  title=""
                  columns={columnsRsCapacities}
                  data={dataRsCapacities}
                />
              ) : (
                <div style={{ height: "200px" }}></div>
              )}
            </Tab>
            <Tab eventKey="exceptions" title={"Εξαιρέσεις"}>
              {!loadingRsExceptions && dataRsExceptions ? (
                <>
                  <div className="d-flex flex-row justify-content-end mr-2">
                    <Button
                      size="lg"
                      className={"rounded-pill"}
                      variant="success"
                      onClick={handleCreateNewException}
                      data-toggle="tooltip"
                      data-placement="left"
                    >
                      <FaPlus className={`mb-1 mr-2`} />
                      Καινούργια Εξαίρεση
                    </Button>
                  </div>
                  <br />
                  <TableSubSimple
                    title=""
                    columns={columnsRsExceptions}
                    data={dataRsExceptions}
                  />
                </>
              ) : (
                <div style={{ height: "200px" }}></div>
              )}
            </Tab>
            <Tab eventKey="collectors" title={"Συνδέσεις με Συλλέκτες"}>
              {!loadingRsCollectors && dataRsCollectors ? (
                <>
                  <div className="d-flex flex-row justify-content-end mr-2">
                    <Button
                      size="lg"
                      className={"rounded-pill"}
                      variant="success"
                      onClick={handleCreateNewRsCollector}
                      data-toggle="tooltip"
                      data-placement="left"
                    >
                      <FaPlus className={`mb-1 mr-2`} />
                      Καινούργια Σύνδεση
                    </Button>
                  </div>
                  <br />
                  <TableSubSimple
                    title=""
                    columns={columnsRsCollectors}
                    data={dataRsCollectors}
                  />
                </>
              ) : (
                <div style={{ height: "200px" }}></div>
              )}
            </Tab>
          </Tabs>
        </LoadingOverlay>
      </Card>
    </>
  );
};

/* ----------- Filters Component ------------------ */
const Filters = ({ fetchData, onChange, initialValues }) => {
  //
  const handleFiltersSubmit = useCallback(
    (values, { setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
        console.log("values", values);
        onChange(values);
      }, 400);
    },
    [onChange]
  );

  return (
    <FormFilterCard
      disableClear={true}
      title="Κριτήρια Αναζήτησης"
      onSubmit={handleFiltersSubmit}
      initialValues={initialValues}
    >
      <Row>
        <FieldSelectRecyclingStation
          where={JSON.stringify({ isNotActive: false })}
          as={FormCol}
          name="recyclingStationId"
          label="Σταθμός Ανακύκλωσης"
          //defaultValues={!!data ? data[0] : undefined}
        />
      </Row>
    </FormFilterCard>
  );
};
