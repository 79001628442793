import React from "react";
import { Modal, Button, ModalProps, Form } from "react-bootstrap";
import { createModal } from "react-modal-promise";
import { Formik, Form as FForm } from "formik";
import { toastWarning } from "utils/toasts";
import { FieldDatepicker, FieldNumericInput } from "./common";
import FieldSelectCollector from "./fs-collectors";
import { useRootStore } from "store";
import FieldSelectRsCollector from "./fs-rscollectors";
import FieldSelectRecyclingStation from "pages/reports/rs-deliveries/fs-recycling-stations";

type Props = {
  open: boolean;
  close: (result: any) => void;
  title: string;
  /** body of dialog */
  text?: string | React.ReactNode;
  /** accept text */
  acceptText?: string;
  /** deny text */
  denyText?: string;
  type?: string;
  collectorId?: number;
  rsId?: Number;
} & ModalProps;

const ModalInput = ({
  open,
  close,
  title,
  text,
  acceptText = "OK",
  denyText = "CANCEL",
  type = "text",
  collectorId = null,
  rsId,
}: Props) => {
  const { appStore } = useRootStore();
  const handleOk = (values) => {
    if (values.RsId === "") {
      toastWarning(`Το πεδίο Σταθμός Ανακύκλωσης είναι υποχρεωτικό`);
    } else if (values.CollectorId === "") {
      toastWarning(`Το πεδίο Συλλέκτης είναι υποχρεωτικό`);
    } else if (values.PlannedDate === null) {
      toastWarning(`Το πεδίο Ημερομηνία είναι υποχρεωτικό`);
    } else if (values.PlannedWeight === "") {
      toastWarning(`Το πεδίο Βάρος είναι υποχρεωτικό`);
    } else {
      const input = {
        RsId: values.RsId,
        PlannedDate: values.PlannedDate,
        PlannedWeight: values.PlannedWeight,
        CollectorId: values.CollectorId,
      };
      close(input);
    }
  };
  return (
    <>
      <Modal show={open} centered backdrop="static">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            RsId: rsId,
            PlannedDate: null,
            PlannedWeight: null,
            CollectorId: collectorId,
          }}
          onSubmit={(values) => {
            handleOk(values);
          }}
        >
          <Form as={FForm}>
            <Modal.Body>
              <br />
              {!appStore.isCollector && (
                <FieldSelectCollector required name="CollectorId" />
              )}
              {appStore.isCollector ? (
                <FieldSelectRsCollector
                  where={JSON.stringify({
                    CollectorId: appStore.userLogin.collectorId,
                  })}
                  required
                  name="RsId"
                />
              ) : (
                <FieldSelectRecyclingStation required name="RsId" />
              )}
              <FieldDatepicker
                required
                minDate={new Date()}
                label={"Ημερομηνία"}
                name="PlannedDate"
              />
              <FieldNumericInput
                required
                label="Βάρος (ΤΝ)"
                name="PlannedWeight"
              />
            </Modal.Body>
            <Modal.Footer>
              {/*  <Button variant="danger" onClick={() => close(false)}>
            {denyText}
          </Button> */}
              <Button variant="primary" type="submit">
                {acceptText}
              </Button>
              <Button variant="danger" onClick={() => close(false)}>
                {denyText}
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

const promiseModalSelectCalendarInput = createModal(ModalInput);

export default promiseModalSelectCalendarInput;
