import { GridHeader, GridFooter, GridGlobalFilter } from '.';
import React, { useCallback, useEffect } from 'react';
import { Card, Table } from 'react-bootstrap';
import {
  Cell,
  Column,
  Row,
  SortingRule,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useGlobalFilter,
} from 'react-table';
import { useRootStore } from '../../store';
import { QueryLazyOptions } from '@apollo/react-hooks';
import { useWhyDidYouUpdate } from '../../hooks';
import LoadingOverlay from 'react-loading-overlay';

const defTableProps = { striped: true, hover: true, bordered: true, className: 'mb-0' };
const defHeaderGroupProps = () => ({ className: 'text-center' });
const defHeaderProps = () => ({ className: 'align-middle' });
const defFooterGroupProps = () => ({ className: 'text-center' });
const defFooterProps = () => ({ className: 'align-middle' });

type Props<D extends object = {}> = {
  title?: string;
  columns: Array<Column<D>>;
  data: D[];
  propPageSize?: number;
  initPageIndex?: number;
  get?: (options?: QueryLazyOptions<any>) => void;
  filterVariables?: Record<string, any>;
  hasFooter?: boolean;
  hasSearch?: boolean;
  hasPdf?: any;
  tableProps?: object;
  bodyProps?: object;
  headerGroupProps?: (headerGroup) => object;
  footerGroupProps?: (footerGroup) => object;
  headerProps?: (column: Column<D>) => object;
  footerProps?: (column: Column<D>) => object;
  rowProps?: (row: Row<D>) => object;
  cellProps?: (cell: Cell<D>) => object;
  onSelectedRowsChange?: (rows: Array<Row<D>>) => void;
  onPageChange?: (page: number) => void;
  onSortByChange?: (sort: SortingRule<D>[]) => void;
  onRefresh?: () => void;
  onCreate?: () => void;
  onExport?: () => void;
  onNew?: () => void;
};

function TableInMem<D extends object = {}>({
  title = '',
  columns,
  data,
  propPageSize = null,
  initPageIndex = 0,
  get = null,
  filterVariables = {},
  hasFooter = false,
  hasSearch = false,
  hasPdf = null,
  onSelectedRowsChange = null,
  onPageChange = null,
  onSortByChange = null,
  onRefresh = null,
  onCreate = null,
  onExport = null,
  onNew = null,
  tableProps = defTableProps,
  headerGroupProps = defHeaderGroupProps,
  headerProps = defHeaderProps,
  footerGroupProps = defFooterGroupProps,
  footerProps = defFooterProps,
  bodyProps = null,
  rowProps = null,
  cellProps = null,
}: Props<D>) {
  //
  //-------------------- Body -----------------------------------------------
  //
  const rootStore = useRootStore();
  const actualPageSize = !!propPageSize ? propPageSize : rootStore.appStore.pageSize;
  const controlledPageCount = ~~((data.length - 1) / actualPageSize) + 1;
  /**
   * * useTable : Main Table Machine
   */
  const tableInstance = useTable<D>(
    {
      columns,
      data,
      initialState: { pageIndex: initPageIndex, pageSize: actualPageSize }, // Pass our hoisted table state
      pageCount: controlledPageCount,
    },
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    rows,
    pageCount,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
  } = tableInstance;

  /**
   * * 1. Effect : ΕΚΤΕΛΕΙ ΤΟ get αν υπάρχει
   */
  useEffect(() => {
    const variables = { ...filterVariables };
    if (get !== null) {
      console.log('[TableInMem] Effect Query:', variables);
      get({ variables });
    }
  }, [filterVariables, get]);

  /**
   * * 2. Effect : ΕΚΤΕΛΕΙ ΤΟ gotoPage οταν Αλλάξει το initPageIndex
   */
  useEffect(() => {
    gotoPage(initPageIndex);
  }, [gotoPage, initPageIndex]);

  /**
   * * 3. Effect : ΕΚΤΕΛΕΙΤΑΙ όταν αλλάξει το selectedFlatRows
   */
  useEffect(() => {
    onSelectedRowsChange && onSelectedRowsChange(selectedFlatRows);
  }, [onSelectedRowsChange, selectedFlatRows]);

  /**
   * * 4. Effect : ΕΚΤΕΛΕΙΤΑΙ όταν αλλάξει το sortBy (Δεν χρειάζεται στο mem)
   */
  //useEffect(() => {
  //console.log('[TableInMem] onSortByChange', sortBy);
  //onSortByChange && onSortByChange(sortBy);
  //}, [onSortByChange, sortBy]);

  //
  //-------------------- Handlers -------------------------------------------
  //
  const handlePageChange = useCallback(
    pageNo => {
      gotoPage(pageNo);
      onPageChange && onPageChange(pageNo);
    },
    [gotoPage, onPageChange],
  );

  const handlePageSizeChange = useCallback(
    (n: number) => {
      rootStore.appStore.setPageSize(n);
      setPageSize(n);
    },
    [rootStore.appStore, setPageSize],
  );
  //
  //-------------------- Render ----------------------------------------------
  //
  useWhyDidYouUpdate('[TableInMem]', { get, filterVariables, onSelectedRowsChange, selectedFlatRows });
  return (
    <Card className="shadow">
      {title !== '' && <GridHeader title={title} onRefresh={onRefresh} onCreate={onCreate} />}
      <Card.Body className="py-2">
        {hasSearch && <GridGlobalFilter value={globalFilter} onChange={setGlobalFilter} />}
        <LoadingOverlay active={!data} spinner text="Παρακαλώ περιμένετε...">
          <Table {...getTableProps(tableProps || {})}>
            <thead className="thead-dark">
              {headerGroups.map(headerGroup => (
                <tr
                  {...headerGroup.getHeaderGroupProps(
                    (headerGroupProps && headerGroupProps(headerGroup)) || {}
                  )}
                >
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps(
                        column.getSortByToggleProps((headerProps && headerProps(column)) || {}),
                      )}
                    >
                      <span>
                        {column.render('Header')}
                        {/* Add a sort direction indicator */}
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps(bodyProps || {})}>
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps((rowProps && rowProps(row)) || {})}>
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps((cellProps && cellProps(cell)) || {})}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
            {hasFooter && (
              <tfoot style={{ backgroundColor: '#e1f3f7' }}>
                {footerGroups.map(footerGroup => (
                  <tr {...footerGroup.getFooterGroupProps((footerGroupProps && footerGroupProps(footerGroup)) || {})}>
                    {footerGroup.headers.map(column => (
                      <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                    ))}
                  </tr>
                ))}
              </tfoot>
            )}
          </Table>
        </LoadingOverlay>
      </Card.Body>
      <GridFooter
        hasPdf={hasPdf}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        recordCount={rows.length}
        onPageChange={handlePageChange}
        onPageSizeChange={propPageSize ? null : handlePageSizeChange}
        onExport={!!onExport && onExport}
        onNew={!!onNew && onNew}
      />
    </Card>
  );
}

export default TableInMem;
