import React from 'react';
import { Card } from 'react-bootstrap';

import { ButtonExport, Pagination, PageLimitSelect, FlexRow, ButtonNewRecord } from '../../common';

type Props = {
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  recordCount?: number;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (size: number) => void;
  onExport?: () => void;
  onNew?: () => void;
  hasPdf?: any;
};

const GridFooter = ({
  pageCount,
  pageIndex,
  pageSize,
  recordCount = 0,
  onPageChange = null,
  onPageSizeChange = null,
  onExport = null,
  onNew = null,
  hasPdf = null,
}: Props) => {
  return (

  //
  //-------------------------------- Render ----------------------
  //
  <Card.Footer>
    <FlexRow>
      <span className="d-flex justify-content-center align-items-center">
        {onExport && <ButtonExport onClick={onExport} />}
        {onNew && <ButtonNewRecord onClick={onNew} />}
        {/* {hasPdf && <PdfButton data={hasPdf} />} */}
      </span>
      <span className="d-flex justify-content-center align-items-center">
        <span className="my-0 mx-2">
          <span className="text-secondary">{"Πλήθος Εγγραφών"}:&nbsp;</span>
          {recordCount.toLocaleString()}
        </span>
        {pageCount > 1 && <Pagination current={pageIndex} total={pageCount} onChange={onPageChange} />}
        <div className="mx-2" />
        {onPageSizeChange && <PageLimitSelect value={pageSize} onChange={onPageSizeChange} />}
      </span>
    </FlexRow>
  </Card.Footer>
);
}

export default React.memo(GridFooter);
