import React from 'react';
import { Card } from 'react-bootstrap';
import {ButtonNewRecord }from 'components/common/buttons/button-new-record';
import { ButtonRefresh, FlexRow } from './../index';

type Props = {
  title: string;
  onRefresh: () => void;
  onCreate: () => void;
};

const GridHeaderSub = ({ title, onRefresh = null, onCreate = null }: Props) => (
  //
  //-------------------------------- Render ----------------------
  //
  <Card.Header>
    <FlexRow>
      {!!onCreate ? <ButtonNewRecord onClick={onCreate} size="sm" /> : <div />}
      <Card.Title className="mb-0">
        <h5>{title}</h5>
      </Card.Title>
      {!!onRefresh ? <ButtonRefresh onClick={onRefresh} size="sm" /> : <div />}
    </FlexRow>
  </Card.Header>
);

export default React.memo(GridHeaderSub);
