import React from 'react';
import { Card } from 'react-bootstrap';
import { ButtonBack, FlexRow } from './../index';
import { useWhyDidYouUpdate } from '../../../hooks';


type Props = {
  /** Title of form */
  title: string;
  /** Optional title suffix muted */
  idx?: number;
  /** Optional onReturn event, enabled only if form is not dirty */
  onReturn?: () => void;
};

const FormHeader = ({ title, idx = null, onReturn = null }: Props) => {

 
  useWhyDidYouUpdate('[FormHeader]', { title, idx, onReturn });
  return (
    <Card.Header>
      <FlexRow>
        <Card.Title className="m-0">
          {title}
          {/* {!!idx && <span className="text-muted">{` (${idx})`}</span>} */}
          {!!idx && idx > 0 && <span className="text-sm">&nbsp;&nbsp;{idx}</span>}
          
        </Card.Title>
        {!!onReturn && <ButtonBack onClick={onReturn} />}
      </FlexRow>
    </Card.Header>
  );
};

export default React.memo(FormHeader);
