import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';

type Props = {
  value;
  onChange?: (value: number) => void;
};
const PageLimitSelect = ({ value, onChange = () => {} }: Props) => {
  /*-------------------- Body -----------------------------------------------*/
  /*-------------------- Handlers -------------------------------------------*/
  /*-------------------- Render ---------------------------------------------*/
  return (
    <DropdownButton id="dropdown-value-button" title={value.toString()}>
      <Dropdown.Item onClick={() => onChange(5)}>5</Dropdown.Item>
      <Dropdown.Item onClick={() => onChange(7)}>7</Dropdown.Item>
      <Dropdown.Item onClick={() => onChange(10)}>10</Dropdown.Item>
      <Dropdown.Item onClick={() => onChange(15)}>15</Dropdown.Item>
      <Dropdown.Item onClick={() => onChange(20)}>20</Dropdown.Item>
      <Dropdown.Item onClick={() => onChange(50)}>50</Dropdown.Item>
    </DropdownButton>
  );
};

export default React.memo(PageLimitSelect);
