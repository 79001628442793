import React from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import ApolloClient from 'apollo-client';
import { Button } from 'react-bootstrap';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { FaFilePdf } from 'react-icons/fa';
import { format } from 'date-fns';
import { pdfDocOpen, styles, BoldText, PdfTable } from 'utils/pdfhelpfunc';
import { useRootStore } from 'store';
import { gqlGetTyreCollectionsView } from './graphql';


const columns = [
  {
    name: 'CodeId',
    header: 'Αριθμός Αίτησης',
    type: 'string',
  },
  {
    name: 'Year',
    header: 'Έτος',
    type: 'string',
  },
  { name: 'CstPointName', header: 'Σημείο Συλλογής', type: 'string', wordWrap: true, },
  { name: 'CstPointAddress', header: 'Διεύθυνση', type: 'string', wordWrap: true, },
  { name: 'CstPointMunicipality', header: 'Δήμος', type: 'string', /* wordWrap: true, */ },
  { name: 'CstPointPhone1', header: 'Τηλέφωνο', type: 'string', /* wordWrap: true, */ },
  {
    name: 'RequestDate',
    header: 'Ημ/νια Αίτησης',
    type: 'date',
  },
  {
    name: 'Notes',
    header: 'Σημειώσεις',
    type: 'string',
    wordWrap: true,
  },
  {
    name: 'TyreType1_ReqQty',
    header: 'Κατηγορία Α',
    type: 'int',
  },
  {
    name: 'TyreType2_ReqQty',
    header: 'Κατηγορία\nΒ',
    type: 'int',
  },
  {
    name: 'TyreType3_ReqQty',
    header: `Κατηγορία\nΓ`,
    type: 'int',
  },
  {
    name: 'TyreType4_ReqQty',
    header: 'Κατηγορία Δ1',
    type: 'int',
  },
  {
    name: 'TyreType5_ReqQty',
    header: 'Κατηγορία Δ2',
    type: 'int',
  },
];

const FirstPage = ({collector, fromDate, tillDate, data}) => (
<Page size="A4" style={styles.page}>
<View style={{ width: 580, height: 820, display: 'flex', padding: '50,5,25,5' }}>

<View style={{ flexDirection: 'row'}}>
    <View style={{ flex: 1 }}>
      <Image style={{ width: 200, height: 50 }} src={process.env.PUBLIC_URL + '/ecoelastika_logo.jpg'} />
    </View>
    <View style={{ flexDirection: 'column' }}>
    <View
      style={{
        flex: 3,
        fontSize: '1.3vh',
        alignSelf: 'flex-end',
        marginRight: '30px',
        alignItems: 'center'
      }}
    >
      <BoldText>{'ΕΚΚΡΕΜΕΙΣ ΑΙΤΗΣΕΙΣ'}</BoldText>
      <BoldText>{'ΣΥΛΛΟΓΗΣ ΕΛΑΣΤΙΚΩΝ'}</BoldText>
      
    </View>
    </View>
  </View>

    <View style={{ marginTop: '50px'}}>
      <Text style={{ alignSelf: 'center', fontSize: '1.3vh'}}>{`${collector}`}</Text>
      <Text style={{ alignSelf: 'center', fontSize: '1.3vh'}}>
            {'Ημερομηνία από:  '}
            {fromDate ? format(fromDate, 'dd-MM-yyyy') : ' - '} {' έως  '} 
            {tillDate ? format(tillDate, 'dd-MM-yyyy') : ' - '}
      </Text>
    </View>

    <PdfTable fontSize={5} headerFontSize={6} data={data.length > 15 ? data.splice(0,15) : data} columns={columns} />
 
</View>
</Page>
);


const SecondPage = ({data}) => (
  <Page size="A4" style={styles.page}>
<View style={{ width: 580, height: 820, display: 'flex', padding: '20,5,25,5' }}>
  <PdfTable fontSize={5} data={ data.length > 20 ? data.splice(0,20) : data} columns={columns} headers={false} />
</View>
</Page>
);

// Create Document Component
const MyDocument = ({ collector, data, fromDate, tillDate }) => (
  <Document>
    <FirstPage data={data} fromDate={fromDate} tillDate={tillDate} collector={collector}></FirstPage>
    {data.length > 15 && <SecondPage data={data}></SecondPage>}
    {data.length > 35 && <SecondPage data={data}></SecondPage>}
    {data.length > 55 && <SecondPage data={data}></SecondPage>}
    {data.length > 75 && <SecondPage data={data}></SecondPage>}
    {data.length > 95 && <SecondPage data={data}></SecondPage>}
    {data.length > 115 && <SecondPage data={data}></SecondPage>}
    {data.length > 135 && <SecondPage data={data}></SecondPage>}
    {data.length > 155 && <SecondPage data={data}></SecondPage>}
  </Document>
);

export const PdfOrdersRep = ({ data, fromDate, tillDate, buildWhere }) => {
  const client = useApolloClient();
  const { appStore } = useRootStore();
  const { data: rawData } = useQuery(gqlGetTyreCollectionsView, {
    fetchPolicy: 'network-only',
    variables: { where: buildWhere({collectedStartDate: fromDate, collectedEndDate: tillDate}), order: 'CollectionIID DESC'}
  });
  const allData = rawData?.getTyreCollectionsView?.data;
  async function printPdf(client: ApolloClient<object>): Promise<void> {
   appStore.setBusy();
    const records = [...allData];
    console.log('data=', allData)
    await pdfDocOpen(
      `OrdersReport`,
      <MyDocument fromDate={fromDate} tillDate={tillDate} collector={appStore.userLogin.collector} data={records}/>,

    );
    appStore.clearBusy();
  } 

  return (
    <Button style={{ width: '160px', marginLeft: '14px' }} variant="success" onClick={() => printPdf(client)}>
      <FaFilePdf className="mb-1" /> Εξαγωγή σε PDF
    </Button>
  );
};
