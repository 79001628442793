import React, { ComponentProps } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaThumbsUp } from 'react-icons/fa';

type Props = React.PropsWithoutRef<ComponentProps<typeof Button>> & {
  loading?: boolean;
};

const ButtonApprove = ({ loading = false, ...props }: Props) => (
  <Button {...props} variant="primary" type="button" onClick={props.onClick} >
    {loading ? <Spinner animation="border" size="sm" className="mb-1" /> : <FaThumbsUp className="mb-1" />}
    {' Έγκριση'}
  </Button>
);

export default React.memo(ButtonApprove);
