import { FlexSpinner } from "components/common";
import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import { useWhyDidYouUpdate } from "hooks";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal, ProgressBar, Button } from "react-bootstrap";
import { createModal } from "react-modal-promise";
import get from "lodash/get";
import { format, parseISO } from "date-fns";

// Καθορισμός Excel Column
class ColumnDef {
  fieldTitle: string;
  fieldName: string; // Ουσιαστικά είναι το (key) path στο object
  fieldType: string;
  fieldName2?: string;
}
const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

function addTitle(ws: Excel.Worksheet, title: string, headers: string[]) {
  // 1st Row Title
  const cellsmerged = "A1:" + alphabet[headers.length - 1] + "1";
  ws.mergeCells(cellsmerged);
  const cell = ws.getCell(`A1`);
  cell.alignment = { horizontal: "center", wrapText: true };
  cell.value = title;
  const row = ws.getRow(1);
  row.height = (title.split("\n").length - 1 + 1) * 16;
  row.font = { bold: true };
  row.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "cccccc" },
  };
  // 2nd Row column-titles
  const row2 = ws.getRow(2);
  row2.values = [...headers];
  row2.font = { bold: true };
  row2.alignment = { wrapText: true };
}

function addHeaders(
  ws: Excel.Worksheet,
  columnsDef: ColumnDef[],
  title?: string,
  count?: number
) {
  const wsColumns: Array<any> = [];
  const headers: Array<any> = [];
  columnsDef.forEach((column) => {
    const wsColumn = {
      header: column.fieldTitle,
      key: column.fieldName,
      width: column.fieldTitle.length < 16 ? 16 : column.fieldTitle.length,
    };
    wsColumns.push(wsColumn);
    headers.push(column.fieldTitle);
  });
  ws.columns = [...wsColumns];
  if (title) addTitle(ws, title + "\n (Σύνολο: " + count + ")", headers);
}

// Προσθήκη εγγραφής ως νέας γραμμης στο excel.worksheet
function addRecord(ws: Excel.Worksheet, record, columnsDef: ColumnDef[]) {
  const wsRow = {};
  columnsDef.forEach((column) => {
    const fieldName = column.fieldName;
    const fieldType = column.fieldType;
    const value = get(record, fieldName); // Lodash get  // get fieldName key from record object
    if (fieldType === "BOOL") wsRow[fieldName] = value ? "NAI" : "OXI";
    else if (fieldType === "STATUS")
      wsRow[fieldName] =
        value === 1
          ? "Εχει Γίνει η Συλλογή"
          : value === 2
          ? "Εχει Ολοκληρωθεί η Συλλογή"
          : "Εκρεμεί η Συλλογή";
    else if (fieldType === "DATETIME")
      wsRow[fieldName] = value ? format(parseISO(value), "dd/MM/yyyy") : "";
    else if (fieldType === "DATETIMEFULL")
      wsRow[fieldName] = value
        ? format(parseISO(value), "dd/MM/yyyy HH:mm")
        : "";
    else if (fieldType === "INTqty")
      wsRow[fieldName] = value === null ? 0 : value;
    else
      wsRow[fieldName] = column?.fieldName2
        ? value + " " + get(record, column.fieldName2)
        : value;
  });

  ws.addRow(wsRow);
}

type Props<D extends object = {}> = {
  getLazyQuery?: (any) => any;
  filterVariables?: Record<string, any>;
  tableName: string;
  title?: string;
  recordsData?: any;
  customPageSize?: number;
  columnsDef?: ColumnDef[]; // Προαιρετικός πίνακας με column definitions, αλλιώς διαβάζει απο τον reports_table πινακα
  open: boolean; // required for react-modal-promise
  close: (result: boolean) => void; // required for react-modal-promise
};

const ExportXlsModal = ({
  getLazyQuery,
  filterVariables,
  tableName,
  columnsDef: columnsDef0 = [],
  title,
  recordsData,
  open,
  customPageSize = 100,
  close,
}: Props) => {
  const [columnsDef] = useState(columnsDef0);
  //
  //-------------------- Body -----------------------------------------------
  //
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const pageSize = customPageSize; // Μέγεθος πακέτου δεδομένων
  const maxNoOfRecords = 10000; // Όριο εγγραφών/γραμμών στο αρχείο  <=============== MAXIMUM RECORDS TO FETCH
  const [pageIndex, setPageIndex] = useState(0);
  const offset = pageSize * pageIndex;
  // refs (wb & ws)
  const wb = useRef<Excel.Workbook>(null);
  const ws = useRef<Excel.Worksheet>(null);
  //const reportTables = useRef<Array<ReportTable>>(null);
  const recordsCount = useRef<number>(null);

  // memorize variables for data query
  //
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const variables = useMemo(
    //() => ({ ...filterVariables, offset, limit: pageSize }),
    () => ({
      ...filterVariables,
      offset: pageIndex === 0 ? offset : offset + 1,
      limit: pageIndex === 0 ? pageSize : pageSize - 1,
    }),
    [filterVariables, offset, pageSize, pageIndex]
  );
  // query Data
  //

  const [get, { data }] = getLazyQuery({});
  // query ReportTable
  //

  // Διάβασε τα excel columns definitions απο τον πίνακα report_tables
  /* const { data: dataReportTables } = useGetReportTablesQuery({
    variables: { where: JSON.stringify({ tableName: tableName }) },
    onCompleted: () => {
      console.log('[ExportXlsModal] ReportTables:', dataReportTables);
      //reportTables.current = [...dataReportTables.getReportTables.data];
      if (!columnsDef || columnsDef.length === 0) {
        // Συμπλήρωσε τον πίνακα columnsDef απο τα records
        const recs = dataReportTables.getReportTables.data;
        const newColumnsDef = columnsDef;
        recs
          .filter(rec => rec.isActive)
          // eslint-disable-next-line array-callback-return
          .map(rec => {
            newColumnsDef.push({
              fieldName: rec.fieldName,
              fieldType: rec.fieldType,
              fieldTitle: rec.fieldDescription,
            });
          });
        setColumnsDef(newColumnsDef);
      }
      //Φέρε τα δεδομένα (1o πακέτο)
      get({ variables, fetchPolicy: 'no-cache' });
      setPageIndex(pageIndex + 1);
     },
  }); */

  useEffect(() => {
    if (!recordsData) {
      get({ variables, fetchPolicy: "no-cache" });
      setPageIndex(pageIndex + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // * New Records Effect! (onNewData)
  useEffect(() => {
    if (!!recordsData) {
      const records = recordsData;
      const count = recordsData.length;
      if (!wb.current) wb.current = new Excel.Workbook();
      if (!ws.current && wb.current) {
        ws.current = wb.current.addWorksheet("Records");
        addHeaders(ws.current, columnsDef, title, count);
      }
      if (!recordsCount.current) recordsCount.current = count;
      records.forEach((record) => addRecord(ws.current, record, columnsDef));
      const recordsFetchedCount = records.length;
      if (
        recordsFetchedCount >= count ||
        recordsFetchedCount >= maxNoOfRecords
      ) {
        // Τέλος κλείσε το modal & αποθήκευσε το αρχείο excel
        close(true);
        wb.current.xlsx.writeBuffer().then((data) => {
          console.log("saving");
          const blob = new Blob([data], { type: fileType });
          saveAs(blob, "export-" + tableName + fileExtension);
        });
      }
    } else if (!!data) {
      // ΓΙΑ ΝΑ ΤΟ ΚΑΝΩ ΓΕΝΙΚΟ ΒΡΙΣΚΩ ΤΟ propertyName
      const propNameData = Object.getOwnPropertyNames(data)[0];
      const records = data[propNameData]["data"];
      const count = data[propNameData]["recordCount"];
      //console.log('[ExportXlsModal] New Records:', data, propNameData, records, count);
      // initialize wb and ws
      if (!wb.current) wb.current = new Excel.Workbook();
      if (!ws.current && wb.current) {
        ws.current = wb.current.addWorksheet("Records");
        addHeaders(ws.current, columnsDef, title, count);
      }
      if (!recordsCount.current) recordsCount.current = count;

      // Πρόσθεσε τις τρέχουσες εγγραφές στο buffer
      records.forEach((record) => addRecord(ws.current, record, columnsDef));

      const recordsFetchedCount = offset + records.length;
      // Όριο εγγραφών/γραμμών στο αρχείο
      if (
        (recordsFetchedCount >= count && offset > count) ||
        recordsFetchedCount >= maxNoOfRecords
      ) {
        // Τέλος κλείσε το modal & αποθήκευσε το αρχείο excel
        close(true);
        wb.current.xlsx.writeBuffer().then((data) => {
          console.log("saving");
          const blob = new Blob([data], { type: fileType });
          saveAs(blob, "export-" + tableName + fileExtension);
        });
      } else {
        setPageIndex(pageIndex + 1);
        //Φέρε τα δεδομένα (σε πακέτα)

        get({ variables, fetchPolicy: "no-cache" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, recordsData, pageIndex]);

  useWhyDidYouUpdate("[ExportXlsModal]", { pageIndex, data, variables });
  return (
    <Modal show={open} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>Εξαγωγή σε Excel</Modal.Title>
      </Modal.Header>
      <FlexSpinner />
      {/* {recordsCount.current && (
        <Modal.Body>
          Εγγραφές: {(pageSize * pageIndex).toLocaleString()} / {Math.min(recordsCount.current, maxNoOfRecords).toLocaleString()}
          <ProgressBar now={(100 * pageSize * pageIndex) / Math.min(recordsCount.current, maxNoOfRecords)} />
        </Modal.Body>
      )} */}
      {recordsCount.current ? (
        <Modal.Body>
          <div className="mb-2">
            Εγγραφές:{" "}
            {(pageSize * pageIndex > recordsCount.current
              ? recordsCount.current
              : pageSize * pageIndex
            ).toLocaleString()}{" "}
            / {Math.min(recordsCount.current, maxNoOfRecords).toLocaleString()}
          </div>
          <ProgressBar
            now={
              (100 * pageSize * pageIndex) /
              Math.min(recordsCount.current, maxNoOfRecords)
            }
          />
        </Modal.Body>
      ) : (
        <Modal.Body>
          <div className="mb-2">Εγγραφές: -/-</div>
          <ProgressBar now={0} />
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button variant="secondary" onClick={() => close(false)}>
          ΑΚΥΡΩΣΗ
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// Μετατρέπει το Modal σε Promise<boolean>
// πρέπει το component να έχει open + close parameters
const exportXlsModal = createModal(ExportXlsModal);

export default exportXlsModal;
