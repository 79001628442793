import { useMutation } from '@apollo/react-hooks';
import { useWhyDidYouUpdate } from 'hooks';
import React, { useCallback, useState } from 'react';
import NewUserForm from './newuser-form';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { toastFailure, toastSuccess } from 'utils/toasts';
import { gqlUserRegister } from './graphql';
import { getRouteByName } from 'app/routes';

export const NewUserPage = () => {
  /*-------------------- Body -----------------------------------------------*/
  const [userRegister] = useMutation(gqlUserRegister);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const history = useHistory();
  const location = useLocation();
  //const { from } = location.state || { from: { pathname: '/' } };
  const state: any = location.state || { from: { pathname: '/' } };
  const from = state.from;
  /* const { handleGraphqlError } = useHandleError('NewUserPage'); */

  console.log('[NewUserPage] invoked from =', from);
  /*! -------------------- Handlers -------------------------------------------*/
  /**
   * * 1. ΠΑΤΗΘΗΚΕ ΤΟ SUBMIT -> ΕΚΤΕΛΩ ΤΟ QUERY (Callback)
   */
  const handleSubmit = useCallback(
    (values, actions) => {
      //console.log('Submit', values, actions);
      actions.setSubmitting(false);
      setLoading(true);
      userRegister({ variables: { email: values.name, userPassword: values.password} })
          .then(d => {
            toastSuccess('Έγινε εγγραφή καινούργιου χρήστη με επιτυχία!')
            history.push(generatePath(getRouteByName('HomePage')));
          })
          .catch(err => {
            toastFailure( err ? err.graphQLErrors[0].message : 'Σφάλμα Εισόδου');
          setError(err);
            console.log(err.graphQLErrors[0].message);
          }).finally(() => {
            setLoading(false);
          });
      }
    ,
    [history, userRegister],
  );

  /*-------------------- Render ---------------------------------------------*/
  useWhyDidYouUpdate('[NewUserPage]', {});

  return (
    <div className="d-flex align-items-center justify-content-center" style={{ height: '85vh' }}>
      <NewUserForm onSubmit={handleSubmit} message={error} loading={loading} />
    </div>
  );
};

NewUserPage.whyDidYouRender = true;