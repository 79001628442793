import React, { FC, ComponentProps } from 'react';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';

type Props = ComponentProps<typeof Button> & {};

export const ButtonNewRecord: FC<Props> = props => (
  <Button
    {...props}
    className="btn-circle"
    variant="primary"
    onClick={props.onClick}
    data-toggle="tooltip"
    data-placement="left"
    title="Νέα Εγγραφή"
  >
    <FaPlus className="mb-1" />
  </Button>
);
