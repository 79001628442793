import ApolloClient from 'apollo-client';
import { UserLogin, UserLoginDocument, UserLoginQuery, UserLoginQueryVariables } from './types';


export function userLogin(client: ApolloClient<object>, email: string, userPassword: string): Promise<UserLogin> {
  return new Promise((resolve, reject) => {
    client
      .query<UserLoginQuery, UserLoginQueryVariables>({
        query: UserLoginDocument,
        variables: { email, userPassword },
        fetchPolicy: 'network-only',
      })
      .then(res => {
        if (res.data.userLogin !== null) {
          resolve(res.data.userLogin);
        } else {
          reject(res.errors[0].message);
        }
      })
      .catch(err => reject(err));
  });
}
