import React from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import ApolloClient from 'apollo-client';
import { Button } from 'react-bootstrap';
import { useApolloClient } from '@apollo/react-hooks';
import { FaFilePdf } from 'react-icons/fa';
import { format } from 'date-fns';
import { pdfDocOpen, styles, BoldText, PdfTable } from 'utils/pdfhelpfunc';
import { useRootStore } from 'store';


const columns = [
  { header: 'Δήμος', name: 'XName', type: 'string' },
  { header: 'Ημέρες', name: 'YValue', type: 'float' },
];

const FirstPage = ({collector, getDocument, fromDate, tillDate, data}) => (
<Page size="A4" style={styles.page}>
<View style={{ width: 580, height: 820, display: 'flex', padding: '50,30,25,30' }}>

<View style={{ flexDirection: 'row'}}>
    <View style={{ flex: 1 }}>
      <Image style={{ width: 200, height: 50 }} src={process.env.PUBLIC_URL + '/ecoelastika_logo.jpg'} />
    </View>
    <View style={{ flexDirection: 'column' }}>
    <View
      style={{
        flex: 3,
        fontSize: '1.3vh',
        alignSelf: 'flex-end',
        marginRight: '30px',
        alignItems: 'center'
      }}
    >
      <BoldText>{'ΑΝΑΛΥΣΗ ΜΕΣΗΣ ΣΤΑΘΜΙΚΗΣ ΑΠΟΚΡΙΣΗΣ'}</BoldText>
      <BoldText>{'ΣΥΛΛΟΓΗΣ ΕΛΑΣΤΙΚΩΝ ΑΝΑ ΔΗΜΟ'}</BoldText>
      
    </View>
    </View>
  </View>

    <View style={{ marginTop: '50px'}}>
      <Text style={{ alignSelf: 'center', fontSize: '1.3vh'}}>{`${collector}`}</Text>
      <Text style={{ alignSelf: 'center', fontSize: '1.3vh'}}>
            {'Ημερομηνία από:  '}
            {format(fromDate, 'dd-MM-yyyy')} {' έως  '} 
            {format(tillDate, 'dd-MM-yyyy')}
      </Text>
      <Image style={{ width: 580, height: 300 }} src={getDocument} />
    </View>
      <View>
    <PdfTable data={data.length > 12 ? data.splice(0,12) : data} columns={columns} />
    </View>
 
</View>
</Page>
);

const SecondPage = ({data}) => (
  <Page size="A4" style={styles.page}>
<View style={{ width: 580, height: 820, display: 'flex', padding: '20,30,25,30' }}>
  <PdfTable data={ data.length > 35 ? data.splice(0,35) : data} columns={columns} headers={false} />
</View>
</Page>
);

const ThirdPage = ({data}) => (
  <Page size="A4" style={styles.page}>
<View style={{ width: 580, height: 820, display: 'flex', padding: '20,30,25,30' }}>
  <PdfTable data={data} columns={columns} headers={false} />
</View>
</Page>
);

// Create Document Component
const MyDocument = ({ collector, allData, getDocument, fromDate, tillDate }) => (
  <Document>
    <FirstPage data={allData} fromDate={fromDate} tillDate={tillDate} collector={collector} getDocument={getDocument}></FirstPage>
  {allData.length > 12 && <SecondPage data={allData}></SecondPage>}
  {allData.length > 47 && <ThirdPage data={allData}></ThirdPage>}
  </Document>
);

export const PdfRep = ({ getDocument, data, fromDate, tillDate }) => {
  const client = useApolloClient();
  const { appStore } = useRootStore();


  const records = [...data];
  async function printPdf(client: ApolloClient<object>): Promise<void> {
    appStore.setBusy();
    await pdfDocOpen(
      `collectorResponseReport`,
      <MyDocument fromDate={fromDate} tillDate={tillDate} collector={appStore.userLogin.collector} allData={records} getDocument={getDocument}/>
    );
    appStore.clearBusy();
  } 

  return (
    <Button style={{ width: '160px', marginLeft: '27px' }} variant="success" onClick={() => printPdf(client)}>
      <FaFilePdf className="mb-1" /> Εξαγωγή σε PDF
    </Button>
  );
};
