import { format } from 'date-fns';

export function langToLid(lang: string) {
  if (lang === 'en') return 1033;
  if (lang === 'el') return 1032;
  return 1033;
}

export function datestr(ts): string {
  if (ts) {
    let n: number = parseInt(ts);
    return format(n, 'YYYY-MM-DD');
  } else {
    return '';
  }
}

export const isArray = (() =>
  Array.isArray || (<T>(x: any): x is T[] => x && typeof x.length === 'number'))();

export const isArrayLike = <T>(x: any): x is ArrayLike<T> =>
  x && typeof x.length === 'number' && typeof x !== 'function';

export function isDate(value: any): value is Date {
  return value instanceof Date && !isNaN(+value);
}

// from rxjs
export function isNumeric(val: any): val is number | string {
  // parseFloat NaNs numeric-cast false positives (null|true|false|"")
  // ...but misinterprets leading-number strings, particularly hex literals ("0x...")
  // subtraction forces infinities to NaN
  // adding 1 corrects loss of precision from parseFloat (#15100)
  return !isArray(val) && val - parseFloat(val) + 1 >= 0;
}

export function isFunction(x: any): x is Function {
  return typeof x === 'function';
}

export function isPromise(value: any): value is PromiseLike<any> {
  return (
    !!value &&
    typeof (value as any).subscribe !== 'function' &&
    typeof (value as any).then === 'function'
  );
}

export const currencyFormat = num =>
  num
    .toFixed(2) // always two decimal digits
    .replace('.', ',') // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'; // use . as a separator
