import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ButtonSearch, ButtonClear } from './../index';

type Props = {
  isDirty?: boolean;
  isValid?: boolean;
  onSearch?: () => void;
  onClear?: () => void;
};

const FormFiltersButtons = ({ isDirty = true, isValid = true, onSearch = () => {}, onClear = () => {} }: Props) => (
  <Col xs="auto">
    <Row className="m-3">
      <ButtonSearch disabled={!isValid} onClick={onSearch} />
    </Row>
    <Row className="m-3">
      <ButtonClear disabled={!isDirty} onClick={onClear} />
    </Row>
  </Col>
);

export default React.memo(FormFiltersButtons);
