import gql from 'graphql-tag';

export const gqlGetCollectorResponse= gql`
query getCollectorResponse($CollectorId: Int!, $fromDate: DateTime, $tillDate: DateTime ) {
  getCollectorResponse(CollectorId: $CollectorId, fromDate: $fromDate, tillDate: $tillDate) {
    Id
    XName
    YValue
  }
}
`;

export const gqlGetStationsDeliveries= gql`
query getStationsDeliveries($CollectorId: Int!, $fromDate: DateTime, $tillDate: DateTime ) {
  getStationsDeliveries(CollectorId: $CollectorId, fromDate: $fromDate, tillDate: $tillDate) {
    Id
    XName
    YValue
  }
}
`;
export const gqlGetCollectedWeight= gql`
query getCollectedWeight($CollectorId: Int!, $fromDate: DateTime, $tillDate: DateTime ) {
  getCollectedWeight(CollectorId: $CollectorId, fromDate: $fromDate, tillDate: $tillDate) {
    Id
    ColQtyLT2tn
    ColSumLT2tn
    ColQtyMT2tn
    ColSumMT2tn
  }
}
`;

export const gqlGetCollectedQuantity= gql`
query getCollectedQuantity($CollectorId: Int!, $fromDate: DateTime, $tillDate: DateTime ) {
  getCollectedQuantity(CollectorId: $CollectorId, fromDate: $fromDate, tillDate: $tillDate) {
    Id
    TyreType1_ColQty
    TyreType2_ColQty
    TyreType3_ColQty
    TyreType4_ColQty
    TyreType5_ColQty
    TyresWeight
  }
}
`;

export const gqlGetDeliveredQuantity= gql`
query getDeliveredQuantity($CollectorId: Int!, $fromDate: DateTime, $tillDate: DateTime ) {
  getDeliveredQuantity(CollectorId: $CollectorId, fromDate: $fromDate, tillDate: $tillDate) {
    Id
    NetWeight
    TyreType1_ColQty
    TyreType2_ColQty
    TyreType3_ColQty
    TyreType4_ColQty
    TyreType5_ColQty
    TyresWeight
  }
}
`;