import * as jwt from "jsonwebtoken";
import { UserRole } from "./constants";

export interface JwtToken {
  userId: number;
  email: String;
  JwtExpirationDate: Date;
  JwtIssueDate: Date;
  userRole: UserRole;
  recyclingStationId: number;
  collectorId: number;
}

const JWT_PRIVATE_KEY = "MOTION$ECOCOLLECTORS";

export function adminDevToken(): string {
  const issueDate = new Date();
  const expireDate = new Date(issueDate.setHours(issueDate.getHours() + 1));

  let jwtObj: JwtToken = {
    userId: 1,
    email: "info@motion.gr",
    JwtExpirationDate: issueDate,
    JwtIssueDate: expireDate,
    userRole: UserRole.Collector,
    collectorId: 1,
    recyclingStationId: null,
  };
  return encodeJwtToken(jwtObj);
}

export function encodeJwtToken(jwtObj: JwtToken): string {
  return jwt.sign(jwtObj, JWT_PRIVATE_KEY);
}

export function decodeJwtToken(tokenString: string): JwtToken {
  const decoded = jwt.verify(tokenString, JWT_PRIVATE_KEY) as any;
  const jwtToken: JwtToken = decoded;
  jwtToken.JwtExpirationDate = new Date(Date.parse(decoded.JwtExpirationDate));
  jwtToken.JwtIssueDate = new Date(Date.parse(decoded.JwtIssueDate));
  return jwtToken as JwtToken;
}
