import gql from 'graphql-tag';

export const gqlUserLogin = gql`
  query userLogin($email: String, $userPassword: String) {
    userLogin(email: $email, userPassword: $userPassword) {
      jwt
      userMustChangePassword
      expirationDate
      userId
    }
  }
`;

export const gqlUserMailTempPassword = gql`
  mutation userMailTempPassword($email: String!) {
    userMailTempPassword(email: $email)
  }
`;

export const gqlUserChangePassword = gql`
  mutation userChangePassword($email: String!, $userPassword:String!, $userNewPassword: String!) {
      userChangePassword(email: $email, userPassword: $userPassword, userNewPassword: $userNewPassword) 
     }
`;

export const gqlUserRegister= gql`
  mutation userRegister($email: String!, $userPassword:String!) {
      userRegister(email: $email, userPassword: $userPassword) 
     }
`;
