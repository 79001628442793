import gql from 'graphql-tag';

export const gqlGetRsDeliveriesView = gql`
  query getRsDeliveriesView($where: String, $offset: Int, $limit: Int, $order: String ) {
    getRsDeliveriesView(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        guid
        rsId
        year
        delivId
        delivNid
        vehicleId
        driverName
        collectorId
        materialId
        grossDate
        grossGateWrkId
        tareDate
        tareGateWrkId
        grossWeight
        tareWeight
        netWeight
        docId
        isNotActive
        firstDate
        firstUser
        lastDate
        lastUser
        salesAreaId
        centralNotes
        delivTypeId
        ecoPointCollectionIid
        sumWeightOfTyreCollections
        tabletECoNid
        tyreType1Qty
        tyreType2Qty
        tyreType3Qty
        tyresSpecifiedAtRs
        tyreType4Qty
        tyreType5Qty
        collectorName
        vehPlateNo
        rsName
        grossGateWrkName
        tareGateWrkName
        materialName
        vehSpecsIid
        vsModel
        vsTruckType
        vsNotes
        vsVehMinTareWeight
        vsVehMaxGrossWeight
        vsCarrierVolume
        vsCarrierHeight
        vsCarrierWidth
        vsCarrierLength
        salesAreaName
        deliveryTypeName
        tyreCalcWeight
      }
      recordCount
    }
  }
`;