import React from 'react';
import { FieldSelectDB } from './../../../components/common/index';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { FsProps } from 'components/common/formik/FsProps';

const gqlGetSalesAreas = gql`
  query getSalesAreas($where: String, $offset: Int, $limit: Int, $order: String) {
    getSalesAreas(where: $where, offset: $offset, limit: $limit, order: $order) {
      data {
        id
        description: name
      }
      recordCount
    }
  }
`;


function useGetSalesAreasLazyQuery() {
  return useLazyQuery(gqlGetSalesAreas, { variables:{ where: JSON.stringify({isNotActive: 0})}, fetchPolicy: 'no-cache' });
  //return [null, { loading: null, data: null }];
}

const FieldSelectSalesArea = ({ label = 'Νομοί', name = 'salesAreaId', defaultValues = {}, ...rest }: FsProps) => {
  return (
    <FieldSelectDB
      {...rest}
      label={label}
      name={name}
      // @ts-ignore
      sbLabel={defaultValues?.collector?.name ?? undefined}
      hook={useGetSalesAreasLazyQuery}
    />
  );
};

export default FieldSelectSalesArea;
