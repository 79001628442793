import React from "react";
import { FieldSelectDB } from "./common/index";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import { FsProps } from "components/common/formik/FsProps";

const gqlGet = gql`
  query get($collectorId: Int!, $filterId: Int!) {
    getMunicipalityFromTyreCollections(
      collectorId: $collectorId
      filterId: $filterId
    ) {
      data {
        id
        name
      }
    }
  }
`;

// export function useGetCompanyTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyTypesQuery, GetCompanyTypesQueryVariables>) {
//   return ApolloReactHooks.useLazyQuery<GetCompanyTypesQuery, GetCompanyTypesQueryVariables>(GetCompanyTypesDocument, baseOptions);
// }

const FieldSelectMunicipalityFromCollections = ({
  label = "Δήμος",
  name = "municipality",
  defaultValues = {},
  collectorId,
  filterId,
  ...rest
}: any) => {
  function useGetLazyQuery() {
    return useLazyQuery(gqlGet, {
      fetchPolicy: "no-cache",
      variables: { collectorId: collectorId, filterId: filterId },
    });
    //return [null, { loading: null, data: null }];
  }
  return (
    <FieldSelectDB
      {...rest}
      label={label}
      name={name}
      // @ts-ignore
      sbLabel={defaultValues?.nomos ?? undefined}
      hook={useGetLazyQuery}
      recordLabel="name"
    />
  );
};

export default FieldSelectMunicipalityFromCollections;
