import React, { ComponentProps } from 'react';
import { Button } from 'react-bootstrap';
import { MdRemoveRedEye } from 'react-icons/md';

type Props = React.PropsWithoutRef<ComponentProps<typeof Button>> & {};

const ButtonRedEye = (props: Props) => (
  <Button
    {...props}
    style={{ borderWidth: 0, cursor: 'pointer' }}
    className="text-center px-2 py-0"
    variant="outline-danger"
  >
    <MdRemoveRedEye className="mb-1" />
  </Button>
);
export default React.memo(ButtonRedEye);
