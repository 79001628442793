import { ResponseDaysPage } from "pages/reports/response-days/response-days-page";
import gql from "graphql-tag";

export const gqlGetTyreCollectionsResponseDays = gql`
  query getTyreCollectionsResponseDays(
    $where: String
    $offset: Int
    $limit: Int
    $order: String
  ) {
    getTyreCollectionsResponseDays(
      where: $where
      offset: $offset
      limit: $limit
      order: $order
    ) {
      data {
        CollectionIID
        Year
        CodeId
        CstPointName
        CstPointAddress
        CstPointNomos
        CstPointMunicipality
        RequestDate
        CollectionDate
        ResponseDays
        TheoriticalWeight
      }
      recordCount
      sums {
        ResponseDays
        TheoriticalWeight
      }
    }
  }
`;
