import React, { ReactNode } from 'react';
import { Card, Table } from 'react-bootstrap';
import { useTable, Column } from 'react-table';
import { ButtonExport } from '.';

import { useWhyDidYouUpdate } from '../../hooks';
import FlexRow from './flex-row';

const defFooterGroupProps = () => ({ className: 'text-center' });
const defFooterProps = () => ({ className: 'align-middle' });

type Props<D extends object = {}> = {
  title?: string;
  //columns: Array<Column<D>>;
  columns: Array<Column<any>>;
  data: any[];
  showButtonRedEye?: boolean;
  invalid?: boolean;
  hasFooter?: boolean;
  footerGroupProps?: (footerGroup) => object;
  footerProps?: (column: Column<D>) => object;
  rowProps?: (row) => object;
  onRefresh?: () => void;
  onSelectRow?: (any) => void;
  onCreate?: () => void;
  children?: ReactNode;
  style?: any;
  onExport?: () => void;
};

function TableSubSimple<D extends object = {}>({
  title,
  columns,
  data,
  hasFooter = false,
  footerGroupProps = defFooterGroupProps,
  footerProps = defFooterProps,
  showButtonRedEye = true,
  invalid = false,
  rowProps = null,
  onRefresh = null,
  onSelectRow = null,
  onCreate = null,
  onExport,
  children = null,
  style,
}: Props<D>) {
  //
  //-------------------- Body -----------------------------------------------
  //
  const { getTableProps, getTableBodyProps, headerGroups,footerGroups, prepareRow, rows } = useTable({
    columns,
    data,
  });

  //
  //-------------------- Render ----------------------------------------------
  //
  useWhyDidYouUpdate('[TableSubSimple]', { columns, data });
  return (
    <Card.Body className="py-2">
      <Table striped hover bordered {...getTableProps()} className="mb-0" style={style}>
        <thead className="thead-dark">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} className="text-center">
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} className="align-middle">
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr style={{backgroundColor: row.original?.Alert ? '#FFEB3B' : undefined}} {...row.getRowProps((rowProps && rowProps(row)) || {})}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
        {hasFooter && (
              <tfoot style={{ backgroundColor: '#e1f3f7' }}>
                {footerGroups.map(footerGroup => (
                  <tr {...footerGroup.getFooterGroupProps((footerGroupProps && footerGroupProps(footerGroup)) || {})}>
                    {footerGroup.headers.map(column => (
                      <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                    ))}
                  </tr>
                ))}
              </tfoot>
            )}
      </Table>
      <Card.Footer>
    <FlexRow>
      <span className="d-flex justify-content-center align-items-center">
        {onExport && <ButtonExport onClick={onExport} />}
        {/* {hasPdf && <PdfButton data={hasPdf} />} */}
      </span>
  </FlexRow>
  </Card.Footer>
      {children}
    </Card.Body>
  );
}

export default React.memo(TableSubSimple);
