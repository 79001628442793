import { FieldLabel } from './../index';
import { FieldAttributes, useField, useFormikContext } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { Form, OverlayTrigger } from 'react-bootstrap';
import { telNumber } from './../../../utils/telnumber';
import { FieldFeedback } from '.';
import FieldTooltip from './field-tooltip';

type Props = {
  as?: React.ElementType<any>;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
} & FieldAttributes<any>;

const FieldTelephoneInput = ({
  as = 'div',
  name,
  label,
  disabled = false,
  placeholder = '',
  required = false,
}: Props) => {
  //
  //-------------------- Body -----------------------------------------------
  //
  const [{ value }, { touched, error, initialValue }] = useField<string>(name);
  const { setFieldValue, setFieldTouched } = useFormikContext<any>();

  const setFieldValueAndTouched = useCallback(
    async (value, touched: boolean) => {
      await setFieldValue(name, value, true); //field,value,shouldValidate
      await setFieldTouched(name, touched); //field,touched,shouldValidate
    },
    [name, setFieldTouched, setFieldValue],
  );
  //
  //-------------------- Handlers -------------------------------------------
  //
  const handleChange = useCallback(
    event => {
      const inVal = event.target.value;
      const outVal = telNumber(inVal);
      setFieldValueAndTouched(outVal, true);
    },
    [setFieldValueAndTouched],
  );

  const handleBlur = useCallback(async () => {
    await setFieldTouched(name, touched); //field,touched,shouldValidate
  }, [setFieldTouched, name, touched]);

  const changed = value !== initialValue;

  // useWhyDidYouUpdate(`[FieldIntegerInput:${name}]`, {
  //   label,
  //   disabled,
  //   name,
  //   handleChange,
  //   handleBlur,
  //   setFieldValueAndTouched,
  //   value,
  //   initialValue,
  // });
  //
  //-------------------- Render -------------------------------------------
  //
  return useMemo(
    () => (
      <Form.Group as={as} controlId={name} className="mb-2">
        <FieldLabel text={label} changed={changed} required={required} />
        <OverlayTrigger
          delay={{ show: 1000, hide: 200 }}
          overlay={FieldTooltip({ text: name, value: value || '' })}
          placement="top"
        >
          <Form.Control
            style={required && !disabled ? { backgroundColor: 'lightYellow' } : {}}
            className="mb-1"
            type={'text'}
            name={name}
            value={value || ''}
            placeholder={placeholder}
            isInvalid={touched && !!error}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </OverlayTrigger>
        <FieldFeedback type="invalid" name={name} />
      </Form.Group>
    ),
    [as, name, label, changed, required, value, disabled, placeholder, touched, error, handleChange, handleBlur],
  );
};

export default FieldTelephoneInput;
