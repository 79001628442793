import gql from "graphql-tag";

export const gqlGetTyreCollections = gql`
  query getTyreCollections(
    $where: String
    $offset: Int
    $limit: Int
    $order: String
  ) {
    getTyreCollections(
      where: $where
      offset: $offset
      limit: $limit
      order: $order
    ) {
      data {
        CollectionIID
        Year
        CodeId
        Notes
        CollectorNotes
        Status
        CstPointId
        CstPoint {
          Name
          Address
          Phone1
          Phone2
          Phone3
          Municipality
          Nomos
          VAT
          IRS
        }
        CollectorId
        Collector {
          ID
          Name
        }
        RequestDate
        Req_FromCompanyPersonId
        Req_FromCollPersonName
        TyreType1_ReqQty
        TyreType2_ReqQty
        TyreType3_ReqQty
        TyreType4_ReqQty
        TyreType5_ReqQty
        SendToCollectorId
        CollectionDate
        PlateNo
        Col_FromCompanyPersonId
        Col_FromCollPersonName
        Col_DocId
        TyreType1_ColQty
        TyreType2_ColQty
        TyreType3_ColQty
        TyreType4_ColQty
        TyreType5_ColQty
        FirstDate
        FirstUser
        LastDate
        LastUser
        TabletDataNID
        RecyclingStationId
        RsDelivSumWeight
        RsDelivGUID
        IsLocked
      }
      recordCount
    }
  }
`;

export const gqlGetTyreCollectionsView = gql`
  query getTyreCollectionsView(
    $where: String
    $offset: Int
    $limit: Int
    $order: String
  ) {
    getTyreCollectionsView(
      where: $where
      offset: $offset
      limit: $limit
      order: $order
    ) {
      data {
        CollectionIID
        Year
        CodeId
        Notes
        CollectorNotes
        plannedDate
        isPlannedDateConfirmed
        Status
        CstPointId
        CollectorId
        RequestDate
        Req_FromCompanyPersonId
        Req_FromCollPersonName
        TyreType1_ReqQty
        TyreType2_ReqQty
        TyreType3_ReqQty
        TyreType4_ReqQty
        TyreType5_ReqQty
        SendToCollectorId
        CollectionDate
        PlateNo
        Col_FromCompanyPersonId
        Col_FromCollPersonName
        Col_DocId
        TyreType1_ColQty
        TyreType2_ColQty
        TyreType3_ColQty
        TyreType4_ColQty
        TyreType5_ColQty
        FirstDate
        FirstUser
        LastDate
        LastUser
        TabletDataNID
        RecyclingStationId
        RsDelivSumWeight
        RsDelivGUID
        IsLocked
        CstPointName
        CstPointAddress
        CstPointPhone1
        CstPointPhone2
        CstPointPhone3
        CstPointVAT
        CstPointTypeName
        CstPointIRS
        CstPointMunicipality
        CstPointNomos
        CstPointEMail
        CollectorName
        ColTyresWeight
        ReqTyresWeight
      }
      recordCount
    }
  }
`;
