import gql from 'graphql-tag';

export const gqlGetCollectorStations= gql`
query getCollectorStations($CollectorId: Int!, $fromDate: DateTime, $tillDate: DateTime ) {
  getCollectorStations(CollectorId: $CollectorId, fromDate: $fromDate, tillDate: $tillDate) {
    Id
    Name
    MonthlyKgLimit
    NetWeight
    RemainingWeight
    Alert
  } 
}
`;