import React from "react";
import { Modal, Button, ModalProps, Form } from "react-bootstrap";
import { createModal } from "react-modal-promise";
import { Formik, Form as FForm } from "formik";
import { FieldDatepicker } from "../formik";
import { toastWarning } from "utils/toasts";

type Props = {
  open: boolean;
  close: (result: any) => void;
  title: string;
  /** body of dialog */
  text: string | React.ReactNode;
  /** accept text */
  acceptText?: string;
  /** deny text */
  denyText?: string;
  type?: string;
  init?: {
    date: Date;
  };
} & ModalProps;

const ModalInput = ({
  open,
  close,
  title = "Καθορίστε Ημερομηνία",
  text,
  acceptText = "ΟΚ",
  denyText = "ΑΚΥΡΩΣΗ",
  type = "text",
  init,
}: Props) => {
  const handleOk = (values) => {
    if (values.date === null) {
      toastWarning("Πρέπει να επιλέξετε Ημερομηνία");
    } else {
      close(values);
    }
  };
  return (
    <>
      <Modal show={open} centered backdrop="static">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{ date: init?.date ?? null }}
          onSubmit={(values) => {
            handleOk(values);
          }}
        >
          <Form as={FForm}>
            <Modal.Body>
              <br />
              <FieldDatepicker required label={"Ημερομηνία"} name="date" />
            </Modal.Body>
            <Modal.Footer>
              {/*  <Button variant="danger" onClick={() => close(false)}>
            {denyText}
          </Button> */}
              <Button variant="primary" type="submit">
                {acceptText}
              </Button>
              <Button variant="danger" onClick={() => close(false)}>
                {"ΑΚΥΡΩΣΗ"}
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

const promiseModalSelectDateOnly = createModal(ModalInput);

export default promiseModalSelectDateOnly;
