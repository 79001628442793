import React from "react";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import { FsProps } from "components/common/formik/FsProps";
import { FieldSelectDB } from "./common";

const gqlGetRsCollectors = gql`
  query getRsCollectors(
    $where: String
    $offset: Int
    $limit: Int
    $order: String
  ) {
    getRsCollectors(
      where: $where
      offset: $offset
      limit: $limit
      order: $order
    ) {
      data {
        id: RsId
        description: RecyclingStationName
      }
      recordCount
    }
  }
`;

function useGetCollectorsLazyQuery() {
  return useLazyQuery(gqlGetRsCollectors, {
    /* variables: {
      where: JSON.stringify({ "$RecyclingStation.IsNotActive$": false }),
    }, */
    fetchPolicy: "no-cache",
  });
  //return [null, { loading: null, data: null }];
}

const FieldSelectRsCollector = ({
  label = "Σταθμός Ανακύκλωσης",
  name = "RsId",
  defaultValues = {},
  ...rest
}: FsProps) => {
  return (
    <FieldSelectDB
      {...rest}
      label={label}
      name={name}
      // @ts-ignore
      sbLabel={defaultValues?.RecyclingStation?.name ?? undefined}
      hook={useGetCollectorsLazyQuery}
    />
  );
};

export default FieldSelectRsCollector;
