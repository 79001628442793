import React from 'react';
import { Row } from 'react-table';
import shortid from 'shortid';

import { GridCell } from './../index';
import { useWhyDidYouUpdate } from '../../../hooks';

type Props = {
  row: Row<any>;
};

const GridRow = ({ row }: Props) => {
  useWhyDidYouUpdate(`[GridRow]`, { row, props: row.getRowProps() });
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map(cell => (
        <GridCell key={shortid.generate()} cell={cell} />
      ))}
    </tr>
  );
};

export default React.memo(GridRow);
