import React from "react";
import { FieldSelectDB } from "./common/index";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";

const gqlGet = gql`
  query getMunicipalities {
    getMunicipalities {
      data {
        id
        name
      }
    }
  }
`;

const FieldSelectMunicipalitiesAll = ({
  label = "Δήμος",
  name = "municipality",
  defaultValues = {},
  ...rest
}: any) => {
  function useGetLazyQuery() {
    return useLazyQuery(gqlGet, {
      fetchPolicy: "cache-and-network",
    });
    //return [null, { loading: null, data: null }];
  }
  return (
    <FieldSelectDB
      {...rest}
      label={label}
      name={name}
      // @ts-ignore
      sbLabel={defaultValues?.municipality ?? undefined}
      hook={useGetLazyQuery}
      recordLabel="name"
    />
  );
};

export default FieldSelectMunicipalitiesAll;
