import React from 'react';
import { Card } from 'react-bootstrap';
import { FlexRow, ButtonSubmit, ButtonCancel, ButtonDelete } from './../index';

type Props = {
  isDirty?: boolean;
  isValid?: boolean;
  isNew?: boolean;
  permitDelete?: boolean;
  onReset?: () => void;
  onDelete?: () => void;
};

const FormFooter = ({
  isDirty = true,
  isNew = false,
  permitDelete = true,
  onReset = () => {},
  onDelete = () => {},
}: Props) => (
  <Card.Footer>
    <FlexRow>
      <div>
        {(isDirty || isNew) && (
          <>
            <ButtonSubmit disabled={!isDirty} />
            <span className="mx-1"></span>
          </>
        )}
        {(isDirty || isNew) && <ButtonCancel disabled={!isDirty} onClick={onReset} />}
      </div>
      <div>{!isDirty && !isNew && permitDelete && <ButtonDelete onClick={onDelete} />}</div>
    </FlexRow>
  </Card.Footer>
);

export default React.memo(FormFooter);
/*
| Dirty | Valid | New | permitDelete |     | Submit/sw | Submit/dis | Cancel/sh | Cancel/dis | Delete/sw | Delete/des |
| ----- | ----- | --- | ------------ | --- | --------- | ---------- | --------- | ---------- | --------- | ---------- |
| 0     | 0     | 0   | 0            |     |           |            |           |            |           |            |
| 0     | 0     | 0   | 1            |     |           |            |           |            | 1         |            |
| 0     | 0     | 1   | 0            |     | 1         | 1          | 1         |  1         |           |            |
| 0     | 0     | 1   | 1            |     | 1         | 1          | 1         |  1         |           |            |
| 0     | 1     | 0   | 0            |     |           |            |           |            |           |            |
| 0     | 1     | 0   | 1            |     |           |            |           |            | 1         |            |
| 0     | 1     | 1   | 0            |     | 1         | 1          | 1         |  1         |           |            |
| 0     | 1     | 1   | 1            |     | 1         | 1          | 1         |  1         |           |            |
| 1     | 0     | 0   | 0            |     | 1         |            | 1         |            |           |            |
| 1     | 0     | 0   | 1            |     | 1         |            | 1         |            |           |            |
| 1     | 0     | 1   | 0            |     | 1         |            | 1         |            |           |            |
| 1     | 0     | 1   | 1            |     | 1         |            | 1         |            |           |            |
| 1     | 1     | 0   | 0            |     | 1         |            | 1         |            |           |            |
| 1     | 1     | 0   | 1            |     | 1         |            | 1         |            |           |            |
| 1     | 1     | 1   | 0            |     | 1         |            | 1         |            |           |            |
| 1     | 1     | 1   | 1            |     | 1         |            | 1         |            |           |            |


Submit/show : Dirty || New
Submit/disable : !Dirty
Cancel : Dirty || New
Cancel/disable : !Dirty
Delete : !Dirty && !New && permitDelete

*/
