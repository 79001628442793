import React from 'react';
import { Form } from 'react-bootstrap';

type Props = {
  text: string | React.ReactNode;
  changed?: boolean;
  required?: boolean;
  size?: string;
};

const FieldLabel = ({ size, text, required = false, changed = false }: Props) => {
  return (
    <>
    {size==="lg" ?  ( <Form.Label column={true} lg={2}>
      <span className={changed ? 'text-truncate text-primary' : 'text-truncate'}>
        {text}
        {required && <span className="font-weight-bold text-danger">{' (*) '}</span>}
        {' : '}
      </span>
    </Form.Label>) : (<Form.Label>
      <span className={changed ? 'text-truncate text-primary' : 'text-truncate'}>
        {text}
        {required && <span className="font-weight-bold text-danger">{' (*) '}</span>}
        {' : '}
      </span>
    </Form.Label>) }
    </>
  );
};

export default React.memo(FieldLabel);
