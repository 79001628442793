/**
 * Μαζεύει όλα τα stores και τα βάζει μέσα στο RootStore
 */
import { create } from 'mobx-persist';

import { log } from '../services';
import { AppStore } from './app-store';

export class RootStore {
  appStore = new AppStore();

  constructor() {
    const hydrate = create();
    hydrate('Bunker-App-AppStore', this.appStore).then(() => {
      log.log('[RootStore] cstr: AppStore has been hydrated');
      this.appStore.initialize();
    });
  }
}
