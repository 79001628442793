import React from "react";
import { useHistory } from "react-router-dom";
import { useRootStore } from "store";

const HomePage = () => {
  //-------------------- Body -------------------------------------------
  //
  const { appStore } = useRootStore();
  const history = useHistory();
  appStore.isCollector ? history.push("/orders") : history.push("/calendar");
  //-------------------- Handlers -------------------------------------------
  //

  //
  //-------------------- Render ----------------------------------------------
  //

  return <></>;
};

export default HomePage;
HomePage.whyDidYouRender = true;
