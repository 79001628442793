import React, { useState } from 'react';
import { Form, Button, Card, InputGroup, Spinner } from 'react-bootstrap';
import { FaUser, FaLock, FaEye } from 'react-icons/fa';
import { Formik, Form as FForm } from 'formik';
import { string, object, InferType, ref } from 'yup'; // for only what you need
import { useWhyDidYouUpdate } from '../hooks';
import { FieldFeedback } from 'components/common';
/**
 * Setup Yup Validation
 */
const validationSchema = object().shape({
  name: string().required(`Το πεδίο δεν μπορεί να είναι κενό`),
  password: string().matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/,
    "Πρέπει να είναι τουλάχιστον 6 χαρακτήρες και να περιέχει τουλάχιστον ένα Κεφαλαίο γράμμα, ένα Μικρό, και έναν Αριθμό",
  ).required(`Το πεδίο δεν μπορεί να είναι κενό`),
  confirmPassword: string()
    .oneOf([ref('password'), null], "Η επιβεβαίωση δεν είναι σωστή")
    .required(`Το πεδίο δεν μπορεί να είναι κενό`),
});

export type FormDataType = InferType<typeof validationSchema>;

type Props = {
  message?: string | null;
  loading?: boolean;
  onSubmit: (values, action) => void;
};

const NewUserForm = ({ message = '', loading = false, onSubmit }: Props) => {
  /*-------------------- Body -----------------------------------------------*/
  const [show, setShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

  useWhyDidYouUpdate('[NewUserForm]', { message, onSubmit });
  /*-------------------- Render ---------------------------------------------*/
  return (
    <Formik initialValues={{ name: '', password: '', confirmPassword: '' }} validationSchema={validationSchema} onSubmit={onSubmit}>
      {formikBag => (
        <Card className="bg-light shadow-sm p-3 mb-5" style={{ maxWidth: 640 }}>
         <Card.Img className="bg-light" variant="top" src="index_3D_Logo_GR.jpg" />
      <Card.Body>
        <Card.Title className="text-center mb-4 mt-1" as="h4">
          Εγγραφή Χρήστη
        </Card.Title>
            <p className="text-danger text-center">{message && ''}</p>

            <Form as={FForm}>
              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FaUser />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    className="rounded-right"
                    id="name"
                    size={"sm"}
                    type="text"
                    placeholder="Email Χρήστη"
                    {...formikBag.getFieldProps('name')}
                    isInvalid={!!formikBag.touched.name && !!formikBag.errors.name}
                  />
                  <FieldFeedback type="invalid" name={'name'} />
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FaLock />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    className="login-pw"
                    id="password"
                    type={show ? 'text' : 'password'}
                    placeholder="Κωδικός Πρόσβασης"
                    {...formikBag.getFieldProps('password')}
                    isInvalid={!!formikBag.touched.password && !!formikBag.errors.password}
                  />
                  <InputGroup.Append>
                    <Button className="rounded-right" onClick={() => setShow(value => !value)}>
                      <FaEye />
                    </Button>
                  </InputGroup.Append>
                  <FieldFeedback type="invalid" name={'password'} />
                </InputGroup>
              </Form.Group>

              <Form.Group>
              <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FaLock />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                  placeholder="Επιβεβαίωση Κωδικού"
                    id="confirmPassword"
                    type={confirmPasswordShow ? 'text' : 'password'}
                    {...formikBag.getFieldProps('confirmPassword')}
                    isInvalid={!!formikBag.touched.confirmPassword && !!formikBag.errors.confirmPassword}
                  />
                  <InputGroup.Append>
                    <Button onClick={() => setConfirmPasswordShow(value => !value)}>
                      <FaEye />
                    </Button>
                  </InputGroup.Append>
                  <FieldFeedback type="invalid" name={'confirmPassword'} />
                </InputGroup>
              </Form.Group>

              <Button
                block
                variant="success"
                size={"sm"}
                style={{ fontSize: '0.8rem' }}
                type="submit"
                disabled={!(formikBag.isValid && formikBag.dirty)}
              >
                {loading ? <Spinner animation="border" size="sm" className="mx-2" /> : <span></span>}
                <span>{'Εγγραφή'}</span>
              </Button>
            </Form>
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
};


export default  NewUserForm;