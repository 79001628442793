import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from "react";
import { Card, Row } from "react-bootstrap";
import { FormCol, CellFloatCustom } from "components/common";
import LoadingOverlay from "react-loading-overlay";
import { useRootStore } from "store";
import { useLazyQuery } from "@apollo/react-hooks";
import { useLocation, useHistory } from "react-router-dom";
import { FormFilterCard } from "components/common/formik";
import TableSubSimple from "components/common/table-sub-simple";
import { FieldDateRanger } from "components/common/formik/field-dateranger";
import { gqlGetStationsDeliveries } from "../graphql";
import moment from "moment";
import Chart from "react-google-charts";
import { format } from "date-fns";
import exportXlsModal from "components/common/export-xls-modal";
import html2canvas from "html2canvas";
import { PdfRep } from "./pdf-stationsDeliveries";

interface FilterParams {
  fromDate: Date;
  tillDate: Date;
}
const initFilters: FilterParams = {
  fromDate: moment()
    .startOf("month")
    .toDate(),
  tillDate: moment()
    .endOf("month")
    .toDate(),
};

export const StationsDeliveriesPage = () => {
  const location = useLocation<any>();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [routeFilterValues] = useState(
    location?.state?.filterValues ?? initFilters
  );
  const [
    get,
    { data: rawData, loading },
  ] = useLazyQuery(gqlGetStationsDeliveries, { fetchPolicy: "network-only" });
  const data = rawData ? rawData.getStationsDeliveries : undefined;
  const { appStore } = useRootStore();
  const [imgUrl, setImgUrl] = useState("");

  const fetchData = useCallback(
    async (params: FilterParams) => {
      setError(false);
      let fromDate = params?.fromDate ?? initFilters.fromDate;
      let tillDate = params?.tillDate ?? initFilters.tillDate;

      get({
        variables: {
          CollectorId: appStore.userLogin.collectorId,
          fromDate: fromDate,
          tillDate: tillDate,
        },
      });
    },
    [appStore.userLogin.collectorId, get]
  );

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      const filterParams = location?.state?.filterValues ?? {};
      fetchData(filterParams as FilterParams);
    }
  });

  const filtersChanged = useCallback(
    (values) => {
      //fetchData(values.collectorId);
      // Ενημέρωσε το State του Route => triggers page refresh
      history.replace(location.pathname, { filterValues: values });
    },
    [history, location.pathname]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Σταθμός Ανακύκλωσης",
        accessor: "XName",
      },
      {
        Header: "Βάρος Υλικών(kg)",
        accessor: "YValue",
        Cell: CellFloatCustom(10, 1),
      },
    ],
    []
  );

  const chartData = (data) => {
    const processedData = [];
    processedData.push(["Σταθμοί Ανακύκλωσης", "Βάρος Υλικών(kg)"]);
    // eslint-disable-next-line array-callback-return
    data.map((record) => {
      processedData.push([record.XName, record.YValue]);
    });
    return processedData;
  };
  function useGetStationsDeliveries() {
    return useLazyQuery(gqlGetStationsDeliveries);
    //return [null, { loading: null, data: null }];
  }

  const getDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      setImgUrl(imgData);
    });
  };

  const getTitle = () => {
    const fromDate = routeFilterValues?.fromDate
      ? format(routeFilterValues.fromDate, "dd/MM/yyyy")
      : " - ";
    const tillDate = routeFilterValues?.tillDate
      ? format(routeFilterValues.tillDate, "dd/MM/yyyy")
      : " - ";
    const title =
      "Ανάλυση Παραλαβών Σταθμών Ανακύκλωσης Από: " +
      fromDate +
      "  Έως: " +
      tillDate +
      "  Συλλέκτης: " +
      appStore.userLogin.collector;
    console.log("title= ", title);
    console.log(routeFilterValues);
    return title;
  };
  const handleExport = useCallback(async () => {
    await exportXlsModal({
      getLazyQuery: useGetStationsDeliveries,
      tableName: "collectorResponse",
      title: getTitle(),
      recordsData: data,
      columnsDef: [
        {
          fieldName: "XName",
          fieldTitle: "Σταθμός Ανακύκλωσης",
          fieldType: "TEXT",
        },
        {
          fieldName: "YValue",
          fieldTitle: "Βάρος Υλικών(kg)",
          fieldType: "FLOAT",
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  /* console.log(dataToRender); */
  return (
    <>
      <Card className="shadow">
        <Card.Header>
          <Card.Title className="m-0">
            Ανάλυση Παραλαβών Σταθμών Ανακύκλωσης
          </Card.Title>
        </Card.Header>

        {/* Φίλτρα */}
        <Filters
          fetchData={fetchData}
          onChange={filtersChanged}
          initialValues={routeFilterValues}
        />
        {/* TableGrid */}
        <LoadingOverlay active={loading} spinner text="Παρακαλώ περιμένετε...">
          {!loading && data ? (
            <>
              {!error && (
                <div id="divToPrint">
                  <Chart
                    style={{ marginBottom: "50px" }}
                    width={"100%"}
                    height={"670px"}
                    chartType="ColumnChart"
                    loader={
                      <div style={{ height: "720px" }}>Loading Chart</div>
                    }
                    data={chartData(data)}
                    options={{
                      hAxis: { title: "Σταθμοί Ανακύκλωσης" },
                      vAxis: { title: "Βάρος Υλικών(kg)", minValue: 0 },
                      //axisTitlesPosition: 'none',
                      legend: { position: "none" },
                      chartArea: { width: "85%", height: "70%" },
                      colors: ["#28A746"],
                      //backgroundColor: '#FAFAD2',
                      // lineWidth: 25
                    }}
                    chartEvents={[
                      {
                        eventName: "ready",
                        callback: () => {
                          getDocument();
                        },
                      },
                      {
                        eventName: "error",
                        callback: () => {
                          setError(true);
                        },
                      },
                    ]}
                  />
                </div>
              )}

              <TableSubSimple
                title=""
                //onRefresh={() => refetch()}
                onExport={handleExport}
                columns={columns}
                data={data}
              />
              {data.length > 0 && (
                <PdfRep
                  fromDate={routeFilterValues.fromDate}
                  tillDate={routeFilterValues.tillDate}
                  getDocument={imgUrl}
                  data={data}
                ></PdfRep>
              )}
            </>
          ) : (
            <div style={{ height: "200px" }}></div>
          )}
        </LoadingOverlay>
      </Card>
    </>
  );
};

/* ----------- Filters Component ------------------ */
const Filters = ({ onChange, initialValues, fetchData }) => {
  //
  const handleFiltersSubmit = useCallback(
    (values, { setSubmitting }) => {
      setTimeout(() => {
        setSubmitting(false);
        console.log("values", values);
        onChange(values);
      }, 400);
    },
    [onChange]
  );

  return (
    <FormFilterCard
      disableClear={true}
      title="Κριτήρια Αναζήτησης"
      onSubmit={handleFiltersSubmit}
      initialValues={initialValues}
    >
      <Row>
        <FieldDateRanger
          onSubmit={fetchData}
          as={FormCol}
          label="Από"
          name="fromDate"
          name2="tillDate"
        />
      </Row>
    </FormFilterCard>
  );
};
